import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import progressContext from "../../Context/progressFlag/progressContext";
import userContext from "../../Context/users/userContext";
import faceContext from "../../Context/faceDetection/faceContext";
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";

import "../../assets/css/mainStory/welcome.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AdminPanelContext from "../../Context/AdminPanelContext/AdminPanelContext";

const Welcome = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const progresscontext = useContext(progressContext);
  const { progressFlag, updateProgressFlag } = progresscontext;

  const { user } = useContext(userContext);
  const { colorsPersonalityAndHairs, colorOfEye } = useContext(faceContext);

  const {updateAdminScreen} = useContext(AdminPanelContext)

  const clickHandler = async () => {
    const question =
      user.settings?.askQuestionaire && !user.settings?.questionaireEnd;
    colorOfEye(user.settings?.defaultEyeColor || "black");
    colorsPersonalityAndHairs(
      user.settings?.defaultPersonality || "adventure",
      user.settings?.defaultHairColor || "black",
      user.settings?.defaultHairStyle || "curly"
    );
    const newProgressFlag = {
      ...progressFlag,
      welcome: false,
      questionaire: question,
      detection: !question,
    };
    updateProgressFlag(newProgressFlag);
  };

  const logout = () => {
    const newProgressFlag = {
      ...progressFlag,
      welcome: true,
      questionaire: false,
      detection: false,
      detection2: false,
      processing: false,
      resultDisplay: false,
      isSelectedItem: false,
    };
    updateProgressFlag(newProgressFlag);
    localStorage.removeItem("token");
    if (location.pathname === '/home' || location.pathname === '/admin/history' || location.pathname === '/admin' || location.pathname === '/admin/profile' || location.pathname === '/admin/settings') {
      props.preprocess()
    }
    if (location.pathname === "/verifyfirst") {
      navigate("/login");
    }
  };
  return (
    <section className="sectionWelcome">
      <nav className="navW px-2">
        <Logo className="logonavW" />
        <div className="navoptionsW">
          <div>
            <Link to={"/admin"} className="linkW px-2">
              Admin Panel
            </Link>
          </div>
          <div>
            <Link to={"/faqsguide"} className="linkW px-2">
              FAQS
            </Link>
          </div>
          <div>
            <span onClick={logout} className="linkW px-2">
              Log out
            </span>
          </div>
        </div>
      </nav>
      <div className="parentmainW">
        <div className="mainW px-4">
          <Logo className="logoW" />
          {user.settings &&
            <>

              <span className="headingW mt-3">
                Try Our Frame Recomender to find perfect pair of glasses
              </span>

              <button className="btnW mt-3" onClick={clickHandler}>
                Get Ready
              </button>
            </>
          }
          {!user.settings &&
            <>

              <span className="headingW mt-3">
                Welcome new user!
              </span>
              <span className="fs-5 text-white">Before you begin check out the settings you need for your experience</span>
              <button className="btnW mt-3" onClick={()=>{
                updateAdminScreen("settings")
                navigate("/admin")
              }}>
                Go to Settings
              </button>
            </>
          }
        </div>
      </div>
    </section>
  );
};

export default Welcome;
