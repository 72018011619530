import React from 'react'
import { useContext } from 'react'
import ThemeContext from '../../../Context/ThemeContext/ThemeContext'

import { IconButton } from '@mui/material'

import { DarkModeOutlined, LightModeOutlined, PersonOutlineOutlined, PowerSettingsNewOutlined, ScannerOutlined, SettingsOutlined } from '@mui/icons-material';
import AdminPanelContext from '../../../Context/AdminPanelContext/AdminPanelContext';
import { useLocation, useNavigate } from 'react-router-dom';
import progressContext from '../../../Context/progressFlag/progressContext';
import ordersContext from '../../../Context/orders/ordersContext';


const Topbar = (props) => {
    const location = useLocation();
    const navigate = useNavigate()

    const { theme, tokens, toggleTheme } = useContext(ThemeContext)
    const { updateAdminScreen } = useContext(AdminPanelContext)
  const { progressFlag, updateProgressFlag } = useContext(progressContext);
  const { clearOrder } = useContext(ordersContext);


    const palette = tokens(theme);


    const logout = () => {
        props.setLoaded(false)
        const newProgressFlag = {
          ...progressFlag,
          welcome: true,
          questionaire: false,
          detection: false,
          detection2: false,
          processing: false,
          resultDisplay: false,
          isSelectedItem: false,
        };
        updateProgressFlag(newProgressFlag);
        clearOrder();
        localStorage.removeItem("token");
        if (location.pathname === '/home' || location.pathname === '/admin/history' || location.pathname === '/admin' || location.pathname === '/admin/profile' || location.pathname === '/admin/settings') {
          props.preprocess()
        }
        if (location.pathname === "/verifyfirst") {
          navigate("/login");
        }
      };

    return (
        <>
            <nav className='d-flex justify-content-end p-2 w-100' style={{
                maxHeight:"56px",
                minHeight:"56px",
                height:"56px"
            }}>
                <div className="d-flex w-100 justify-content-end" style={{
                    borderRadius: "3px",
                }}>
                    <IconButton onClick={toggleTheme}>
                        {theme === "dark" ? (
                            <DarkModeOutlined style={{ color: palette.primary[100] }} />
                        ) : (
                            <LightModeOutlined style={{ color: palette.primary[100] }} />
                        )}
                    </IconButton>
                    <IconButton onClick={() => {
                        navigate("/home")
                    }}>
                        <ScannerOutlined style={{ color: palette.primary[100] }} />
                    </IconButton>
                    <IconButton onClick={() => {
                        updateAdminScreen("settings")
                    }}>
                        <SettingsOutlined style={{ color: palette.primary[100] }} />
                    </IconButton>
                    <IconButton onClick={() => {
                        updateAdminScreen("updateProfile")
                    }}>
                        <PersonOutlineOutlined style={{ color: palette.primary[100] }} />
                    </IconButton>
                    <IconButton onClick={() => {
                        logout();
                    }}>
                        <PowerSettingsNewOutlined style={{ color: palette.primary[100] }} />
                    </IconButton>
                </div>

            </nav>
        </>
    )
}

export default Topbar
