export const convertToTimeElapsed = (timestamp) => {
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - timestamp;

  // Calculate the time difference in seconds, minutes, hours, and days
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Determine the appropriate time elapsed format based on the time difference
  if (days > 0) {
    return days + " day" + (days === 1 ? "" : "s") + " ago";
  } else if (hours > 0) {
    return hours + " hour" + (hours === 1 ? "" : "s") + " ago";
  } else if (minutes > 0) {
    return minutes + " minute" + (minutes === 1 ? "" : "s") + " ago";
  } else {
    return seconds + " second" + (seconds === 1 ? "" : "s") + " ago";
  }
}
export const capitalizeFirstWord = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}



export const findMajorityElement = (array) => {
  const elementCount = {};

  // Count the occurrences of each element
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (elementCount[element]) {
      elementCount[element]++;
    } else {
      elementCount[element] = 1;
    }
  }

  let majorityElement;
  let majorityCount = 0;

  // Find the element with the highest count
  for (const element in elementCount) {
    if (elementCount[element] > majorityCount) {
      majorityElement = element;
      majorityCount = elementCount[element];
    }
  }

  return majorityElement;
}


export const averageCalculation = (set) => {
  const sum = set.reduce((a, b) => a + b, 0);
  const avg = sum / set.length || 0;
  return avg;
}

export const base64_to_img = (base64) => {
  // Convert the base64 string to a Blob
  const byteString = atob(base64.split(',')[1]);
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });

  // Now you have the screenshot in JPEG format as a Blob
  // You can use the Blob for your desired purpose, like uploading it to a server or displaying it as an image

  // Example: Displaying the image using an image element
  const imageUrl = URL.createObjectURL(blob);
  const img = new Image();
  img.src = imageUrl;
  return img
}


export function rgbToHex(r, g, b) {
  const red = Math.ceil(r)
  const green = Math.ceil(g)
  const blue = Math.ceil(b)
  return "#" + componentToHex(red) + componentToHex(green) + componentToHex(blue);
}

export const avgcolordefiner = (color_set_rgb) => {
  const reds = []
  const greens = []
  const blues = []

  const Ys = [];
  const Crs = [];
  const Cbs = [];

  color_set_rgb.forEach(e => {
    reds.push(e.red)
    greens.push(e.green)
    blues.push(e.blue)

    Ys.push(0.299 * e.red + 0.587 * e.green + 0.114 * e.blue)
    Crs.push(-0.169 * e.red - 0.331 * e.green + 0.500 * e.blue + 128)
    Cbs.push(0.500 * e.red - 0.419 * e.green - 0.081 * e.blue + 128)

  });

  const R = average(reds)
  const G = average(greens)
  const B = average(blues)

  const Y = average(Ys)
  const Cb = average(Crs)
  const Cr = average(Cbs)


  // const Y = 0.299 * R + 0.587 * G + 0.114 * B
  // const Cb = -0.169 * R - 0.331 * G + 0.500 * B +128
  // const Cr = 0.500 * R - 0.419 * G - 0.081 * B+128



  const complexion = checkComplexion_ycbcr(Y, Cb, Cr)
  //const complexion = checkComplexion_rgb(R,G,B)

  const result = { complexion, red: R, green: G, blue: B }
  return result
}

export const average = (array) => {
  const sum = array.reduce((a, b) => a + b, 0);

  const average = (sum / array.length)
  return average
}
export function distanceVector(v1, v2) {
  var dx = v1.x - v2.x;
  var dy = v1.y - v2.y;

  return Math.sqrt(dx * dx + dy * dy);
}
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
export const checkComplexion_ycbcr = (y, cb, cr) => {

  //skin tone ranges 
  //dark rgb: 52,34,30 to 88,59,45
  //brown rgb: 106,71,49 to 143,92,61
  //medium rgb: 152,98,64 to 193,148,107
  //fair rgb: 209,171,124 to 222,189,154
  //light rgb: 251,212,169 to 254,230,186


  //dark ycbcr:37.03,  126.29,138.67 to 59.45,  127.74,148.36
  //brown ycbcr: Y:68.55 cb:129.38 cr:154.71 to Y:89.05 cb:129.66 cr:166.48
  // medium:  Y:94.19 cb:130.15 cr:169.24 to Y:137.39 cb:133.99 cr:167.67
  //fair: Y:154.77 cb:137.16 cr:166.68 to Y:178.32 cb:134.03 cr:159.15
  //light : Y:198.42 cb:135.66 cr:165.50 to Y:211.35 cb:138.53 cr:158.42

  const lightSkinRange = { minY: 195, maxY: 215, minCb: 130, maxCb: 140, minCr: 135, maxCr: 160 };
  const fairSkinRange = { minY: 150, maxY: 180, minCb: 130, maxCb: 140, minCr: 155, maxCr: 170 };
  const mediumSkinRange = { minY: 90, maxY: 150, minCb: 125, maxCb: 135, minCr: 160, maxCr: 175 };
  const brownSkinRange = { minY: 60, maxY: 90, minCb: 125, maxCb: 130, minCr: 150, maxCr: 170 };
  const darkSkinRange = { minY: 30, maxY: 60, minCb: 120, maxCb: 130, minCr: 135, maxCr: 150 };
  const coolUndertone = { minY: 30, maxY: 60, minCb: 95, maxCb: 100, minCr: 150, maxCr: 160 };

  // Analyze a pixel's Cb and Cr values and categorize complexion

  if (
    cb >= lightSkinRange.minCb && cb <= lightSkinRange.maxCb &&
    cr >= lightSkinRange.minCr && cr <= lightSkinRange.maxCr
  ) {
    return "Light Complexion";
  }
  else if (
    cb >= fairSkinRange.minCb && cb <= fairSkinRange.maxCb &&
    cr >= fairSkinRange.minCr && cr <= fairSkinRange.maxCr
  ) {
    return "Fair Complexion";
  }
  else if (
    cb >= mediumSkinRange.minCb && cb <= mediumSkinRange.maxCb &&
    cr >= mediumSkinRange.minCr && cr <= mediumSkinRange.maxCr
  ) {
    return "Medium Complexion";

  }
  else if (
    cb >= brownSkinRange.minCb && cb <= brownSkinRange.maxCb &&
    cr >= brownSkinRange.minCr && cr <= brownSkinRange.maxCr
  ) {
    return "Brown Complexion";
  }
  else if (
    cb >= darkSkinRange.minCb && cb <= darkSkinRange.maxCb &&
    cr >= darkSkinRange.minCr && cr <= darkSkinRange.maxCr
  ) {
    return "Dark Complexion";
  }
  else if (
    cb >= coolUndertone.minCb && cb <= coolUndertone.maxCb &&
    cr >= coolUndertone.minCr && cr <= coolUndertone.maxCr
  ) {
    return "Skin Under tone";
  }
  else {
    return "Uncategorized";
  }

}


export function findCommonElements(arr1, arr2) {
  // Create an empty array to store common elements
  const commonElements = [];

  // Loop through the first array
  for (let i = 0; i < arr1.length; i++) {
    const currentElement = arr1[i];

    // Check if the current element is present in the second array
    if (arr2.includes(currentElement)) {
      // Add the common element to the commonElements array
      commonElements.push(currentElement);
    }
  }

  // Return the array of common elements
  return commonElements;
}

export function findCommonElementsInThree(arr1, arr2, arr3) {
  // Create an empty array to store common elements
  const commonElements = [];

  // Loop through the elements of the first array
  for (let i = 0; i < arr1.length; i++) {
    const currentElement = arr1[i];

    // Check if the current element is present in both the second and third arrays
    if (arr2.includes(currentElement) && arr3.includes(currentElement)) {
      // Add the common element to the commonElements array
      commonElements.push(currentElement);
    }
  }

  // Return the array of common elements
  return commonElements;
}

export function convertToExactDate(timestamp) {
  const date = new Date(timestamp ); // Convert to milliseconds by multiplying with 1000

  // Extract date components
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  // Format the date as desired
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate
}

export const makeSentencefromreasons=(arr)=>{
  const words =['hair style', 'face shape','personality', 'age & gender','skin tone','compliments the hair color','contrasts the hair color']
const keywords =['hair_style','face_shape','personality','age_gender','skin_tone','hair_color_compliment','hair_color_contrast']
var sentence='';

for (let i = 0; i < arr.length; i++) {
    const index=keywords.findIndex(x =>x===arr[i]);
    if(i===0){
      sentence+=`${words[index]}`
    }
    else
    if(i!==arr.length-1){
      sentence+=`, ${words[index]}`
    }
    else
    if(i===arr.length-1){
      sentence+=` and ${words[index]}.`
    }
}
return sentence
}