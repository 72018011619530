import React, { useContext, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
// import { pdProcessor, pdProcessorEnd } from '../../Utilities/pdProcessor'
import {
  pdProcessor,
  pdProcessorEnd,
  resetLasttime,
} from "../../Utilities/pdProcessor2";
import faceContext from "../../Context/faceDetection/faceContext";
import progressContext from "../../Context/progressFlag/progressContext";
import { ReactComponent as LoadingSpinner } from "../../assets/svgs/loading_spinner.svg";
import { base64_to_img } from "../../Utilities/commonFunction";
import {
  drawNumber,
  drawOvalDotted,
  drawSquareCorners,
} from "../../Utilities/drawingCanvas";
import userContext from "../../Context/users/userContext";
import { ReactComponent as Logo } from "../../assets/svgs/logoBlue.svg";
import "../../assets/css/mainStory/faceDetection.css";

const FaceDetection = (props) => {
  var detectionPhase;

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const canvasInfoRef = useRef(null);
  const canvasInfo2Ref = useRef(null);
  const canvasTimerRef = useRef(null);

  const context = useContext(faceContext);
  const { faces, saveImageBase64, pdResults, saveImageElement } = context;

  const progresscontext = useContext(progressContext);
  const { progressFlag, updateProgressFlag, resetProgressFlag } =
    progresscontext;

  const { user } = useContext(userContext);

  const { models } = props;

  const [pdFlag, setPDFlag] = useState(false);
  const [imgStoreFlag, setImgStoreFlag] = useState(false);

  const [imgSrc, setImgSrc] = useState(null);
  const [imgBase64, setImgBase64] = useState(null);
  const [distance, setDistance] = useState(null);
  const [PDValue, setPDValue] = useState("");
  const [PDResult, setPDResult] = useState("");
  const [camera, setCamera] = useState(user?.settings?.facingMode || "user");
  const [isReady, setIsReady] = useState(false);
  const [cameraChanging, setCameraChanging] = useState(false);
  const [timer, setTimer] = useState(null);
  const [timerDetection, setTimerDetection] = useState(null);
  const [remainingTime, setRemainingTime] = useState(3);
  const [runLive,setRunLive] = useState(false)

  const updatePdValue = (value) => {
    setPDValue(value);
  };

  const updatePDFlag = (flag) => {
    setPDFlag(flag);
  };
  const updateImgStoreFlag = (flag) => {
    setImgStoreFlag(flag);
  };

  const updateImgSrc = (data) => {
    setImgSrc(data);
  };
  const updateImgBase64 = (data) => {
    setImgBase64(data);
  };
  const updateDistance = (data) => {
    setDistance(data);
  };

  useEffect(()=>{
    if(runLive){
      console.log({timerDetection})
      
      if (!timerDetection) {
        const newTimerD = setInterval(() => {
          predictWebcam()
        }, 20);

        setTimerDetection(newTimerD);
      }


    }
    else{
      if (timerDetection) {
        clearInterval(timerDetection);
        setTimerDetection(null);
      }
    }

    return () => {
      if (timerDetection) {
        clearInterval(timer);
        setTimerDetection(null);
      }
    };
  },[runLive,distance,PDValue])

  useEffect(() => {
    drawNumber(canvasTimerRef, remainingTime);

    // Clear the existing timer if the value goes out of the specified range
    if (distance < 31 || distance > 40) {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
        setRemainingTime(3);
      }
    } else {
      // Set a new timer if the value is within the specified range
      if (!timer) {
        const newTimer = setTimeout(() => {
          if (distance > 31 && distance < 40) {
            setRemainingTime((prevState) => prevState - 1);
            if (remainingTime - 1 === 0) {
              capturePhoto();
            }
          }
        }, 600);

        setTimer(newTimer);
      }
    }

    // Cleanup function to clear the timer when the component unmounts or the value changes
    return () => {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
    };
  }, [distance, timer,pdFlag]);

  useEffect(() => {
    if (cameraChanging) {
      changeFacingMode();
      resetLasttime();
      setCameraChanging(false);
    }

    if (pdFlag && imgStoreFlag) {
      pdResults(PDValue);

      if (faces.capImageBase64 == null) {
        saveImageBase64(imgBase64);
      }

      if (faces.capImageElement == null) {
        saveImageElement(imgSrc);
      }

      updatePDFlag(false);
      moveForward();
     
    } 
    else if (!pdFlag || !imgStoreFlag) {
      models.pd.setOptions({ runningMode: "VIDEO" });

      const canvasElement = canvasRef.current;
      const context = canvasElement.getContext("2d");
      const videoElement = webcamRef.current.video;
      const infoCanvCtx = canvasInfoRef.current.getContext("2d");
      const infoCanvCtx2 = canvasInfo2Ref.current.getContext("2d");
      const timerCanvCtx = canvasTimerRef.current.getContext("2d");

      videoElement.addEventListener("loadeddata", handleUserMedia);

      if (videoElement) {
        if (canvasElement) {
          context.canvas.height = videoElement.offsetHeight;
          context.canvas.width = videoElement.offsetWidth;

          infoCanvCtx.canvas.height = videoElement.offsetHeight;
          infoCanvCtx.canvas.width = videoElement.offsetWidth;

          infoCanvCtx2.canvas.height = videoElement.offsetHeight;
          infoCanvCtx2.canvas.width = videoElement.offsetWidth;

          timerCanvCtx.canvas.height = videoElement.offsetHeight;
          timerCanvCtx.canvas.width = videoElement.offsetWidth;

          drawOvalDotted(canvasInfoRef, "green");
          drawOvalDotted(canvasInfo2Ref, "red");
        }
      }

      if (isReady) {
        predictFaceCharacteristics();
      } else {
        handleReadyStateChange();
      }
    }
  }, [pdFlag, imgStoreFlag, isReady, cameraChanging, camera]);

  const changeFacingMode = () => {
    // document.getElementById('webcam').classList.add('d-none')
    if (camera === "environment") {
      setCamera("user");
      canvasRef.current.style.transform = "scaleX(-1)";
    } else {
      setCamera("environment");
      canvasRef.current.style.transform = "scaleX(1)";
    }
  };

  const cameraViewHandler = () => {
    setCameraChanging(true);
  };

  const moveForward=async()=>{
    // await webcamRef.current.componentWillUnmount();

    const newProgressFlag = {
      ...progressFlag,
      detection: false,
      detection2: true,
    };
    updateProgressFlag(newProgressFlag);
  }

  const predictFaceCharacteristics = async () => {
    if (!pdFlag && isReady) {
      setRunLive(true)
      
      // detectionPhase = setInterval(() => {
        // window.requestAnimationFrame(predictWebcam);
        // predictWebcam()
      // }, 1000);

    } else if (!imgStoreFlag) {
      const imageElement = base64_to_img(imgBase64);

      updateImgSrc(imageElement);
      updateImgStoreFlag(true);
    }
  };

  async function predictWebcam() {
    // console.log({PDValue})

    if (
      !pdFlag &&
      !cameraChanging &&
      webcamRef.current &&
      webcamRef.current.video
    ) {
      pdProcessor(
        models.pd,
        webcamRef,
        canvasRef,
        updatePdValue,
        updateDistance
      );
      // window.requestAnimationFrame(predictWebcam);
    }
  }

  const capturePhoto = React.useCallback(() => {
    clearInterval(detectionPhase)
    const dimenstion = {
      width: webcamRef?.current?.video?.offsetWidth,
      height: webcamRef?.current?.video?.offsetHeight,
    };
    const imageSrc = webcamRef.current.getScreenshot(dimenstion);

    setPDResult(PDValue);
    updateImgBase64(imageSrc);
    pdProcessorEnd();

    updatePDFlag(true);
  }, [webcamRef]);

  const handleUserMedia = async () => {
    document.getElementById("distanceText").style.display = "block";
    document.getElementById("loading").classList.add("d-none");
    document.getElementById("infoCanvas").classList.remove("invisible");
    document.getElementById("infoCanvas2").classList.remove("invisible");
    document.getElementById("timerCanvas").classList.remove("invisible");
    document.getElementById("capture").classList.remove("d-none");
    // document.getElementById("cameraView").classList.remove("d-none");
  };

  const handleReadyStateChange = async () => {
    const readyStateCheck = new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (webcamRef.current.video.readyState === 4) {
          clearInterval(intervalId);
          resolve();
        }
      }, 100);
    });

    await readyStateCheck;
    setIsReady(true);
  };

  const goBack = () => {
    resetProgressFlag();
  };

  return (
    <>
      <section className="sectionFD">
        <nav className="navFD pt-4 ps-4">
          <span onClick={goBack} className="gobackFD me-5">
            &larr;
          </span>
          <span className="titleFD mx-2">Frameswise</span>
          <Logo onClick={goBack} className="logoFD" />
        </nav>

        <div className="parentvideomainFD">
          <div className="videomainFD">
            <div className="position-relative">
              <div id="loading" className="container my-4 cameraloadingFD">
                <span className="">Setting up Camera! Please be patient</span>
                <LoadingSpinner
                  style={{ marginTop: "10px", width: "40px", height: "40px" }}
                />
              </div>

              <div
                id="distanceText"
                style={{
                  visibility: "visible",
                  display: "none",
                }}
                className={` py-2 ps-3 pe-5  mt-4 instructionFD ${
                  distance < 40 && distance > 31 ? "bg-success" : ""
                }`}
              >
                <span className={`instructiontextFD `}>
                  {distance
                    ? distance > 40 || distance < 31
                      ? distance > 40
                        ? "Come closer to camera"
                        : "Move further from camera"
                      : `Your face is at right distance, take picture or wait for auto capture in ${remainingTime}`
                    : "Please align your face"}
                </span>
              </div>

              <Webcam
                className="webcamFD"
                audio={false}
                id="webcam"
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                mirrored={camera === "user"}
                style={{}}
                videoConstraints={{
                  facingMode: camera,
                }}
              />

              <canvas
                ref={canvasRef}
                id="output-canvas"
                style={{
                  position: "absolute",
                  zIndex: "90",
                  left: 0,
                  width: "100%",
                  transform: `${
                    camera === "environment" ? "scaleX(1)" : "scaleX(-1)"
                  }`,
                }}
              ></canvas>

              <canvas
                ref={canvasInfoRef}
                id="infoCanvas"
                className="invisible"
                style={{
                  position: "absolute",
                  zIndex: "90",
                  left: 0,
                  width: "100%",
                  visibility:
                    distance > 31 && distance < 40 ? "visible" : "hidden",
                }}
              ></canvas>

              <canvas
                ref={canvasInfo2Ref}
                id="infoCanvas2"
                className="invisible"
                style={{
                  position: "absolute",
                  zIndex: "90",
                  left: 0,
                  width: "100%",
                  visibility:
                    distance < 31 || distance > 40 ? "visible" : "hidden",
                }}
              ></canvas>
              <canvas
                ref={canvasTimerRef}
                id="timerCanvas"
                className="invisible"
                style={{
                  position: "absolute",
                  zIndex: "90",
                  left: 0,
                  width: "100%",
                  visibility:
                    distance > 31 && distance < 40 ? "visible" : "hidden",
                }}
              ></canvas>
            </div>
          </div>
        </div>
        <div className="buttondivFD mt-4">
          <button
            disabled={
              !PDValue || PDValue === "" || distance < 31 || distance > 40
            }
            id="capture"
            onClick={capturePhoto}
            className="btnFD d-none"
          >
            Take a Picture
          </button>
        </div>
      </section>
    </>
  );
};

export default FaceDetection;
