// StarRating.js
import React, { useContext, useState } from 'react';
import '../assets/css/reviewstars.css'
import ThemeContext from '../Context/ThemeContext/ThemeContext';

const StarRating = ({ rating, onChange }) => {
  const [selectedRating, setSelectedRating] = useState(rating);

  const { theme, tokens } = useContext(ThemeContext)
  const colors = tokens(theme)

  const handleStarClick = (newRating) => {
    setSelectedRating(newRating);
    onChange(newRating); // Pass the selected rating to the parent component
  };

  const stars = [1, 2, 3, 4, 5].map((star) => (
    <span
      key={star}
      className={`stars  mx-2 fs-3 ${star <= selectedRating ? 'selectedStar ' : ''}`}
      onClick={() => handleStarClick(star)}
      style={{
        color:star <= selectedRating ?colors.redAccent[400]:colors.redAccent[600],
        textShadow:` 0 0 10px ${colors.redAccent[300]}`
      }}
    >
      ★
    </span>
  ));

  return <div className="star-rating ">{stars}</div>;
};

export default StarRating;
