import React, { useRef } from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import userContext from '../../Context/users/userContext';
import { ReactComponent as LoadingSpinnerSmallPink } from '../../assets/svgs/loading_spinner_small_pink.svg';

import Navbar from '../Navbar';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faShop, faUser } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/text.css'
import '../../assets/css/glassmophorm.css'
import ThemeContext from '../../Context/ThemeContext/ThemeContext';

const Profile = () => {

    const { user, updateProfile } = useContext(userContext)

    const [disableUpdate, setDisableUpdate] = useState(true)
    const [newUser, setNewUser] = useState(user)
    const [oldUser, setOldUser] = useState(user)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState({
        status: "danger",
        show: false,
        msg: ""
    })

    const { theme, tokens } = useContext(ThemeContext)
    const colors = tokens(theme)

    const onChange = (e) => {
        setNewUser({ ...newUser, [e.target.name]: e.target.value })
        if (e.target.value === oldUser[e.target.name]) {
            setDisableUpdate(true)
        } else {
            setDisableUpdate(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUpdating(true)

        const result = await updateProfile(null, newUser.name, newUser.shopName, newUser.shopAddress)
        if (result.Success == "true") {
            setError({
                status: "success",
                show: true,
                msg: "Profile Updated"
            })
            setDisableUpdate(true)
            setOldUser(newUser)
        }
        else {
            setError({
                status: "danger",
                show: true,
                msg: "Unable to update"
            })
        }

        setUpdating(false)
    }


    return (
        <>
            <section className="d-flex flex-column " style={{maxHeight: window.innerHeight - 56, overflow: 'auto', maxWidth: "100%" }}>

                <form className='d-flex w-100 flex-column flex-wrap p-3' onSubmit={handleSubmit} style={{
                    background: colors.blueAccent[900],
                    color: colors.primary[100]
                }}>
                    <span className='fs-2 mt-5 fw-bold' style={{ color: colors.redAccent[400] }}>Update Profile</span>
                    <span className='fs-5   mt-2 mb-4' >Here you can update your profile</span>

                    <span className='fs-5  mt-2' >Your Name</span>
                    <input type="text" placeholder="Username" id="name" name='name' onChange={onChange} value={newUser.name} required />

                    <span className='fs-5  mt-2' >Your Email</span>
                    <input type="email" placeholder='Email' id="signupEmail" name='signupEmail' aria-describedby="emailHelp" onChange={onChange} value={newUser.email} disabled style={{color:"black"}}/>

                    <span className='fs-5  mt-2' >Your Shop Name</span>
                    <input type="text" placeholder='Shop Name' id="shopName" name='shopName' onChange={onChange} value={newUser.shopName} required />

                    <span className='fs-5  mt-2' >Your Shop Address</span>
                    <input type="text" placeholder='Shop Location' id="shopAddress" name='shopAddress' onChange={onChange} value={newUser.shopAddress} required />


                    {error.show &&
                        <span className={`text-${error.status} fw-bold fs-5`}>
                            {error.msg}
                        </span>
                    }

                    {!updating && <button style={{
                        background: colors.primary[400],
                        color: colors.primary[100],
                        border: `1px solid ${colors.primary[100]}`
                    }} type="submit" className="btn mt-5" disabled={disableUpdate}>Update</button>}
                    {updating && <LoadingSpinnerSmallPink style={{ marginTop: '10px', width: "30px", height: "30px" }} />}








                </form>
            </section>
        </>
    )
}

export default Profile
