import { FACEMESH_RIGHT_IRIS, FACEMESH_LEFT_IRIS, } from "@mediapipe/face_mesh";
import { FaceLandmarker, DrawingUtils } from '@mediapipe/tasks-vision';


var lastVideoTime = -1;
var results = undefined;
var run = true

export const resetLasttime = () => {
    lastVideoTime = -1
}

export const pdProcessorEnd = () => {
    run = false
}

// Function to calculate distance between two points / pupils
const getDistance = (p1, p2) => {
    return Math.sqrt(
        Math.pow(p1.x - p2.x, 2) +
        Math.pow(p1.y - p2.y, 2) +
        Math.pow(p1.z - p2.z, 2)
    );
};

export const pdProcessor = async (faceLandmarker, webcamRef, canvasRef, updatePdValue, updateDistance) => {

    try {




        const video = webcamRef.current.video;
        const width = video.offsetWidth;
        const height = video.offsetWidth;

        canvasRef.current.width = width
        canvasRef.current.height = video.offsetHeight

       



        // Now let's start detecting the stream.
        // console.log({width,height})
        let startTimeMs = performance.now();
        if (
            lastVideoTime !== video.currentTime &&
            video != null &&
            video.readyState === 4 &&
            width>0 && height>0
        ) {

            lastVideoTime = video.currentTime;
            results = faceLandmarker.detectForVideo(video, startTimeMs);
            // results = faceLandmarker.detectForVideo(video);
            // Setting canvas - references and context
            const canvasElement = canvasRef.current;
            const canvasCtx = canvasElement.getContext("2d");
            canvasCtx.save();
            


            // Loading Face Mesh landmarks for iris and getting coordinates for pupils
            if (results.faceLandmarks && results.faceLandmarks[0] && results.faceLandmarks.length > 0) {
                distanceCalculator(results, updateDistance, width, height)
                let pupils = {
                    left: {
                        x:
                            (results.faceLandmarks[0][FACEMESH_LEFT_IRIS[0][0]].x +
                                results.faceLandmarks[0][FACEMESH_LEFT_IRIS[2][0]].x) /
                            2.0,
                        y:
                            (results.faceLandmarks[0][FACEMESH_LEFT_IRIS[0][0]].y +
                                results.faceLandmarks[0][FACEMESH_LEFT_IRIS[2][0]].y) /
                            2.0,
                        z:
                            (results.faceLandmarks[0][FACEMESH_LEFT_IRIS[0][0]].z +
                                results.faceLandmarks[0][FACEMESH_LEFT_IRIS[2][0]].z) /
                            2.0,
                        width: getDistance(
                            results.faceLandmarks[0][FACEMESH_LEFT_IRIS[0][0]],
                            results.faceLandmarks[0][FACEMESH_LEFT_IRIS[2][0]]
                        ),
                    },
                    right: {
                        x:
                            (results.faceLandmarks[0][FACEMESH_RIGHT_IRIS[0][0]].x +
                                results.faceLandmarks[0][FACEMESH_RIGHT_IRIS[2][0]].x) /
                            2.0,
                        y:
                            (results.faceLandmarks[0][FACEMESH_RIGHT_IRIS[0][0]].y +
                                results.faceLandmarks[0][FACEMESH_RIGHT_IRIS[2][0]].y) /
                            2.0,
                        z:
                            (results.faceLandmarks[0][FACEMESH_RIGHT_IRIS[0][0]].z +
                                results.faceLandmarks[0][FACEMESH_RIGHT_IRIS[2][0]].z) /
                            2.0,
                        width: getDistance(
                            results.faceLandmarks[0][FACEMESH_RIGHT_IRIS[0][0]],
                            results.faceLandmarks[0][FACEMESH_RIGHT_IRIS[2][0]]
                        ),
                    },
                };


                // Setting variables for calculation disance between pupils
                let distance = getDistance(pupils.left, pupils.right);
                let irisWidthInMM = 11.7;
                let pupilWidth = Math.min(pupils.left.width, pupils.right.width);
                let pd = (irisWidthInMM / pupilWidth) * distance;


                // Setting real-time pupillary distance
                updatePdValue(pd.toFixed(0));

                if (results.faceLandmarks) {
                    const drawingUtils = new DrawingUtils(canvasCtx);
                    // Drawing Face Mesh landmarks of iris on canvas (and face oval and tessellation if you want)
                    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
                    for (const landmarks of results.faceLandmarks) {
                        drawingUtils.drawConnectors(
                            landmarks,
                            FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
                            { color: "#FF3030" }
                        );
                        drawingUtils.drawConnectors(
                            landmarks,
                            FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
                            { color: "#30FF30" }
                        );
                    }
                }
            }
            else{
                console.log("no detection")
                updateDistance(null)
                updatePdValue(null)
            }
        }
    } catch (error) {
        console.error("An error occurred in pdProcessor:", error);


    }

}


const distanceCalculator = (results, updateDistance, width, height) => {
    var irisLeftMinX = -1;
    var irisLeftMaxX = -1;
    if (results.faceLandmarks) {
        for (const landmarks of results.faceLandmarks) {
            for (const point of FACEMESH_LEFT_IRIS) {
                var point0 = landmarks[point[0]];
                if (irisLeftMinX == -1 || point0.x * width < irisLeftMinX) {
                    irisLeftMinX = point0.x * width;
                }
                if (irisLeftMaxX == -1 || point0.x * width > irisLeftMaxX) {
                    irisLeftMaxX = point0.x * width;
                }
            }


        }
    }

    var dx = irisLeftMaxX - irisLeftMinX;
    var dX = 11.7;

    // Logitech HD Pro C922	Norm focal
    var normalizedFocaleX = 1.40625;
    var fx = Math.min(width, height) * normalizedFocaleX;
    var dZ = (fx * (dX / dx)) / 10.0;
    // dZ = dZ.toFixed(2);
    if (dZ !== Infinity) {

        updateDistance(Math.round(dZ))
    }
    else {
        updateDistance('calculating...')
    }


    //   canvasCtx.fillStyle = "red";
    //   canvasCtx.font = "30px Arial";
    //   canvasCtx.fillText(dZ + " cm", width * 0.75, 50);

}