import { getIndexInSubArray } from "./utils";

const finalizeFrame = async (frame) => {
    const finalarray = [];
    for (const key in frame) {
        //[["aviator","faceshape personality",7],['cateye',"faceshape personality",7]]
        for (const frameKeyed of frame[key]) {
            for (const frameName of frameKeyed.frame) {

                // console.log("new cycle")
                // console.log({frameName,priority:frameKeyed.priority})
                // console.log({finalarray})


                const index = getIndexInSubArray(finalarray, frameName)
                // console.log({index})
                if (index<0) {
                    const newArray = []
                    newArray.push(frameName)
                    newArray.push(frameKeyed.reason)
                    newArray.push(frameKeyed.priority)
                    finalarray.push(newArray)
                }
                else {
                    // console.log(finalarray[index])
                    // console.log(frameKeyed.priority)
                    if (finalarray[index][2] < frameKeyed.priority) {
                        finalarray.splice(index, 1)
                        const newArray = []
                        newArray.push(frameName)
                        newArray.push(frameKeyed.reason)
                        newArray.push(frameKeyed.priority)
                        finalarray.push(newArray)
                    }
                }

            };
        };

    }

    return finalarray
}

const finalizeColor = async (color) => {
    // console.log(color)
    const finalarray = [];
    for (const key in color) {
        // console.log({key})
        //[["aviator","faceshape personality",7],['cateye',"faceshape personality",7]]
        for (const colorKeyed of color[key]) {
            for (const colorName of colorKeyed.color) {

                // console.log("new cycle")
                // console.log({colorName,priority:colorKeyed.priority})
                // console.log({finalarray})


                const index = getIndexInSubArray(finalarray, colorName)
                // console.log({index})
                if (index<0) {
                    const newArray = []
                    newArray.push(colorName)
                    newArray.push(colorKeyed.reason)
                    newArray.push(colorKeyed.priority)
                    finalarray.push(newArray)
                }
                else {
                    // console.log(finalarray[index])
                    // console.log(colorKeyed.priority)
                    if (finalarray[index][2] < colorKeyed.priority) {
                        finalarray.splice(index, 1)
                        const newArray = []
                        newArray.push(colorName)
                        newArray.push(colorKeyed.reason)
                        newArray.push(colorKeyed.priority)
                        finalarray.push(newArray)
                    }
                }

            };
        };

    }

    return finalarray
}


export const finalizeArray = async (frame, color) => {
    const arrayFrame = await finalizeFrame(frame)
    const arrayColor = await finalizeColor(color)
    return {arrayFrame,arrayColor}
}

