import { TinyFaceDetectorOptions, detectAllFaces } from '@vladmandic/face-api';
import * as faceapi from "@vladmandic/face-api"

var steps = 0;
var age = [];
var gender = [];
var attempts=0;

const resetVars = () => {
  steps = 0;
  age = [];
  gender = [];
  attempts=0;
}

//detect using face-api.js
const faceMyDetect = async (webcamRef) => {

  if (
    //typeof webcamRef.current !== "undefined" &&
    //webcamRef !== null 
    //&& webcamRef.current.video.readyState === 4
    typeof webcamRef !== "undefined" &&
    webcamRef !== null
    && attempts<1 
    ) {
      attempts++
    // let video = webcamRef.current.video;
    let video = webcamRef;
    const detections = await detectAllFaces(video, new TinyFaceDetectorOptions()).withAgeAndGender()

    // console.log('detections age gender')
    // console.log(detections)
    
    if (detections.length !== 0) {
      gender.push(detections[0].gender)
      age.push(detections[0].age)
      steps++
      
    }
    else{
      //giving default values if not found
       gender.push('female')
       age.push('20')
       steps++
     }


  }
 

}



//main process
export const ageGenderProcessor = async (models, image, progressFlag, updateValue) => {

  faceapi.nets.tinyFaceDetector = models.tinyface
  faceapi.nets.ageGenderNet = models.agegender
  resetVars()

  const continueProcessing = setInterval(async () => {
    await faceMyDetect(image)

    if (steps > 0) {
      clearInterval(continueProcessing)
     
      progressFlag(true)
      let ageGenderNetResults = { age: age, gender: gender }
      updateValue(ageGenderNetResults)

    }
  }, 1000);

}


