import React, { useState } from 'react'
import AdminPanelContext from './AdminPanelContext'

const AdminPanelState = (props) => {

    const updateAdminScreen=(screen)=>{
        const obj={...adminScreen}
        for (let key in obj) {
            if (key === screen) {
                obj[key] = true;
            } else {
                obj[key] = false;
            }
          }
          setAdminScreen(obj)
        
    }

    const [adminScreen, setAdminScreen] = useState({
        dashboard:true,
        settings:false,
        updateProfile:false,
        feedback:false,
        history:false,
        faqs:false,
        bar:false,
        pie:false,
        line:false,
    })

    return (
        <AdminPanelContext.Provider value={{adminScreen,updateAdminScreen}}>
            {props.children}
        </AdminPanelContext.Provider>

    )

}
export default AdminPanelState