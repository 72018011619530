import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import progressContext from "../../Context/progressFlag/progressContext";
import faceContext from "../../Context/faceDetection/faceContext";
import { ReactComponent as LoadingSpinner } from "../../assets/svgs/loading_spinner.svg";
import { ageGenderProcessor } from "../../Utilities/ageGenderProcessor";
import { shapeProcessor } from "../../Utilities/shapeProcessor";
import "../../assets/css/mainStory/faceDetection2.css";
import { ReactComponent as Logo } from "../../assets/svgs/logoBlue.svg";
import Ring_270_svg from "../../assets/svgs/Ring_270_svg";

const FaceDetection2 = (props) => {
  const imgRef = useRef(null);
  const resizeCanvasRef = useRef(null);

  const context = useContext(faceContext);
  const {
    faces,
    ageGenderResults,
    shapeResults,
    saveResizedImage,
    colorsOfFace,
    allLandmakrs,
    averageCalculator,
    saveImageBase64,
    saveImageElement,
  } = context;

  const progresscontext = useContext(progressContext);
  const { progressFlag, updateProgressFlag, resetProgressFlag } =
    progresscontext;

  const [ageGenderFlag, setAgeGenderFlag] = useState(false);
  const [shapeFlag, setShapeFlag] = useState(false);
  const [resizeImgFlag, setResizeImgFlag] = useState(false);

  const [ageGenderValue, setAgeGenderValue] = useState(null);
  const [shapeValue, setShapeValue] = useState(null);
  const [colorValue, setColorValue] = useState(null);
  const [measurementValue, setMeasurementValue] = useState(null);
  const [errorDetection, setErrorDetection] = useState(false);

  const { models } = props;

  var ageGender;
  var shape;
  var color;

  const updateShapeValue = (value) => {
    setShapeValue(value);
  };

  const updateAgeGenderValue = (value) => {
    setAgeGenderValue(value);
  };
  const updateColorValue = (value) => {
    setColorValue(value);
  };

  const updateMeasurementValue = (value) => {
    setMeasurementValue(value);
  };

  const updateAgeGenderFlag = (flag) => {
    setAgeGenderFlag(flag);
  };

  const updateShapeFlag = (flag) => {
    setShapeFlag(flag);
  };
  const updateResizeImgFlag = (flag) => {
    setResizeImgFlag(flag);
  };

  const predictFaceCharacteristics = async () => {
    if (!shapeFlag) {
      shape = await shapeProcessor(
        models.facemesh,
        // faces.capImageElement,
        faces.resizedImg,
        updateShapeFlag,
        updateShapeValue,
        updateColorValue,
        updateMeasurementValue
      );
      if (shape === false) {
        setErrorDetection(true);
      }
    } else if (!ageGenderFlag) {
      ageGender = await ageGenderProcessor(
        models,
        faces.capImageElement,
        updateAgeGenderFlag,
        updateAgeGenderValue
      );
    }
  };

  useEffect(() => {
    if (shapeFlag && ageGenderFlag) {
      ageGenderResults(ageGenderValue);
      shapeResults(shapeValue);
      colorsOfFace(colorValue);
      allLandmakrs(measurementValue);

      updateShapeFlag(false);
      updateAgeGenderFlag(false);

      averageCalculator();
      const newProgressFlag = {
        ...progressFlag,
        detection2: false,
        justification: true,
      };
      updateProgressFlag(newProgressFlag);
    } else if (!resizeImgFlag) {
      document.getElementById("processing").classList.remove("d-none");
      // models.facemesh.setOptions({ runningMode: "IMAGE" });
      if (imgRef) {
        // resizeImage()
      }
      while (!imgRef) {
        if (imgRef) {
          // resizeImage()
          break;
        }
      }
    } else if (!shapeFlag || !ageGenderFlag) {
      models.facemesh.setOptions({ runningMode: "IMAGE" });
      predictFaceCharacteristics();
    }
  }, [shapeFlag, ageGenderFlag, resizeImgFlag]);

  const resizeImage = (event) => {
    const image = event.target;

    const w = image.width;
    const h = image.height;
    // canvasRef.current.width = w
    // canvasRef.current.height = h
    // resizeCanvasRef.current.width = w;
    // resizeCanvasRef.current.height = h;

    // Determine whether the image is wider or taller
    const isLandscape = w > h;

    const canvasSize = Math.min(w, h);

    //for having equal widht and height
    // resizeCanvasRef.current.width = canvasSize;
    // resizeCanvasRef.current.height = canvasSize;

    //for having inheirt widht and height
    resizeCanvasRef.current.width = w;
    resizeCanvasRef.current.height = h;

    const ctx = resizeCanvasRef.current.getContext("2d");

    // ctx.fillStyle = "black";

    // for rectangle photos
    ctx.fillRect(
      0,
      0,
      resizeCanvasRef.current.width,
      resizeCanvasRef.current.height
    );

    //for square photos
    // ctx.fillRect(
    //   0,
    //   0,
    //   canvasSize,
    //   canvasSize
    // );

    // Calculate the position to draw the image
    const drawX = isLandscape ? (canvasSize - w) / 2 : 0;
    const drawY = isLandscape ? 0 : (canvasSize - h) / 2;

    // Draw the image on the canvas
    // ctx.drawImage(imgRef.current, drawX, drawY, w, h);
    ctx.drawImage(imgRef.current,0,0, w, h);

    imgRef.current.classList.add("d-none");

    saveResizedImage(resizeCanvasRef.current);
    updateResizeImgFlag(true);
  };


  return (
    <>
  

      <section className="sectionFD2">
        <nav className="navFD2 pt-4 ps-4">
          
          <span className="titleFD mx-2">Frameswise</span>
          <Logo  className="logoFD" />
        </nav>

        <div className="parentmaindivFD2">
          <div className="maindivFD2">
            {!errorDetection && (
              <>
                <span className="mainheadingFD2 mb-4">Processing Face!</span>
                <Ring_270_svg color={"var(--primary-color)"} width={"100px"} />
                <span className="descFD2 mt-5">It may take sometime</span>
                <span className="descFD2">
                  Please don’t quit or exit the screen
                </span>
              </>
            )}
            {errorDetection && (
              <>
                <span className="errorFD2">Please Try Again</span>
                <span className="mainheadingFD2 mb-4">
                  Unable to detect Face!
                </span>
                <button
                  className="btnFD2"
                  onClick={() => {
                    saveImageElement(null);
                    saveImageBase64(null);
                    const newProgressFlag = {
                      ...progressFlag,
                      detection: true,
                      detection2: false,
                    };
                    updateProgressFlag(newProgressFlag);
                  }}
                >
                  Try Again
                </button>
              </>
            )}
          </div>
        </div>
      </section>
      <div
        id="processing"
        className="d-none"
        style={{
          position: "absolute",
          top: "0",
          zIndex: "-1",
        }}
      >
        <img
          ref={imgRef}
          src={faces.capImageElement.src}
          onLoad={resizeImage}
          alt=""
        />
        <canvas
          ref={resizeCanvasRef}
          style={{
            left: 0,
            // width: '100%',
            // height: '100%',
          }}
        ></canvas>
      </div>
    </>
  );
};

export default FaceDetection2;
