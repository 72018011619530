import React, { useContext } from 'react'
import ThemeContext from '../../Context/ThemeContext/ThemeContext'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faqs = () => {

    const { theme, tokens } = useContext(ThemeContext)
    const colors = tokens(theme)

    return (
        <>
            <section className="d-flex flex-column " 
            style={{ 
                maxHeight: window.innerHeight - 56,
                overflow: 'auto',
                maxWidth: "100%",
                padding:'20px'
                }}>

                <span className='mt-3 px-4 py-2 fw-bold fs-3' style={{ color: colors.redAccent[450] }}>FAQS</span>
                <span className='mt-1 px-4 py-2 fs-5' style={{ color: colors.redAccent[100] }}>Frequently Asked Questions</span>

                <Accordion
                    style={{
                        background: colors.blueAccent[900],
                        color: colors.blueAccent[400],
                    }} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: colors.redAccent[400] }} />}>
                        <span style={{ color: colors.blueAccent[100] }}>
                            An Important Question
                        </span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </span>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    style={{
                        background: colors.blueAccent[900],
                        color: colors.blueAccent[400],
                    }} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: colors.redAccent[400] }} />}>
                        <span style={{ color: colors.blueAccent[100] }}>
                            An Important Question
                        </span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </span>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    style={{
                        background: colors.blueAccent[900],
                        color: colors.blueAccent[400],
                    }} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: colors.redAccent[400] }} />}>
                        <span style={{ color: colors.blueAccent[100] }}>
                            An Important Question
                        </span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </span>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    style={{
                        background: colors.blueAccent[900],
                        color: colors.blueAccent[400],
                    }} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: colors.redAccent[400] }} />}>
                        <span style={{ color: colors.blueAccent[100] }}>
                            An Important Question
                        </span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </span>
                    </AccordionDetails>
                </Accordion>

            
           
            </section>
        </>
    )
}

export default Faqs
