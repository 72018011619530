import React, { useContext, useEffect, useState } from "react";

import Navbar from "../Navbar";
import Welcome from "./Welcome";
import FaceDetection from "./FaceDetection";
import Processing from "./Processing";
import Recommendation from "./Recommendation/Recommendation";
import { useNavigate } from "react-router-dom";
import progressContext from "../../Context/progressFlag/progressContext";
import userContext from "../../Context/users/userContext";
import { ReactComponent as LoadingSpinner } from "../../assets/svgs/loading_spinner.svg";
import { ReactComponent as Info } from "../../assets/svgs/info.svg";
// import {  pdProcessorEnd } from '../../Utilities/pdProcessor'
import FaceDetection2 from "./FaceDetection2";
import Questionaire from "./Questionaire/Questionaire";
import Justification from "./Justification/Justification";
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";

import "../../assets/css/blinkers.css";
import "../../assets/css/transition.css";
import "../../assets/css/button.css";
import faceContext from "../../Context/faceDetection/faceContext";
function Home(props) {
  const navigate = useNavigate();
  const { models } = props;

  const progresscontext = useContext(progressContext);
  const { progressFlag, updateProgressFlag } = progresscontext;

  const usercontext = useContext(userContext);
  const { getUser, user, setUser } = usercontext;

  const { clearFaceState } = useContext(faceContext);

  const [checking, setChecking] = useState(true);

  const backHandler = () => {
    clearFaceState();
    const newProgressFlag = {
      ...progressFlag,
      welcome: true,
      questionaire: false,
      detection: false,
      detection2: false,
      processing: false,
      justification: false,
      resultDisplay: false,
      isSelectedItem: false,
      rescan: false,
    };
    updateProgressFlag(newProgressFlag);
  };

  useEffect(() => {
    preprocess();
  }, []);

  const preprocess = async () => {
    setChecking(true);
    const result = await getUser();
    if (result.success) {
      setUser(result.user);
      localStorage.setItem("token", result.authToken);

      if (!result.user.verified) {
        navigate("/verifyfirst");
      } else if (!models) {
        navigate("/");
      }

      setChecking(false);
    } else {
      localStorage.removeItem("token");
      navigate("/signup");
    }
  };

  return (
    <>
      {checking ? (
        <section className="sectionWelcome">
          <div className="parentmainW">
            <div className="mainW px-2">
              <Logo />
              <span className="headingW mt-3">
                Loading Your Profile <br />
                <span className="fs-4 text-white">Please Wait</span>
              </span>
              <LoadingSpinner
                style={{ marginTop: "10px", width: "40px", height: "40px" }}
              />
            </div>
          </div>
        </section>
      ) : (
        <>
          {progressFlag.welcome && <Welcome preprocess={preprocess} />}

          {progressFlag.questionaire && <Questionaire />}

          {progressFlag.detection && <FaceDetection models={models} />}

          {progressFlag.detection2 && <FaceDetection2 models={models} />}

          {progressFlag.processing && <Processing />}
          {progressFlag.justification && <Justification />}
          {progressFlag.resultDisplay && <Recommendation />}
          {/* {
              progressFlag.resultDisplay && user?.settings?.AR &&
              <RecommendationAR />
            } */}
        </>
      )}
    </>
  );
}

export default Home;
