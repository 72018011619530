import React from 'react'
import { ReactComponent as Star } from '../../assets/svgs/star.svg';


const Guide = () => {
  return (
    <>
    <div className="container ">
      
        <p><Star style={{  width: "15px", height: "15px", marginRight: "5px" }}/>Best results can be achieved when the face is inside the blue corners.</p>
        <p><Star style={{  width: "15px", height: "15px", marginRight: "5px" }}/>Face shape results will be presized if the distance is around 35 cm.</p>
        <br />
        <div className='p-4'>
        <center>
        <img src={require('../../assets/imgs/instructions/cm35.png')} className='img-fluid' style={{maxWidth:'600px',width:'100%'}} alt="" />

        </center>

        </div>
        <p><Star style={{ width: "15px", height: "15px", marginRight: "5px" }}/>Make sure there is good light and the camera is clean.</p>
        <p><Star style={{  width: "15px", height: "15px", marginRight: "5px" }}/>Sit still and do not move your head.</p>
        <p><Star style={{  width: "15px", height: "15px", marginRight: "5px" }}/>Look straight into the camera on top and take picture.</p>
        <p><Star style={{  width: "15px", height: "15px", marginRight: "5px" }}/>
        It will capture your pupil distance.
        </p>
        <p><Star style={{  width: "15px", height: "15px", marginRight: "5px" }}/>Then it will take some time to process the face for detecting face shape, age & gender.</p>
        <p><Star style={{  width: "15px", height: "15px", marginRight: "5px" }}/>You can retake the picture as many times as you want (wait for the recommendation screen and then click rescan).</p>
      </div>
    </>
  )
}

export default Guide