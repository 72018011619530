import React from "react";
import { capitalizeFirstWord } from "../../../Utilities/commonFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Pereferences = ({ selectedValue, allValues, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [opened, setOpened] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setOpened(!opened);
  };
  return (
    <>
      <div>
        <div className="position-relative">
          <div
            style={{
              position: "absolute",
              display: "flex",
              background: "#ECEDFC",
              flexDirection: "column",
              width: "100%",
              borderRadius: "10px",
            }}
            className={`${opened ? "listactiveQ" : "listQ"}`}
          >
            {allValues.map((e) => {
              return (
                e !== selectedValue && (
                  <span
                    className={`${
                      opened ? "optionactiveQ" : "optionQ"
                    } py-2 ps-3`}
                    onClick={() => {
                      onSelect(e);
                      toggleDropdown();
                    }}
                  >
                    {capitalizeFirstWord(e)}
                  </span>
                )
              );
            })}
          </div>
        </div>
        <div
          style={{
            position: "relative",
            borderRadius: "100px",
            background: "rgb(238, 238, 238)",
            transition: "1s",
            cursor: "pointer",
            fontWeight:"bold",
            width:"100%"
          }}
          className="container d-flex justify-content-between p-4 "
          onClick={toggleDropdown}
        >
          <span>{capitalizeFirstWord(selectedValue)}</span>
          {
            <FontAwesomeIcon
              style={{ transition: ".4s" }}
              className={`${opened ? "selectactiveiconQ" : ""}`}
              icon={faChevronDown}
            />
          }
        </div>
      </div>
    
    </>
  );
};

export default Pereferences;
