import React from 'react'
import Topbar from './Dashboard/Topbar'
import { useContext,useState,useEffect } from 'react'
import ThemeContext from '../../Context/ThemeContext/ThemeContext'
import Sidebar from './Dashboard/Sidebar'
import AdminPanelContext from '../../Context/AdminPanelContext/AdminPanelContext'
import History from './History/History'
import userContext from '../../Context/users/userContext'
import { ReactComponent as LoadingSpinner } from '../../assets/svgs/loading_spinner.svg';
import { useNavigate } from 'react-router-dom'
import Feedback from './Feedback'
import Settings from './Settings'
import Profile from './Profile'
import Dashboard from './Dashboard/Dashboard'
import Faqs from './Faqs'

const AdminPanel = () => {
  console.clear()
  const navigate = useNavigate();

  const { adminScreen } = useContext(AdminPanelContext)
  const { theme, tokens } = useContext(ThemeContext)
  const { getUser, user, setUser } = useContext(userContext)

  const [loaded, setLoaded] = useState(false)


  const palette = tokens(theme);

  useEffect(() => {
    preprocess()
}, [])

const preprocess = async () => {
  const result = await getUser()
  if (result.success) {

      setUser(result.user)
      localStorage.setItem('token', result.authToken)

      if (!result.user.verified) {
          navigate('/verifyfirst')
      }


  }
  else {
      localStorage.removeItem('token')
      navigate('/signup')
  }
  setLoaded(true)
}

  return (
    <>
      {!loaded &&
        <div className='d-flex flex-column  w-100 h-100' style={{
          paddingTop: '15%',
          background: palette.primary[400],
          color: palette.primary[100]
        }}>
          <div className="d-flex justify-content-center">
            <span className="fs-2 fw-bold ">Loading...</span>
          </div>
          <center >

            <LoadingSpinner style={{ marginTop: '10px', width: "80px", height: "80px" }} />
          </center>
        </div>

      }
{loaded &&
      <main className="d-flex w-100 h-100" style={{
        background: palette.primary[400]
      }}>
        <Sidebar />
        <section style={{maxHeight:"100%"}} className='w-100 '>
          <Topbar preprocess={preprocess} setLoaded={setLoaded}/>
          {
            adminScreen.dashboard &&
            <Dashboard />
          }
          {
            adminScreen.history &&
            <History />
          }
          {
            adminScreen.feedback &&
            <Feedback />
          }
          {
            adminScreen.settings &&
            <Settings />
          }
          {
            adminScreen.updateProfile &&
            <Profile />
          }
          {
            adminScreen.faqs &&
            <Faqs />
          }
        </section>
      </main>
      }
    </>
  )
}

export default AdminPanel
