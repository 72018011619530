import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Navbar from "../Navbar";
import userContext from "../../Context/users/userContext";
import { ReactComponent as LoadingSpinnerSmall } from "../../assets/svgs/loading_spinner_small_pink.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

import "../../assets/css/auth/loginsignup.css";

import { ReactComponent as Logo } from "../../assets/svgs/logoBlue.svg";

const Login = (props) => {
  const context = useContext(userContext);
  const { loginUser, getUser, setUser } = context;

  const navigate = useNavigate();

  const btnLoginRef = useRef(null);
  const left = useRef(null);

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [user, setuser] = useState({
    email: "",
    password: "",
    rememberMe:localStorage.getItem("remme")||false
  });

  const [updating, setUpdating] = useState(false);
  const [leftHeight, setLeftHeight] = useState("100%");

  const [error, setError] = useState({
    show: false,
    msg: "",
  });

  const onChange = (e) => {
    if (e.target.name==="rememberMe"){
      setuser({ ...user, rememberMe: !user.rememberMe });
    }
    else{
      setuser({ ...user, [e.target.name]: e.target.value });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    btnLoginRef.current.classList.add("d-none");
    setUpdating(true);
    setError({ ...error, show: false, msg: "" });

    const result = await loginUser(user.email, user.password,user.rememberMe);

    if (result.success) {
      localStorage.setItem("token", result.authToken);
      localStorage.setItem("remme",user.rememberMe)
      navigate("/home");
    } else {
      setError({ ...error, show: true, msg: "Invalid Creditentials!" });
      btnLoginRef.current.classList.remove("d-none");
      setUpdating(false);
    }
  };

  useEffect(() => {
    setLeftHeight(left.current.offsetHeight);
  }, []);

  useEffect(() => {
    preprocess();
  }, []);

  const preprocess = async () => {
    const result = await getUser();
    if (result.success) {
      setUser(result.user);
      localStorage.setItem("token", result.authToken);

      if (!result.user.verified) {
        navigate("/verifyfirst");
      } else {
        navigate("/home");
      }
    } else {
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      <section className="sectionLS">
        <div ref={left} className="left">
          <div className="topLeftTriangle"></div>
          <Logo
            //  width={'200px'} height={'200px'}
            className="logoLS"
          />

          <form
            className="form-container-login"
            onSubmit={handleSubmit}
            id="form"
          >
            <span className="mainHeading ">Welcome to Frameswise</span>
            <span className="subHeading mb-4">
              Please login to your account.
            </span>
            <div
              className="mb-5"
              style={{
                flexDirection: "row",
                width: "fit-content",
              }}
            >
              <Link className="active" to={"/login"}>
                LOGIN
              </Link>
              <Link className="notactive" to="/signup">
                SIGNUP
              </Link>
            </div>

            <input
              className="email-input mb-2"
              placeholder="Enter your Email"
              type="email"
              id="email"
              name="email"
              aria-describedby="emailHelp"
              onChange={onChange}
              value={user.email}
              required
            />
            <div className="password-input mb-2">
              <input
                className="ms-2"
                placeholder="Enter your Password"
                type={`${passwordVisibility ? "text" : "password"}`}
                id="password"
                name="password"
                onChange={onChange}
                value={user.password}
                required
                style={{
                  width: " 85%",
                }}
              />
              <FontAwesomeIcon
                className="mx-2"
                icon={passwordVisibility ? faEye : faEyeSlash}
                style={{
                  color: "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPasswordVisibility(!passwordVisibility);
                }}
              />
            </div>

            <div>
              {error.show && (
                <span className="text-lightRed fw-bold fs-5">{error.msg}</span>
              )}
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "start",
                display: "flex",
                justifyContent: "space-between",
              }}
              className="px-2 mb-5"
            >
              <Link className=" forgot-password" to="/forgotPassword">
                Forgot Password?
              </Link>
              <span>
                <input
                id="rememberMe"
                name="rememberMe"
                  checked={user.rememberMe}
                  onChange={onChange}
                  type="checkbox"
                  className=" forgot-password me-2"
                />
                Rememeber Me?
              </span>
            </div>
            <button type="submit" className="login-btn" ref={btnLoginRef}>
              Log In
            </button>
            {updating && (
              <LoadingSpinnerSmall
                style={{ marginTop: "10px", width: "30px", height: "30px" }}
              />
            )}
          </form>
        </div>
        <div className="rightLS" style={{ height: leftHeight }}></div>
      </section>
    </>
  );
};

export default Login;
