import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as faceapi from "@vladmandic/face-api";
import * as tf from "@tensorflow/tfjs";
import { useNavigate } from "react-router-dom";
import { FaceMesh } from "@mediapipe/face_mesh";
import { FilesetResolver, FaceLandmarker } from "@mediapipe/tasks-vision";
import "../assets/css/others/loading.css";

const Loading = (props) => {
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);

  const { setModels } = props;

  const PATH_FACE_API = process.env.PUBLIC_URL + "/assets/models/face-api";
  // const PATH_MEDIAPIPE_FACELANDMARKER = process.env.PUBLIC_URL + '/assets/models/mediapipe-facemesh/'
  const PATH_MEDIAPIPE_FACELANDMARKER =
    "https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task";

  useEffect(() => {
    loadModels();
  }, []);

  async function loadModels() {
    await tf.ready();
    setProgress(10);
    const model_mediapipe = await loadMediapipeFaceMesh();
    setProgress(25);

    const model_pddetector = await copyMediaPipe(model_mediapipe);

    setProgress(35);

    const model_tinyFace = await loadTinyFaceDetector();

    setProgress(80);
    const model_agegender = await loadAgeGenderNet();

    setProgress(100);

    setModels({
      tinyface: model_tinyFace,
      agegender: model_agegender,
      facemesh: model_mediapipe,
      pd: model_pddetector,
    });
    console.clear();
    navigate('/home')
  }


  async function loadTinyFaceDetector() {
    await faceapi.nets.tinyFaceDetector.loadFromUri(PATH_FACE_API);

    // You can load other models here if needed
    return faceapi.nets.tinyFaceDetector;
  }
  async function loadAgeGenderNet() {
    await faceapi.nets.ageGenderNet.loadFromUri(PATH_FACE_API);
    // You can load other models here if needed
    return faceapi.nets.ageGenderNet;
  }

  async function loadMediapipeFaceMesh() {
    const vision = await FilesetResolver.forVisionTasks(
      // path/to/wasm/root
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
    );
    const model = await FaceLandmarker.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: PATH_MEDIAPIPE_FACELANDMARKER,
      },
      runningMode: "IMAGE",
    });

    return model;
  }

  async function copyMediaPipe(model) {
    const newModel = model;
    await newModel.setOptions({ runningMode: "VIDEO" });
    return newModel;
  }

  return (
    <>
     

      <section className="sectionLoading">
        <div className="mainLoading py-3">
          <span className="mainHeadingLoading ">Welcome to Frameswise App</span>
          <span className="taglineHeadingLoading mt-5">
            {progress!=100 && <>Loading...</>}
            {progress===100 && <>Complete!</>}
            </span>
          <div className="loaderL mt-5">
            <div className="fillL" style={{width:`${progress}%`}}></div>
          </div>
          <div className="" style={{ width: "543px" }}>
            <div className="shadowL"></div>
          </div>
          <div className="textDivL mt-5">
            <span className="subHeadingL">Please Note!</span>
            <span className="descL">
              This app is in early development stage and you might experience
              incomplete features or bugs in app. Please bear with us as we are
              working on it.
            </span>
            <span className="version">v0.5.3</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Loading;
