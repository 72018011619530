import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { personalityTraits } from "../../../Utilities/const";
import { getIndexInSubArray } from "../../../Utilities/DecisionMaker/utils";



function PersonalityQuestion(props) {
  // console.log(props.personality)
  // console.log(getIndexInSubArray(personalityTraits,props.personality))
  // console.log(personalityTraits.findIndex(props.personality))
   const [index, setIndex] = useState(9);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="w-100 fw-bold text-black">
          <center>Personality</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="slidecontainerQ ">
          <input
            onChange={(e) => {
              setIndex(e.target.value);
            }}
            className="sliderQ"
            type="range"
            min="0"
            max="18"
            value={index}
            step={1}
            id="myRange"
          />
        </div>
        <div className="mb-5">
          <span style={{float:"left"}}>Introvert</span>
          <span style={{float:"right"}}>Extrovert</span>
        </div>
        <div className="mb-2">
          <span className="personalityheadingQ ">
            {personalityTraits[index][0]}
          </span>
        </div>
        <div className="mb-2">
          <span className="personalitydescQ ">
            {personalityTraits[index][1]}
          </span>
        </div>
        
      </Modal.Body>
      <Modal.Footer className="w-100 d-flex justify-content-center">
        <Button className="btnQ" onClick={props.onGoBack}>Cancel</Button>
        <Button className="btnQ" onClick={() => props.onNext(personalityTraits[index][0])}>
          Next
        </Button>
      </Modal.Footer>
    </Modal>
    
  );
}

export default PersonalityQuestion;
