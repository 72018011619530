import React from "react";

const Right_arrow_svg = ({color,...props}) =>  (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 15"
      {...props}
    >
      <path
        d="M47.2071 8.20711C47.5976 7.81658 47.5976 7.18342 47.2071 6.79289L40.8431 0.428932C40.4526 0.0384078 39.8195 0.0384078 39.4289 0.428932C39.0384 0.819457 39.0384 1.45262 39.4289 1.84315L45.0858 7.5L39.4289 13.1569C39.0384 13.5474 39.0384 14.1805 39.4289 14.5711C39.8195 14.9616 40.4526 14.9616 40.8431 14.5711L47.2071 8.20711ZM0.5 8.5H46.5V6.5H0.5V8.5Z"
        fill={color}
      />
    </svg>
  );

export default Right_arrow_svg;
