import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import userContext from '../../Context/users/userContext';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LoadingSpinnerSmallPink } from '../../assets/svgs/loading_spinner_small_pink.svg';
import Tooltip from "rc-tooltip";
import { useEffect } from 'react';
import { capitalizeFirstWord } from '../../Utilities/commonFunction';
import { hairColorTraits, hairStyleTraits, personalityTraits } from '../../Utilities/const';
import ThemeContext from '../../Context/ThemeContext/ThemeContext';
const Settings = () => {

    const [hairColors, setHairColors] = useState([])
    const [hairStyles, setHairStyles] = useState([])
    const [personalityList, setPersonalities] = useState([])

    for (let i = 0; i < hairColorTraits.length; i++) {
        hairColors.push(hairColorTraits[i][0])
    }
    for (let i = 0; i < hairStyleTraits.length; i++) {
        hairStyles.push(hairStyleTraits[i][0])
    }
    for (let i = 0; i < personalityTraits.length; i++) {
        personalityList.push(personalityTraits[i][0])
    }

    const { theme, tokens } = useContext(ThemeContext)
    const colors = tokens(theme)
    const btnStyle = {
        background: colors.primary[400],
        color: colors.primary[100],
        border: `1px solid ${colors.primary[100]}`,
        width:"fit-content"
    }

    const { user, updateProfile } = useContext(userContext)

    const [disableUpdate, setDisableUpdate] = useState(true)
    const [oldSettings, setOldSettings] = useState(user.settings)
    const [newSettings, setNewSettings] = useState({
        AR: user?.settings?.AR ? user.settings.AR : false,
        facingMode: user?.settings?.facingMode || 'user',
        askQuestionaire: user?.settings?.askQuestionaire ? user.settings.askQuestionaire : true,
        questionaireEnd: user?.settings?.questionaireEnd ? user.settings.questionaireEnd : false,
        recommendationLimit: user?.settings?.recommendationLimit ? user.settings.recommendationLimit : 4,
        showGender: user?.settings?.showGender ? user.settings.showGender : false,
        showAge: user?.settings?.showAge ? user.settings.showAge : false,
        askEyeColor: user?.settings?.askEyeColor ? user.settings.askEyeColor : false,
        defaultHairStyle: user?.settings?.defaultHairStyle || 'straight',
        defaultHairColor: user?.settings?.defaultHairColor || 'black',
        defaultEyeColor: user?.settings?.defaultEyeColor || 'black',
        defaultPersonality: user?.settings?.defaultPersonality || 'Adventure',
        limitHistoryRecords: user?.settings?.limitHistoryRecords || 5

    })

    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState({
        status: "danger",
        show: false,
        msg: ""
    })


    useEffect(() => {

        if (JSON.stringify(newSettings) === JSON.stringify(oldSettings)) {
            setDisableUpdate(true)
        } else {
            setDisableUpdate(false)
        }
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault()

        setUpdating(true)

        // console.log(newSettings)

        const result = await updateProfile(newSettings)
        if (result.Success == "true") {
            setError({
                status: "success",
                show: true,
                msg: "Profile Updated"
            })
            setDisableUpdate(true)
            setOldSettings(newSettings)
        }
        else {
            setError({
                status: "danger",
                show: true,
                msg: "Unable to update"
            })
        }

        setUpdating(false)
    }

    const changeSettings = (settingName, settingValue) => {

        if (settingName === "recommendationLimit") {
            const newLimit = settingValue + 1 > 4 ? 1 : settingValue + 1
            const newestSettings = { ...newSettings, [settingName]: newLimit }
            setNewSettings({ ...newSettings, [settingName]: newLimit })
            if (JSON.stringify(newestSettings) === JSON.stringify(oldSettings)) {
                setDisableUpdate(true)
            } else {
                setDisableUpdate(false)
            }
        }

        else if (settingName === "facingMode") {

            if (newSettings.facingMode === "user") {
                const newestSettings = { ...newSettings, [settingName]: 'environment' }
                setNewSettings({ ...newSettings, [settingName]: 'environment' })
                if (JSON.stringify(newestSettings) === JSON.stringify(oldSettings)) {
                    setDisableUpdate(true)
                } else {
                    setDisableUpdate(false)
                }
            }
            else {
                const newestSettings = { ...newSettings, [settingName]: 'user' }
                setNewSettings({ ...newSettings, [settingName]: 'user' })
                if (JSON.stringify(newestSettings) === JSON.stringify(oldSettings)) {
                    setDisableUpdate(true)
                } else {
                    setDisableUpdate(false)
                }
            }



        }


        else {
            const newestSettings = { ...newSettings, [settingName]: !settingValue }

            setNewSettings({ ...newSettings, [settingName]: !settingValue })

            if (JSON.stringify(newestSettings) === JSON.stringify(oldSettings)) {
                setDisableUpdate(true)
            } else {
                setDisableUpdate(false)
            }
        }



    }

    const onChange = (e) => {
        setNewSettings({ ...newSettings, [e.target.name]: parseInt(e.target.value) })
        const newestSettings = { ...newSettings, [e.target.name]: parseInt(e.target.value) }
        if (JSON.stringify(newestSettings) === JSON.stringify(oldSettings)) {
            setDisableUpdate(true)
        } else {
            setDisableUpdate(false)
        }
    }

    const selectOptionChange = (e) => {
        const newestSettings = { ...newSettings, [e.target.name]: e.target.value }

        setNewSettings({ ...newSettings, [e.target.name]: e.target.value })

        if (JSON.stringify(newestSettings) === JSON.stringify(oldSettings)) {
            setDisableUpdate(true)
        } else {
            setDisableUpdate(false)
        }
    }

    return (
        <>


            <section className="d-flex flex-column " style={{  
                maxHeight: window.innerHeight-56, 
                overflow: 'auto', 
                maxWidth: "100%" 
                }}>

                <form className='d-flex w-100 flex-column flex-wrap p-3' onSubmit={handleSubmit} style={{
                    background: colors.blueAccent[900],
                    color: colors.primary[100]
                }}>
                    <span className=" fw-bold fs-2">Change Settings For Your Experience</span>

                    <span style={{
                        color: colors.blueAccent[100]
                    }} className=" fw-bold fs-6 mb-5"> Here You can Adjust the settings. Hover or tap over the option to learn more about it. </span>

                    <div className='d-flex  align-items-center mb-3'>

                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>Select your initial camera selection. You can also change camera during scanning.<br /> <b>Note:</b> This feature is added for those who face bugs during camera view change.</span>}
                        >
                            <span className='me-3 '>Change Camera facing mode</span>
                        </Tooltip>

                        <button type="button" style={btnStyle} className="btn"
                            onClick={() => { changeSettings('facingMode', newSettings.facingMode) }}

                        >{newSettings.facingMode || "user"}</button>
                    </div>



                    <div className='d-flex  align-items-center mb-3'>

                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>Turn On to ask question from customer about their personal attributes.<br /> <b>Note:</b> Turning off means the software will take default answers.</span>}
                        >
                            <span className='me-3 '>Ask Questionaire</span>
                        </Tooltip>

                        <button type="button" className="btn" style={btnStyle}
                            onClick={() => { changeSettings('askQuestionaire', newSettings.askQuestionaire) }}

                        >{newSettings.askQuestionaire ? "YES" : "NO"}</button>
                    </div>

                    <div className='d-flex  align-items-center mb-3'>
                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>Asking question after face scanning or before?<br /> </span>}
                        >
                            <span className='me-3 '> Ask Questionaire in the end</span>
                        </Tooltip>

                        <button type="button" className="btn" style={btnStyle}
                            onClick={() => { changeSettings('questionaireEnd', newSettings.questionaireEnd) }}
                        >{newSettings.questionaireEnd ? "YES" : "NO"}</button>
                    </div>
                    <div className='d-flex  align-items-center mb-3'>
                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>Limit the number of maximum glasses being recommended.<br /> Max value 4 and min value 1. </span>}
                        >
                            <span className='me-3 '> Limit Reccommendation Glasses</span>
                        </Tooltip>

                        <button style={btnStyle} type="button" onClick={() => { changeSettings('recommendationLimit', newSettings.recommendationLimit) }} className="btn btn-secondary">{newSettings.recommendationLimit ? newSettings.recommendationLimit : 4}</button>
                    </div>
                    <div className='d-flex  align-items-center mb-3'>
                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>Displays a screen after scanning telling user about their gender that is detected<br /> </span>}
                        >
                            <span className='me-3 '>Show Gender on scan</span>
                        </Tooltip>


                        <button style={btnStyle} type="button" onClick={() => { changeSettings('showGender', newSettings.showGender) }} className="btn btn-secondary" >{newSettings.showGender ? "YES" : "NO"}</button>
                    </div>
                    <div className='d-flex  align-items-center mb-3'>
                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>Displays a screen after scanning telling user about their age that is detected<br /></span>}
                        >
                            <span className='me-3 '>Show Age on scan</span>
                        </Tooltip>



                        <button style={btnStyle} type='button'
                            onClick={() => { changeSettings('showAge', newSettings.showAge) }}
                            className="btn btn-secondary" >{newSettings.showAge ? "YES" : "NO"}</button>
                    </div>

                    <div className='d-flex  align-items-center mb-3'>
                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>Number of Records to be displayed in history tab<br /> Max value is 20 minimum is 1. Please note that it will directly effects the loading time of history records. </span>}
                        >
                            <span className='me-3 '>Number of Records in each load?</span>
                        </Tooltip>

                        <input name='limitHistoryRecords' id='limitHistoryRecords' type="number"
                            required
                            onChange={onChange} value={newSettings.limitHistoryRecords} max={20} min={1} className="" style={{}} />
                    </div>

                    <div className='d-flex  align-items-center mb-3'>
                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>This option will work if questionaire is disabled.<br /> It will take default values. </span>}
                        >
                            <span className='me-3 '>Default value for hair style</span>
                        </Tooltip>

                        <select name='defaultHairStyle' value={newSettings?.defaultHairStyle || "curly"} onChange={selectOptionChange}>
                            {
                                hairStyles.map((color) => (
                                    <option
                                        name='defaultHairStyle' value={color}>{capitalizeFirstWord(color)} hair style</option>
                                ))

                            }
                        </select>
                    </div>



                    <div className='d-flex  align-items-center mb-3'>
                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>This option will work if questionaire is disabled.<br /> It will take default values. </span>}
                        >
                            <span className='me-3 '>Default value for hair color</span>
                        </Tooltip>

                        <select name='defaultHairColor' value={newSettings?.defaultHairColor || "black"} onChange={selectOptionChange}>
                            {
                                hairColors.map((color) => (
                                    <option
                                        name='defaultHairColor' value={color}>{capitalizeFirstWord(color)} hair Color</option>
                                ))

                            }
                        </select>
                    </div>

                    <div className='d-flex  align-items-center mb-3'>
                        <Tooltip
                            placement="top"
                            overlay={<span className='fs-6'>This option will work if questionaire is disabled.<br /> It will take default values. </span>}
                        >
                            <span className='me-3 '>Default value for Personality</span>
                        </Tooltip>

                        <select name='defaultPersonality' value={newSettings?.defaultPersonality || "black"} onChange={selectOptionChange}>
                            {
                                personalityList.map((color) => (
                                    <option
                                        name='defaultPersonality' value={color}>{capitalizeFirstWord(color)}</option>
                                ))

                            }
                        </select>
                    </div>

                    {error.show &&
                        <span className={`text-${error.status} fw-bold fs-5 mb-3`}>
                            {error.msg}
                        </span>
                    }
                        {!updating && <button style={btnStyle} type="submit" className="btn" disabled={disableUpdate}>Update</button>}
                        {updating && <LoadingSpinnerSmallPink height={"30px"} width={"30px"}/>}

                </form >
            </section >
        </>
    )
}

export default Settings
