import React, { useContext, useEffect, useRef, useState } from 'react'
import ordersContext from '../../../Context/orders/ordersContext';
import '../../../assets/css/inputNumber.css'
import { convertToTimeElapsed, capitalizeFirstWord } from '../../../Utilities/commonFunction'
import { ReactComponent as LoadingSpinnerSmall } from '../../../assets/svgs/loading_spinner_small.svg';
import { Link } from 'react-router-dom';



const HistroyItems = (props) => {
    const colorsListNames = ['Blue', 'silver/gray', 'green', 'pink', 'gold',
        //0to 4
        'red', 'brown', 'yellow', 'tortoise', 'peaches', 'cream', 'Blod', //5 to 11
        'black', 'orange', 'white', 'purple', 'burgundy']//12 to 16
    const colorsListHex = ['#005AFF', '#A8ABAE', '#154734', '#F88CAE', '#FFD971',
        //0to 4
        '#E31937', '#AA875C', '#FFEB00', '#754734', '#F4A6A3', '#C2CDA5', '#FF7F7F', //5 to 11
        '#20211A', '#EA871E', '#E5F1ED', '#330072', '#651D32']//12 to 16




    const imageRef = useRef(null)


    const { order, updateId, updateProgressFlag, colors } = props;

    const [editedOrder, setEditedOrder] = useState({
        id: order._id,
        name: order.name,
        age: order.accurateAge,
        preDeadline: order.prescriptionDeadline,
        status: order.orderStatus
    })

    const index = colorsListHex.indexOf(order.colorFrame);

    var frameColorName = 'Asthetic';
    if (index !== -1) {
        frameColorName = colorsListNames[index]
    } else if (order.colorFrame) {
        frameColorName = order.colorFrame
    }
    else {
        frameColorName = 'Asthetic'

    }


    return (
        <>

            <div className="col-sm-12 col-md-4 col-lg-3">
                <div className="card bg-transparent " style={{
                    maxWidth: '300px',
                    backdropFilter: 'blur(10px)',
                    borderLeft: '1px solid rgba(255, 255, 255, 0.3)',
                    borderTop: '1px solid rgba(255, 255, 255, 0.3)',
                    boxShadow: ' 0 5px 15px rgba(0, 0, 0, 0.3)',
                    background: colors.blueAccent[900],
                    color: colors.primary[100],
                }}>
                    <div className="card-header fw-bold " style={{textAlign:'center',color: colors.primary[100]}}>
                        {editedOrder.name || 'Anonymous'}
                    </div>
                    <img className="card-img-top img-fluid rounded w-100" ref={imageRef} src={order.image ? order.image : require('../../../assets/imgs/account_female.png')} alt="..." />

                    <div className="card-body">
                        <h5 className="card-title" style={{
                            color: colors.redAccent[100]
                        }}>Detections</h5>
                        <span className="card-text">
                            Face Shape : {capitalizeFirstWord(order.faceShape)}<br />
                            PD : {order.pupilDistance}<br />
                            Selected frame : {capitalizeFirstWord(order.selectedFrame)} <br />
                            Color : {capitalizeFirstWord(frameColorName)}<br />
                            Status : {editedOrder.status}<br />

                        </span>
                        <button
                            style={{
                                background: colors.primary[400],
                                color: colors.primary[100],
                                border: `1px solid ${colors.primary[100]}`,
                                float: 'right',
                                width: 'fit-content',
                                borderRadius: '5px'
                            }}
                            onClick={() => {
                                updateId(order._id)
                                updateProgressFlag({
                                    list: false,
                                    detail: true
                                })
                            }}
                            className="btn mt-4 mb-2">{`>>ViewDetails`}</button>
                    </div>
                    <div className="card-footer" style={{
                        color: colors.blueAccent[300]
                    }}>
                        {convertToTimeElapsed(order.date)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HistroyItems