import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import userContext from '../../Context/users/userContext';
import Navbar from '../Navbar';
import { ReactComponent as PinkSpinner } from '../../assets/svgs/loading_spinner_small_pink.svg';
import StarRating from '../../Utilities/StarRating';
import { ReactComponent as LoadingSpinner } from '../../assets/svgs/loading_spinner.svg';
import FeedbackContext from '../../Context/FeedbackContext/FeedbackContext';
import ThemeContext from '../../Context/ThemeContext/ThemeContext';



const Feedback = () => {


    const { user } = useContext(userContext)


    const uploadRef = useRef(null)


    const feedbackContext = useContext(FeedbackContext)
    const { saveFeedback } = feedbackContext

    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState({ email: user.email, exp: '' })
    const [uploading, setUploading] = useState(false)

    const [error, setError] = useState({
        show: false,
        msg: ""
    })
    const [success, setSuccess] = useState({
        show: false,
        msg: ""
    })

    const { theme, tokens } = useContext(ThemeContext)
    const colors = tokens(theme)

    const onChange = (e) => {
        setFeedback({ ...feedback, [e.target.name]: e.target.value })

        if (e.target.name === 'exp') {
            e.target.style.height = 'auto'; // Reset the height first
            e.target.style.height = e.target.scrollHeight + 'px'; // Set the new height
        }
    }

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const submitHandler = async (e) => {
        e.preventDefault()

        if (rating === 0) {
            setError({
                show: true,
                msg: "Please provide rating"
            })
            uploadRef.current.classList.remove('d-none')
        }
        else {
            uploadRef.current.classList.add('d-none')
            setUploading(true)


            const result = await saveFeedback('frameswise', rating, feedback.email, feedback.exp)


            if (!result) {
                setError({
                    show: true,
                    msg: "Unable to post feedback. Please try again"
                })
                uploadRef.current.classList.remove('d-none')
            }
            else {
                setSuccess({
                    show: true,
                    msg: "Your suggestion has been submitted"
                })

            }

        }
        setUploading(false)
    }

    return (
        <>
            <section className=" w-100 ps-4" style={{
                background: colors.blueAccent[900],
                color: colors.primary[100],
                maxHeight: window.innerHeight - 56,
                overflow: 'auto',
                maxWidth: "100%",
                minWidth: "100%",
                paddingTop:'20px'
            }}>
                <center>
                    <div className=' my-3'>
                        <span className=" fs-2 fw-bold">Provide us your Feedback!</span>
                    </div>
                    <div className=' mb-2'>
                        <span className=" fs-4 ">How do you feel about the app or report a bug here? We shall bring improvements</span>
                    </div>
                </center>





                <div className=" mt-5 px-3 py-3" style={{ borderRadius: '10px' }}>
                    <form onSubmit={submitHandler}>

                        <div className="row">

                            <div className="mb-3 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="email" className="form-label  fs-4 fw-bold">Your Email address</label>
                                <input required disabled onChange={onChange} type="email" className="form-control" id="email" name='email' value={user.email} aria-describedby="emailHelp"
                                style={{
                                    background:colors.primary[800],
                                    color:colors.primary[100]
                                }} />
                            </div>



                            <div className="mb-3 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="email" className="form-label  fs-4 fw-bold">Ratings</label>
                                <StarRating rating={rating} onChange={handleRatingChange} />

                            </div>



                        </div>

                        <div className='mb-2 '>
                            <span className=" fs-4 fw-bold">Your Suggestion</span>
                        </div>
                        <div className="form-floating mb-3">

                            <textarea className="form-control" placeholder="Leave a comment here" name='exp' id="exp"
                                style={{
                                    background:colors.primary[800],
                                    height: 'fit-content',
                                    minHeight: '200px',
                                    overflowY: 'auto',
                                    maxHeight: '600px',
                                    color:colors.primary[100]

                                }}
                                required
                                value={feedback.exp} onChange={onChange}></textarea>
                            <label htmlFor="exp ">Give your suggestions:</label>
                        </div>

                        {success.show &&
                            <div className="d-flex my-2">
                                <span className="text-success fw-semibold fs-5">
                                    {success.msg}
                                </span>
                            </div>
                        }
                        {error.show &&
                            <div className="d-flex my-2">
                                <span className="text-danger fw-semibold fs-5">
                                    {error.msg}
                                </span>
                            </div>
                        }


                        <button style={{
                            background: colors.primary[400],
                            color: colors.primary[100],
                            border: `1px solid ${colors.primary[100]}`
                        }} type="submit" ref={uploadRef} className="btn mx-2">Submit</button>

                        {uploading &&
                            <div className='mb-5'>
                                <PinkSpinner style={{ marginTop: '10px', width: "30px", height: "30px" }} />
                            </div>
                        }
                    </form>
                </div>

            </section>



        </>
    )
}

export default Feedback
