const personalityTraits = [
    ["Introverted","These choices allow them to wear glasses without drawing too much attention to themselves"],

    ["Pessimistic","Earth tones, blacks, and grays can convey a more reserved and understated aesthetic.Familiar shapes and styles can provide a sense of stability and comfort"],

    ["Indoor","Practicality may opt for minimalist frames that are versatile and easy to pair with different outfits"],

    ["Logical","They might choose frames with clean lines and minimalist designs, avoiding unnecessary embellishments"],

    ["Professional","Neutral colors and traditional shapes can convey a polished and serious demeanor"],

    ["Easygoing","Comfortable shapes, light materials, and a laid-back aesthetic can complement their easygoing nature"],

    ["Perfectionist","Frames with simple lines, sleek designs, and a lack of unnecessary embellishments might appeal to their preference for precision"],
//7
    ["Conscientious","They may prefer classic and timeless frame styles that convey a polished and put-together appearance"],

    ["Ambivert","They might opt for styles that can work well in different social settings, striking a balance between boldness and subtlety"],

    ["Analytical","They might choose eyeglass frames with clean lines and a contemporary design, reflecting their preference for precision and efficiency"],

    ["Nurturing","Rounded shapes, warm colors, and materials like acetate can convey a nurturing and friendly vibe"],

    ["Empathetic","Rounded frames, soft colors, and designs that enhance the eyes can convey a friendly and approachable demeanor"],

    ["Optimistic","Colorful and playful frame styles and lightweight and have an airy or open design might appeal to optimistic individuals"],

    ["Independent","They might choose eyeglasses that stand out, featuring unconventional shapes, bold colors, or distinctive materials"],

    ["Spontaneous","They might be drawn to bold and playful frame designs with vibrant colors, unconventional shapes, or eye-catching patterns"],

    ["Confident","Bold or unconventional frames can reflect a strong personality, indicating a willingness to stand out"],

    ["Adventurous","Lightweight materials and designs that stay in place during physical activities might be preferred"],

    ["Creative","Unconventional shapes, colors, and materials may appeal to their artistic sensibilities"],

    ["Extroverted","They might choose frames with unique shapes, vibrant colors, or distinctive patterns to make a statement"],
] 


const hairColorTraits=[
    ["blonde","Complements the Lighter frames in shades like tortoiseshell pastels or metal frames can complement blonde hair Contrast the Darker frames such as black or deep browns can provide a striking contrast"],

    ["brunette","Complement: Rich, warm tones like browns, greens, or reds can complement darker hair colors. Contrast: Lighter frames, like metal or clear frames, can create a nice contrast."],

    ["red","Complement: Earthy tones like greens and browns can complement red hair, while gold or warm-colored frames can enhance the overall look. Contrast: Navy or darker frames can provide a pleasing contrast."],

    ["gray","Complement: Lighter frames in shades like tortoiseshell, pastels, or metal frames can complement blonde hair. Contrast: Darker frames, such as black or deep browns, can provide a striking contrast."],

    ["auburn","Complement: Lighter frames in shades like tortoiseshell, pastels, or metal frames can complement blonde hair. Contrast: Darker frames, such as black or deep browns, can provide a striking contrast."],

    ["black","Complement: Black hair often pairs well with a variety of frame colors. Classic black frames can provide a sleek and cohesive look. Additionally, deep browns, tortoiseshell patterns, and dark purples can complement black hair by adding warmth without creating too much contrast. Contrast: If you prefer a more contrasting look, you can opt for frames in lighter colors such as metallic tones, clear frames, or even bold colors like red or white. These can create a striking visual contrast against the dark backdrop of black hair."]

]
const hairStyleTraits=[
    ["short","Short hair allows your face to be more visible, so you can experiment with bolder and more distinctive frames."],

    ["long","Lighter or rimless frames work well with long, flowing hair as they won't overpower your face"],

    ["curly","Frames with softer, rounded edges can complement the natural curves of curly or wavy hair."],

    ["straight","Angular or square frames can add structure to straight hair, providing a nice contrast"],

    ["updo","Since an updo exposes more of your face, focus on frames that draw attention to your eyes. Cat-eye or bold frames can work well."],

    ["bangs","If you have bangs, consider the height of the frames. You don't want them to compete with your bangs, so choose frames that sit above the fringe or are small enough not to obscure them."],

    ["pixiecut","A pixie cut often creates a more delicate and feminine look. Consider smaller frames that complement this style."]

]


module.exports={
    personalityTraits,
    hairColorTraits,
    hairStyleTraits
}