import React from "react";
import Accordion from 'react-bootstrap/Accordion';
export default function Faqs() {

  return (

    <>

      <Accordion className="container">
        <Accordion.Item eventKey="0">
          <Accordion.Header>How the app works?</Accordion.Header>
          <Accordion.Body>
            <strong>The Frameswise webapp is based on machine learning AI models.</strong> It simply takes the picture containing human faces and process it to detect multiple things like face shape, gender, age and pupil distance.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>How the frames are recommended?</Accordion.Header>
          <Accordion.Body>
            Frames recommendation is based on processed results like <strong>age, gender & shape</strong>. There are four catagories in which frame is recommended <strong>most, highly, fairly & low</strong>. Most recommended is the frame that is suited for face from all 3 detections ie age, gender & shape. Highly recommended frames are suited by gender and face shape. Fairly recommended frames are suited by age and face shape. Low recommended are suited by age and gender wise.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header> What is the pricing scheme?</Accordion.Header>
          <Accordion.Body>
            <strong>This webapp is completely free to use.</strong> It is in testing mode and it is avaiable to free to all users.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>What is the privacy policy?</Accordion.Header>
          <Accordion.Body>
            We do not have any privacy policy while the app is in testing mode.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Is our data stored?</Accordion.Header>
          <Accordion.Body>
            We store the data of only those scans that selects the frame at the end. However it can be deleted in the history tab. Apart from that we also store your login informations.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Is it must to have our email verified?</Accordion.Header>
          <Accordion.Body>
            <strong>Yes.</strong> To make sure we have legitmate testers we made this policy.
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>


  

    </>
  );
}
