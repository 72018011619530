import { findCommonElements} from './utils';
const colorTypes = [
    //0 to 3
    'black', 'brightred', 'brown', 'electricblue',
    //4 to 7 
    'grey', 'oranges', 'pink', 'tortoise', 
    //8 to 9
    'vividgreen', 'yellow'
]
const glassesTypes = [ 
    //0 to 3
    'aviator','browline','cateye','geometric',
    //4 to 7
    'oval','oversized','rectangle',   'round', 
    //8
    'square'
]

const ages=["x<18","18<x<55","x>55"]
const genders=["male","female"]

const frameOnAge=[
    [2,3,7,8],[0,1,2,3,4,5,6,7,8],[0,1,7,8]
]
const colorOnAge=[
    [1,3,8,5],[0,2,4,7],[7,0,2]
]

const frameOnGender=[[0,1,3,4,5,6,7,8],[0,1,2,3,4,5,6,7,8]]
const colorOnGender=[[0,3,4,7,8],[0,1,2,3,4,5,6,7,8]]



export const GenderAgeRecommend = (gender,age) => {

    const ageCategory=age<18?'x<18':age<55?'18<x<55':'x>55'

    const numberGender=genders.findIndex(x =>x===gender)
    const numberAge=ages.findIndex(x =>x===ageCategory)

    const frameGender = frameOnGender[numberGender]
    const frameAge = frameOnAge[numberAge]

    const colorGender = colorOnGender[numberGender]
    const colorAge = colorOnAge[numberAge]


    var framesGender=[];
    var framesAge=[];
    var colorsGender=[];
    var colorsAge=[];

    
    frameGender.forEach(n => {
        framesGender.push(glassesTypes[n])
    });
    frameAge.forEach(n => {
        framesAge.push(glassesTypes[n])
    });

    colorGender.forEach(n => {
        colorsGender.push(colorTypes[n])
    });

    colorAge.forEach(n => {
        colorsAge.push(colorTypes[n])
    });


    const frames=findCommonElements(framesGender,framesAge)
    const colors=findCommonElements(colorsGender,colorsAge)

    const recommendation = {frames,colors}
    return recommendation

}

