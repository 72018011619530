const glassesTypes = [
    //0 to 3
    'aviator', 'browline', 'cateye', 'geometric',
    //4 to 7
    'oval', 'oversized', 'rectangle', 'round',
    //8
    'square'
]
const colorTypes = [
    //0 to 3
    'black', 'brightred', 'brown', 'electricblue',
    //4 to 7 
    'grey', 'oranges', 'pink', 'tortoise', 
    //8 to 9
    'vividgreen', 'yellow'
]
const personalities = [
    //0 to 3
    'Introverted', 'Pessimistic', 'Indoor', 'Traditional',
    //4 to 7
    'Logical', 'Professional', 'Easygoing', 'Perfectionist',
    //8 to 11
    'Conscientious', 'Ambivert', 'Analytical', 'Nurturing',
    //12 to 15
    'Empathetic', 'Optimistic', 'Independent', 'Spontaneous',
    //16 to 19
    'Confident', 'Adventurous', 'Creative', 'Extroverted'
]
const frameRecommendation = [
    //0 to 3
    [4, 7, 6], [6, 4, 7, 0], [6, 7, 1, 2], [4,7,6],
    //4 to 7
    [6,7,8,1],[1,8,4,0,6],[7,4],[6,7,8,1],
    //8 to 11
    [6,7,4,2,1,8],[0,8,1],[6,1,3],[7,4,1,2],
    //12 to 15
    [7,6,4],[7,2,3,5],[5,0,3],[3,7,2,5,0],
    //16 to 19
    [4,7,6],[7,1,4,8],[3,7,2,5,0],[0,2,3,5]
]
const colorRecommendation=[
    //0 to 3
    [0,2,4,7],[0,2,4,7],[0,2,4,7],[0,2,4,7],
    //4 to 7
    [0,2,4,7,3,8,1],[0,2,4,7],[0,2,4,7],[0,2,4,5,6,9],
    //8 to 11
    [0,2,4,7,9],[0,2,4,7,3,8,1],[0,4],[0,2,4,7,9,1],
    //12 to 15
    [0,2,4,7,3,8,1],[1,3,8,5],[1,3,9,6,8,5],[1,3,8],
    //16 to 19
    [4,1,3,9,8],[1,3,8,5,9,2],[4,1,3,9,8],[1,3,8,7]
]

export const personalityBase = (personality) => {
    
    const number=personalities.findIndex(x =>x===personality)
    const frameNumber=frameRecommendation[number]
    const colorNumber=colorRecommendation[number]
    var frames=[];
    var colors=[];
    frameNumber.forEach(n => {
        frames.push(glassesTypes[n])
    });
    colorNumber.forEach(n => {
        colors.push(colorTypes[n])
    });
    
    
    const recommendation = {
        frame: frames,
        color: colors
    }
    return recommendation
    
}

