
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import React, { useState } from 'react'

import FaceState from './Context/faceDetection/FaceState';
import FrameState from './Context/recommendedFrames/FramesState';
import OrderState from './Context/orders/OrdersState';
import ProgressState from './Context/progressFlag/ProgressState';
import UserState from './Context/users/UserState';
import ThemeState from './Context/ThemeContext/ThemeState';

import Login from './Components/Auth/Login';
import Signup from './Components/Auth/Signup';
import EmailVerified from './Components/Auth/EmailVerified';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import VerifyFirst from './Components/Auth/VerifyFirst';

import Loading from './Components/Loading';
import Home from './Components/MainStory/Home';

import AdminPanel from "./Components/Admin/AdminPanel";
// import History from './Components/Admin/History/History';
// import Alert from './Components/Others/Alert';
// import Admin from './Components/Admin/Admin';
// import Settings from './Components/Admin/Settings';
// import Profile from './Components/Admin/Profile';
// import Feedback from './Components/Admin/Feedback';

import FaqsGuide from './Components/Others/FaqsGuide';

import "rc-tooltip/assets/bootstrap.css";
import FeedbackState from './Context/FeedbackContext/FeedbackState';
import AdminPanelState from "./Context/AdminPanelContext/AdminPanelState";

function App() {

  const [models, setModels] = useState(null)


  return (
    <>
      <AdminPanelState>
        <ThemeState>
          <FeedbackState>
            <OrderState>
              <FaceState>
                <FrameState>
                  <ProgressState>
                    <UserState>
                      <BrowserRouter>

                        <Routes>


                          <Route exact path='/login' element={<Login />} />
                          <Route exact path='/signup' element={<Signup />} />
                          <Route exact path='/forgotPassword' element={<ForgotPassword />} />
                          <Route exact path='/api/auth/verifyuser/:id/:token' element={<EmailVerified />} />
                          <Route exact path='/api/auth/reset/:id/:token' element={<ResetPassword />} />
                          <Route exact path='/verifyfirst' element={<VerifyFirst />} />



                          <Route exact path='/' element={<Loading setModels={setModels} models={models} />} />
                          <Route exact path='/home' element={<Home models={models} />} />



                          <Route exact path='/admin' element={<AdminPanel />} />
                          {/* <Route exact path='/admin' element={<Admin />} />
                        <Route exact path='/admin/history' element={<History />} />
                        <Route exact path='/admin/settings' element={<Settings />} />
                        <Route exact path='/admin/profile' element={<Profile />} />
                        <Route exact path='/admin/feedback' element={<Feedback />} /> */}


                          <Route exact path='/faqsguide' element={<FaqsGuide />} />
                          {/* <Route exact path='/about' element={<About />}/> */}
                          {/* <Route exact path='/admin/tester' element={<Tester />} /> */}


                        </Routes>


                      </BrowserRouter>
                    </UserState>
                  </ProgressState>
                </FrameState>
              </FaceState>
            </OrderState>
          </FeedbackState>
        </ThemeState>
      </AdminPanelState>
    </>
  );
}

export default App;
