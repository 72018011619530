

import { HairColorStyle } from './HairColorStyle';
import { personalityBase } from './personalityBase';
import { GenderAgeRecommend } from './AgeGenderBase';
import { recommenderOnShape } from './faceShape';

import { findCommonElements, findCommonElementsInFour, findCommonElementsInThree  } from './utils';

export const getFrame = async (faceShape,hairColor, hairStyle, personality, age, gender) => {
    //for frame style
    const frameRecommend = {}
    //for hair, personality and ageGender getting color and frame
    const hairWise = HairColorStyle(hairColor, hairStyle)
    const personalityWise = personalityBase(personality)
    const ageGenderWise = GenderAgeRecommend(gender, age)


    const shapeWise = { frame: recommenderOnShape(faceShape), reason: 'face_shape', pirority: 4 }
    const hairStyleWise = { frame: hairWise.frame, reason: 'hair_style', pirority: 2 }
    const personalityFrameWise = { frame: personalityWise.frame, reason: 'personality', pirority: 3 }
    const agFrameWise = { frame: ageGenderWise.frames, reason: 'age_gender', pirority: 1 }


    const mostcommon = findCommonElementsInFour(shapeWise.frame, hairStyleWise.frame, personalityFrameWise.frame, agFrameWise.frame)
    const priority=shapeWise.pirority + hairStyleWise.pirority + personalityFrameWise.pirority + agFrameWise.pirority
    const reason = []
    reason.push(shapeWise.reason)
    reason.push(hairStyleWise.reason)
    reason.push(agFrameWise.reason)
    reason.push(personalityFrameWise.reason)

    if (mostcommon.length > 0) {
        frameRecommend[`priority${priority}`] = [{
            frame: mostcommon,
            reason,
            priority

        }]
    }

    const totalFrames = [shapeWise, hairStyleWise, personalityFrameWise, agFrameWise]
    for (let i = 0; i < totalFrames.length - 1; i++) {
        for (let j = i + 1; j < totalFrames.length; j++) {

            const mostcommon = findCommonElements(totalFrames[i].frame, totalFrames[j].frame)
            if (mostcommon.length > 0) {
                const priority = totalFrames[i].pirority + totalFrames[j].pirority
                const reason = []
                reason.push(totalFrames[i].reason)
                reason.push(totalFrames[j].reason)

                if (frameRecommend.hasOwnProperty(`priority${priority}`) && frameRecommend[`priority${priority}`].length > 0) {
                    frameRecommend[`priority${priority}`].push({
                        frame: mostcommon,
                        reason,
                        priority
                    })
                }
                else {
                    frameRecommend[`priority${priority}`] = [{
                        frame: mostcommon,
                        reason,
                        priority
                    }]
                }

            }
        }
    }


    for (let i = 0; i < totalFrames.length - 2; i++) {
        for (let j = i + 1; j < totalFrames.length - 1; j++) {
            for (let k = j + 1; k < totalFrames.length; k++) {

                const mostcommon = findCommonElementsInThree(totalFrames[i].frame, totalFrames[j].frame, totalFrames[k].frame)
                if (mostcommon.length > 0) {
                    const priority = totalFrames[i].pirority + totalFrames[j].pirority + totalFrames[k].pirority

                    const reason = []
                    reason.push(totalFrames[i].reason)
                    reason.push(totalFrames[j].reason)
                    reason.push(totalFrames[k].reason)


                    if (frameRecommend.hasOwnProperty(`priority${priority}`) && frameRecommend[`priority${priority}`].length > 0) {
                        frameRecommend[`priority${priority}`].push({
                            frame: mostcommon,
                            reason,
                            priority
                        })
                    }
                    else {
                        frameRecommend[`priority${priority}`] = [{
                            frame: mostcommon,
                            reason,
                            priority
                        }]
                    }


                }

            }
        }

    }


    return frameRecommend
}
