import React, { useContext, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import progressContext from '../Context/progressFlag/progressContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';



export default function Navbar(props) {

  const location = useLocation();
  const navigate = useNavigate();
  const loginItemRef = useRef();
  const [updating, setUpdating] = useState(false)

  const progresscontext = useContext(progressContext)
  const { progressFlag, updateProgressFlag } = progresscontext;


  const logout = () => {
    setUpdating(false)
    const newProgressFlag = {
      ...progressFlag,
      welcome: true,
      questionaire: false,
      detection: false,
      detection2: false,
      processing: false,
      resultDisplay: false,
      isSelectedItem: false
    };
    updateProgressFlag(newProgressFlag);
    localStorage.removeItem('token')


    if (location.pathname === '/home' || location.pathname === '/admin/history' || location.pathname === '/admin' || location.pathname === '/admin/profile' || location.pathname === '/admin/settings') {
      props.preprocess()
    }
    if (location.pathname === '/verifyfirst') {
      navigate('/login')
    }
  }



  return (
    <nav className="navbar navbar-expand-lg bg-transparent text-white bg-6 p-2">
      <div className="container-fluid">
        <Link className={`nav-link text-white fw-bold fs-3 me-3`} to="/home">FramesWise</Link>
       

        
        <button
          className="navbar-toggler navbar-dark"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
           
          <span className="navbar-toggler-icon text-white"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">


            {localStorage.getItem('token') &&
              <li className="nav-item mx-3">
                <Link
                  className={`nav-link text-white ${location.pathname === '/home' ? 'fw-bold' : ''}`}
                  aria-current="page"
                  to="/home"
                >Home</Link>
              </li>
            }
            {localStorage.getItem('token') && <li className="nav-item mx-3">
              <Link className={`nav-link text-white ${location.pathname === '/admin/history' ? 'fw-bold' : ''}`} to="/admin/history">History</Link>
            </li>}
            {localStorage.getItem('token') && <li className="nav-item mx-3">
              <Link className={`nav-link text-white ${
                location.pathname === '/admin' ||
                location.pathname ==='/admin/settings'||
                location.pathname ==='/admin/tester'||
                location.pathname ==='/admin/feedback'||
                location.pathname ==='/admin/profile' 
                ? 'fw-bold' : ''}`} to="/admin">Admin Panel</Link>
            </li>}


            <li className="nav-item mx-3">
              <Link className={`nav-link text-white ${location.pathname === '/faqsguide' ? 'fw-bold' : ''}`} to="/faqsguide">FAQS & Guide</Link>
            </li>

            {localStorage.getItem('token') ?
              <li className="nav-item mx-3">
                <Link onClick={logout} className={`nav-link text-white`} >Logout</Link>
                {updating && <img className='img-fluid' src={require('../assets/gifs/loading_blue_circle.gif')} style={{
                  width: "50px",
                  height: '50px'
                }} alt="" />}
              </li>

              :
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item mx-3">
                  <Link ref={loginItemRef} className={`nav-link text-white ${location.pathname === '/login' ? 'fw-bold' : ''}`} to="/login">Login</Link>
                </li>

                <li className="nav-item mx-3">
                  <Link className={`nav-link text-white ${location.pathname === '/signup' ? 'fw-bold' : ''}`} to="/signup">Signup</Link>
                </li>
              </ul>
            }

          </ul>
        </div>
      </div>
    </nav>
  );
}
