import React, { useContext, useEffect, useRef, useState } from 'react'
import Navbar from '../../Navbar'
import HistroyItem from './HistoryItems'
import { useNavigate } from 'react-router-dom'
import ordersContext from '../../../Context/orders/ordersContext'
import userContext from '../../../Context/users/userContext'
import { ReactComponent as LoadingSpinner } from '../../../assets/svgs/loading_spinner.svg';
import { ReactComponent as LoadingSpinnerSmall } from '../../../assets/svgs/loading_spinner_small_pink.svg';
import HistoryDetailedItem from './HistoryDetailedItem'
import ThemeContext from '../../../Context/ThemeContext/ThemeContext'


const History = (props) => {


    const context = useContext(ordersContext);
    const { orders, getOrder, editOrder, clearOrder, totalPage } = context;

    const user_context = useContext(userContext)
    const { getUser, user, setUser } = user_context

    const { theme, tokens } = useContext(ThemeContext)
    const colors = tokens(theme)

    const { showAlert } = props

    const [loaded, setLoaded] = useState(false)
    const [miniloaded, setminiLoaded] = useState(false)

    const [page, setPage] = useState(1)
    const [progressFlag, setProgressFlag] = useState({
        list: true,
        detail: false
    })
    const [id, setId] = useState(null)

    const updateProgressFlag = (flag) => {
        setProgressFlag(flag)
    }
    const updateId = (id) => {
        setId(id)
    }


    useEffect(() => {
        if (orders === null || orders.length === 0) {
            processOrder()
        }
        else {
            setLoaded(true)
            setminiLoaded(true)
        }
    }, [])
    useEffect(() => {
        if (page > 1) {
            loadNextPage()
        }
    }, [page])


    const loadNextPage = async () => {
        setminiLoaded(false)
        await getOrder(page, user.settings?.limitHistoryRecords || 5)
        setminiLoaded(true)
    }

    const processOrder = async () => {
        setLoaded(false)
        setminiLoaded(false)
        setPage(1)
        await clearOrder();
        await getOrder(1, user.settings?.limitHistoryRecords || 5)
        setLoaded(true)
        setminiLoaded(true)
    }



    return (
        <>

            <section className="w-100" style={{
                background: colors.blueAccent[900],
                color: colors.primary[100],
                maxHeight: window.innerHeight-56,
                overflowY: 'auto',
                overflowX:'hidden',
                minWidth: "100%",
                maxWidth: "100%",
                paddingTop: '20px'
            }}>


                {!loaded &&
                    <div className='d-flex flex-column' style={{
                        paddingTop: '15%'
                    }}>
                        <div className="d-flex justify-content-center">
                            <span className="fs-2 fw-bold ">Loading...</span>
                        </div>
                        <center >

                            <LoadingSpinner style={{ marginTop: '10px', width: "80px", height: "80px" }} />
                        </center>
                    </div>

                }
                {loaded &&
                    <div>
                        {progressFlag.list &&
                            <>
                                <span className='mt-3 px-4 py-2 fw-bold fs-3' style={{ color: colors.redAccent[450] }}>History Records</span>
                                <div className="w-100 d-flex justify-content-end">
                                    <button disabled={!loaded} className='px-2 py-1' style={{
                                        background: colors.primary[400],
                                        color: colors.primary[100],
                                        border: `1px solid ${colors.primary[100]}`,
                                        float: 'right',
                                        width: 'fit-content',
                                        borderRadius: '5px'
                                    }} onClick={processOrder}>Refresh</button>
                                </div>
                                <span className='mt-1 px-2 py-2 fs-5' style={{ color: colors.redAccent[100] }}>Here you can check all your saved orders</span>


                                {orders.length === 0 && <div className="container">

                                    <center>
                                        <span className=" fs-2 fw-bold   ">{orders.length !== 0 ? `Your History` : 'You have no scanning history, scan your face and save it to view here!'} </span>
                                    </center>
                                </div>
                                }
                                <div 
                                className="row"
                                // className="d-flex flex-wrap justify-content-center my-2 w-100"
                                >
                                    {orders.map((order) => {

                                        if (!order.orderDelete) {
                                            return <HistroyItem
                                                showAlert={showAlert}
                                                key={order._id}
                                                order={order}
                                                updateProgressFlag={updateProgressFlag}
                                                updateId={updateId}
                                                colors={colors}
                                            />
                                        }
                                    })}
                                </div>
                                {orders.length !== 0 &&

                                    <div className='d-flex justify-content-center align-items-center'>
                                        {miniloaded &&
                                            <button
                                                style={{
                                                    background: colors.primary[400],
                                                    color: colors.primary[100],
                                                    border: `1px solid ${colors.primary[100]}`,
                                                    float: 'right',
                                                    width: 'fit-content',
                                                    borderRadius: '5px',
                                                    padding: '20px'
                                                }}
                                                onClick={() => { setPage(prev => prev + 1) }}
                                                disabled={page == totalPage}>Load More Total Pages: {totalPage} Currently Loaded Page: {page}</button>}
                                        {
                                            !miniloaded &&
                                            <LoadingSpinnerSmall style={{ marginTop: '10px', width: "30px", height: "30px" }} />
                                        }
                                    </div>
                                }
                            </>}
                        {
                            progressFlag.detail &&
                            <>
                                <span className='mt-3 px-4 py-2 fw-bold fs-3' style={{ color: colors.redAccent[450] }}>Detailed View</span>
                                <br />
                                <span className='mt-1 px-4 py-2 fs-5' style={{ color: colors.redAccent[100] }}>You are viewing an order in detail</span>
                                <br />
                                <div className="w-100 d-flex px-4 my-2">
                                    <button disabled={!loaded} className='px-2 py-1' style={{
                                        background: colors.primary[400],
                                        color: colors.primary[100],
                                        border: `1px solid ${colors.primary[100]}`,
                                        width: 'fit-content',
                                        borderRadius: '5px'
                                    }} onClick={()=>{
                                        updateProgressFlag({...progressFlag,list:true,detail:false})
                                    }}>{`<<Go Back To List View`}</button>
                                </div>
                                <HistoryDetailedItem
                                    colors={colors}
                                    id={id}
                                    updateProgressFlag={updateProgressFlag} />
                            </>
                        }
                    </div>
                }
            </section>
        </>
    )
}

export default History