import { Box } from '@mui/material'
import React from 'react'
import StatBox from '../Charts/StatBox'
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useContext } from 'react';
import ThemeContext from '../../../Context/ThemeContext/ThemeContext';
import userContext from '../../../Context/users/userContext';
import { useEffect } from 'react';
import { useState } from 'react';
import { WcOutlined } from '@mui/icons-material';
import PieChart from '../Charts/Pie';
import Highlights from './Highlights';


const Dashboard = () => {
    const { theme, tokens } = useContext(ThemeContext)
    const { user } = useContext(userContext)

    const todaysDate = new Date().toDateString()
    const colors = tokens(theme);

    const [intToday, setIntToday] = useState(0)
    const [intTotal, setIntTotal] = useState(0)
    const [scanComp, setScanComp] = useState(0)
    const [scanCompT, setScanCompT] = useState(0)
    const [genderPie, setgenderPie] = useState({ male: 0, female: 0 })
    const [genderPieT, setgenderPieT] = useState({ male: 0, female: 0 })
    const [agePie, setagePie] = useState({ young: 0, adult: 0, elder: 0 })
    const [agePieT, setagePieT] = useState({ young: 0, adult: 0, elder: 0 })

    const topStatsStyle = {
        width: '20%',
        minWidth: '150px',
        color: colors.primary[100],
        background: colors.primary[350],
        justifyContent: 'center',
        alignItems: 'center'
    }
    const pieChartStyle = {
        display: "flex",
        width: 'fit-content',
        minWidth: '250px',
        background: colors.primary[350],
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: "260px",
    }

    useEffect(() => {
        if (user?.stats?.scan) {
            setIntTotal(prev => 0)
            setScanCompT(prev => 0)
            setgenderPieT((...prev) => ({
                male: 0,
                female: 0
            }))
            setagePieT((...prev) => ({
                young: 0, adult: 0, elder: 0
            }))
            for (const key in user?.stats.scan) {
                if (user.stats?.scan?.[`${key}`].interaction) {
                    const intT = user.stats?.scan?.[`${key}`].interaction || 0;
                    const scanT = user.stats?.scan?.[`${key}`].compscans || 0;
                    const maleT = user.stats?.scan?.[`${key}`]?.genderscan?.male || 0;
                    const femaleT = user.stats?.scan?.[`${key}`]?.genderscan?.female || 0;
                    const youngT = user.stats?.scan?.[`${key}`]?.agescan?.young || 0;
                    const adultT = user.stats?.scan?.[`${key}`]?.agescan?.adult || 0;
                    const elderT = user.stats?.scan?.[`${key}`]?.agescan?.elder || 0;
                    console.log({ key, adultT, youngT })
                    setIntTotal(prev => prev + intT)
                    setScanCompT(prev => prev + scanT)
                    setgenderPieT((prev) => ({
                        male: prev.male + maleT,
                        female: prev.female + femaleT
                    }))
                    setagePieT((prev) => ({
                        young: prev.young + youngT,
                        adult: prev.adult + adultT,
                        elder: prev.elder + elderT
                    }))
                    console.log({ agePieT })

                }
                if (key === todaysDate) {
                    setgenderPie({
                        male: user.stats?.scan?.[`${todaysDate}`]?.genderscan?.male || 0,
                        female: user.stats?.scan?.[`${todaysDate}`]?.genderscan?.female || 0
                    })
                    setagePie({
                        young: user.stats?.scan?.[`${todaysDate}`]?.agescan?.young || 0,
                        adult: user.stats?.scan?.[`${todaysDate}`]?.agescan?.adult || 0,
                        elder: user.stats?.scan?.[`${todaysDate}`]?.agescan?.elder || 0
                    })
                    setIntToday(user.stats?.scan?.[`${todaysDate}`].interaction)
                    setScanComp(user.stats?.scan?.[`${todaysDate}`].compscans)
                }
            }
        }

    }, [])

    return (
        <>
            <section className="d-flex flex-column " style={{ maxHeight: window.innerHeight - 56, overflow: 'auto', maxWidth: "100%" }}>
                <span className='mt-3 px-4 py-2 fw-bold fs-3' style={{ color: colors.redAccent[450] }}>Dashboard</span>
                <span className='mt-1 px-4 py-2 fs-5' style={{ color: colors.redAccent[100] }}>Welcome to your dashboard</span>

                <div className="d-flex flex-wrap p-2 justify-content-center">
                    <div className='d-flex flex-column px-1 py-2 m-1' style={{
                        // width:'48%',
                        color: colors.primary[100],
                        background: colors.primary[350],
                    }}>
                        <span className='fw-bold fs-6' style={{ textAlign: "center" }}>What's New!</span>
                        <span className=' fs-6'>New Desgined App with Admin Dashboard</span>
                    </div>
                    <div className='d-flex flex-column px-1 py-2 m-1' style={{
                        // width:'48%',
                        color: colors.primary[100],
                        background: colors.primary[350],
                    }}>
                        <span className='fw-bold fs-6' style={{ textAlign: "center" }}>What's Upcomming?</span>
                        <span className=' fs-6'>Chatbox to communicate with developers</span>
                        <span className=' fs-6'>Augmented Reality for people to try on glasses</span>
                        <span className=' fs-6'>Customization for admins to control the recommendation from app</span>
                    </div>
                </div>

                <div className="d-flex flex-wrap px-2 py-2 mt-1 justify-content-center align-center">
                    <div className='d-flex flex-column px-4 py-2 m-1' style={topStatsStyle}>
                        <span className='fw-bold fs-5' style={{ textAlign: "center" }}>Interactions Today</span>
                        <span className=' fs-5'>{intToday || 0}</span>
                    </div>
                    <div className='d-flex flex-column px-2 py-2 m-1' style={topStatsStyle}>
                        <span className='fw-bold fs-5' style={{ textAlign: "center" }}>Total Interactions</span>
                        <span className=' fs-5'>{intTotal || 0}</span>
                    </div>
                    <div className='d-flex flex-column px-2 py-2 m-1' style={topStatsStyle}>
                        <span className='fw-bold fs-5' style={{ textAlign: "center" }}>Scan Completed Today</span>
                        <span className=' fs-5'>{scanComp || 0}</span>
                    </div>
                    <div className='d-flex flex-column px-2 py-2 m-1' style={topStatsStyle}>
                        <span className='fw-bold fs-5' style={{ textAlign: "center" }}>Scan Completed All Time</span>
                        <span className=' fs-5'>{scanCompT || 0}</span>
                    </div>
                </div>

                <div className="d-flex flex-wrap px-2 py-2 mt-1 justify-content-center align-center">
                    <div className='d-flex flex-column px-1 py-2  m-1' style={pieChartStyle}>

                        <span className='fw-bold fs-5'
                            style={{
                                textAlign: "center",
                                color: colors.primary[100]
                            }}>
                            Gender wise scan Today</span>
                        {(genderPie.male !== 0 || genderPie.female !== 0) &&
                            <PieChart stats={genderPie} />}
                        {genderPie.male == 0 && genderPie.female == 0 &&
                            <span className=' fs-5' style={{
                                textAlign: "center",
                                color: colors.primary[100]
                            }}>No Record Found</span>}
                    </div>

                    <div className='d-flex flex-column px-1 py-2 m-1' style={pieChartStyle}>
                        <span className='fw-bold fs-5'
                            style={{
                                textAlign: "center",
                                color: colors.primary[100]
                            }}>
                            Gender wise scan All Time</span>

                        {(genderPieT.male !== 0 || genderPieT.female !== 0) &&
                            <PieChart stats={genderPieT} />}
                        {genderPieT.male == 0 && genderPieT.female == 0 &&
                            <span style={{ textAlign: "center", color: colors.primary[100] }} className=' fs-5'>No Record Found</span>}
                    </div>
                </div>

                <div className="d-flex flex-wrap px-2 py-2 mt-1 justify-content-center align-center">
                    <div className='d-flex flex-column px-1 py-2  m-1' style={pieChartStyle}>

                        <span className='fw-bold fs-5'
                            style={{
                                textAlign: "center",
                                color: colors.primary[100]
                            }}>
                            Age scan Today</span>
                        {(agePie.young !== 0 || agePie.adult !== 0 || agePie.elder !== 0) &&
                            <PieChart stats={agePie} />}
                        {agePie.young == 0 && agePie.adult == 0 && agePie.elder == 0 &&
                            <span className=' fs-5' style={{
                                textAlign: "center",
                                color: colors.primary[100]
                            }}>No Record Found</span>}
                    </div>

                    <div className='d-flex flex-column px-1 py-2 m-1' style={pieChartStyle}>
                        <span className='fw-bold fs-5'
                            style={{
                                textAlign: "center",
                                color: colors.primary[100]
                            }}>
                            Age wise scan All Time</span>

                        {(agePieT.young !== 0 || agePieT.adult !== 0 || agePieT.elder !== 0) &&
                            <PieChart stats={agePieT} />}
                        {agePieT.young == 0 && agePieT.adult == 0 && agePieT.elder == 0 &&
                            <span style={{ textAlign: "center", color: colors.primary[100] }} className=' fs-5'>No Record Found</span>}
                    </div>
                </div>

                <Highlights />

            </section>
        </>
    )
}

export default Dashboard
