import React, { useState } from 'react'
import ProgressContext from './progressContext'

const ProgressState=(props)=>{

    const updateProgressFlag = (newProgressFlag) => {
        setProgressFlag(newProgressFlag);
      };

      const resetProgressFlag = ()=>{
        setProgressFlag({
          welcome: true,
        questionaire:false,
        detection: false,
        detection2:false,
        justification:false,
        processing: false,
        resultDisplay: false,
        isSelectedItem:false,
        rescan:false
        })
      }

    const [progressFlag, setProgressFlag] = useState({
        welcome: true,
        questionaire:false,
        detection: false,
        detection2:false,
        justification:false,
        processing: false,
        resultDisplay: false,
        isSelectedItem:false,
        rescan:false
      })


    return (
      <ProgressContext.Provider value={{ progressFlag, updateProgressFlag,resetProgressFlag }}>
        {props.children}
      </ProgressContext.Provider>
  
    )

}
export default ProgressState