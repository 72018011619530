import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import userContext from "../../Context/users/userContext";
import { ReactComponent as LoadingSpinnerSmall } from "../../assets/svgs/loading_spinner_small_pink.svg";
import { ReactComponent as Logo } from "../../assets/svgs/logoBlue.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

import "../../assets/css/auth/loginsignup.css";

const SignupDetails = () => {
  const navigate = useNavigate();

  const btnLoginRef = useRef(null);
  const left = useRef(null)

  const [user, setuser] = useState({
    name: "",
    signupEmail: "",
    signupPassword: "",
    signupConfirmPassword: "",
    shopName: "",
    shopAddress: "",
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [cpasswordVisibility, setcPasswordVisibility] = useState(false);

  const [updating, setUpdating] = useState(false);
  const [sentMail, setSentMail] = useState(false);
  const [error, setError] = useState({
    show: false,
    msg: "",
  });

  const [leftHeight,setLeftHeight]=useState("100%")

  const context = useContext(userContext);
  const { createUser, verificationCheck, resendVerificationLink } = context;

  const onChange = (e) => {
    setuser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    btnLoginRef.current.classList.add("d-none");
    setUpdating(true);
    try {
      if (user.signupPassword === user.signupConfirmPassword) {
        setError({ ...error, show: false, msg: "" });
        const json = await createUser(
          user.name.trim(),
          user.shopName.trim(),
          user.shopAddress.trim(),
          user.signupEmail,
          user.signupPassword.trim()
        );

        if (json.success) {
          localStorage.setItem("token", json.authToken);
          setSentMail(true);
          const checkInterval = setInterval(async () => {
            const result = await verificationCheck();
            if (result) {
              navigate("/home");
              clearInterval(checkInterval);
            } else if (result.error) {
              clearInterval(checkInterval);
            }
          }, 1000);
        } else {
          setUpdating(false);
          btnLoginRef.current.classList.remove("d-none");
          setError({ ...error, show: true, msg: json.error });
        }
      } else {
        setError({ ...error, show: true, msg: "Your Passwords Donot Match!" });
        btnLoginRef.current.classList.remove("d-none");
        setUpdating(false);
      }
    } catch (error) {
      setError({ ...error, show: true, msg: "Unknown error Occured" });
      btnLoginRef.current.classList.remove("d-none");
      setUpdating(false);
    }
  };

  useEffect(()=>{
    setLeftHeight(left.current.offsetHeight)
  },[])

  return (
    <>
      <section className="sectionLS">
        <div ref={left} className="left pb-3">
          <div className="topLeftTriangle"></div>
          <Logo className="logoLS" />
          <form
            onSubmit={handleSubmit}
            id="form"
            className="form-container-login"
          >
            <span className="mainHeading ">Welcome to Frameswise</span>
            <span className="subHeading mb-4">Please create your account.</span>
            <div
              className="mb-5"
              style={{
                flexDirection: "row",
                width: "fit-content",
              }}
            >
              <Link className="notactive" to={"/login"}>
                LOGIN
              </Link>
              <Link className="active" to="/signup">
                SIGNUP
              </Link>
            </div>
            <input
              className="email-input mb-3"
              placeholder="Enter your Name"
              type="text"
              id="name"
              name="name"
              onChange={onChange}
              value={user.name}
              required
            />
            <input
              className="email-input mb-3"
              placeholder="Enter your Email"
              type="email"
              id="signupEmail"
              name="signupEmail"
              onChange={onChange}
              value={user.signupEmail}
              required
            />

            <input
              className="email-input mb-3"
              placeholder="Enter your Shop Name"
              type="text"
              id="shopName"
              name="shopName"
              onChange={onChange}
              value={user.shopName}
              required
            />
            <input
              className="email-input mb-3"
              placeholder="Enter your Shop Address"
              type="text"
              id="shopAddress"
              name="shopAddress"
              onChange={onChange}
              value={user.shopAddress}
              required
            />
            <div className="password-input mb-3">
              <input
                className="ms-2"
                placeholder="Enter your Password"
                type={`${passwordVisibility ? "text" : "password"}`}
                id="signupPassword"
                name="signupPassword"
                onChange={onChange}
                value={user.signupPassword}
                required
                style={{
                  width: " 85%",
                }}
              />
              <FontAwesomeIcon
                className="mx-2"
                icon={passwordVisibility ? faEye : faEyeSlash}
                style={{
                  color: "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPasswordVisibility(!passwordVisibility);
                }}
              />
            </div>

            <div className="password-input mb-3">
              <input
                className="ms-2"
                placeholder="Confirm Password"
                type={`${cpasswordVisibility ? "text" : "password"}`}
                id="signupConfirmPassword"
                name="signupConfirmPassword"
                onChange={onChange}
                value={user.signupConfirmPassword}
                required
                style={{
                  width: " 85%",
                }}
              />
              <FontAwesomeIcon
                className="mx-2"
                icon={cpasswordVisibility ? faEye : faEyeSlash}
                style={{
                  color: "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setcPasswordVisibility(!cpasswordVisibility);
                }}
              />
            </div>

            <div>
              {error.show && (
                <span className="text-lightRed fw-bold fs-5">{error.msg}</span>
              )}
            </div>

            <button type="submit" className="login-btn" ref={btnLoginRef}>
              Sign Up
            </button>
            {sentMail && (
              <span className="fs-6 text-primary fw-bold ">
                Verification email Has Been Sent to Your entered email address.
              </span>
            )}
            <div>
              {updating && (
                <LoadingSpinnerSmall
                  style={{ marginTop: "10px", width: "30px", height: "30px" }}
                />
              )}
              {sentMail && (
                <span className="fs-6 text-primary fw-bold">
                  Waiting for you to verify the link!
                </span>
              )}
            </div>
          </form>
        </div>
        <div  className="rightLS"
         style={{height:leftHeight}}
         ></div>
      </section>
    </>
  );
};

export default SignupDetails;
