import React from 'react'
import { useContext } from 'react'
import userContext from '../../../Context/users/userContext'
import { useState } from 'react'
import { truncatedNormal } from '@tensorflow/tfjs'
import ThemeContext from '../../../Context/ThemeContext/ThemeContext'
import { useEffect } from 'react'

const Highlights = () => {
    const { user } = useContext(userContext)
    const { theme, tokens } = useContext(ThemeContext)

    const [view, setView] = useState(0)

    const [mrFrame, setmrFrame] = useState([])
    const [mrColor, setmrColor] = useState([])
    const [mcFrame, setmcFrame] = useState([])
    const [mcColor, setmcColor] = useState([])

    const colors = tokens(theme);



    function getKeysWithHighestValue(obj) {
        // Find the maximum value in the object
        const values = Object.values(obj);
        const max = Math.max(...values);

        // Identify keys with the maximum value
        const keysWithMaxValue = Object.keys(obj).filter(key => obj[key] === max);

        return keysWithMaxValue;
    }



    useEffect(() => {
        const recommenededframes = user?.stats?.recommenededframes
        const recommenededcolors = user?.stats?.recommenededcolors
        const choosenglasses = user?.stats?.choosenglasses
        const choosencolors = user?.stats?.choosencolors
        if (recommenededframes) {
            setmrFrame(getKeysWithHighestValue(recommenededframes));
        }
        if (recommenededcolors) {
            setmrColor(getKeysWithHighestValue(recommenededcolors));
        }
        if (choosenglasses) {
            setmcFrame(getKeysWithHighestValue(choosenglasses));
        }
        if (choosencolors) {
            setmcColor(getKeysWithHighestValue(choosencolors));
        }


    }, [])
    return (
        <>
            {mrFrame.length === 0 &&
                <section className="d-flex flex-wrap px-4 py-2 mt-1 flex-column justify-content-center py-5 align-items-center"
                    style={{
                        color: colors.primary[100],
                        background: colors.blueAccent[900]
                    }}>

                    <span className="fs-4 fw-bold">No Records Found</span>
                    <span className="fs-6 fw-bold">Please Complete a scan to view stats here</span>
                </section>
            }
            {mrFrame.length > 0 &&
                <section className="d-flex flex-wrap px-4 py-2 mt-1 flex-column "
                    style={{
                        color: colors.primary[100],
                        background: colors.blueAccent[900]
                    }}>
                    {view === 0 &&
                        <>
                            <span className='fs-3 fw-bold'>Most Recommended Glasses</span>
                            <span className='fs-5'>These are the glasses that are recommended to your customers</span>
                            <div className="d-flex flex-wrap justify-content-center my-3">
                                {mrFrame.map(e => {
                                    return (
                                        <img style={{ borderRadius: '20px' }} className='img-fluid px-2  my-2' width={"200px"} src={require(`../../../assets/frameImgs/${e}_frame.jpg`)} alt="" />)
                                })}
                            </div>

                        </>
                    }
                    {view === 1 &&
                        <>
                            <span className='fs-3 fw-bold'>Most Choosen Glasses</span>
                            <span className='fs-5'>These are the glasses that are choosen by your customers</span>
                            <div className="d-flex flex-wrap justify-content-center my-3">
                                {mcFrame.map(e => {
                                    return (
                                        <img style={{ borderRadius: '20px' }} className='img-fluid px-2  my-2' width={"200px"} src={require(`../../../assets/frameImgs/${e}_frame.jpg`)} alt="" />)
                                })}
                            </div>

                        </>
                    }
                    {view === 2 &&
                        <>
                            <span className='fs-3 fw-bold'>Most Recommended Colors</span>
                            <span className='fs-5'>These are the colors that are recommended to your customers</span>
                            <div className="d-flex flex-wrap justify-content-center my-3">
                                {mrColor.map(e => {
                                    return (
                                        <img style={{ borderRadius: '360px', background: 'transparent' }} className=' px-2  my-2' width={"100px"} height={"100px"} src={require(`../../../assets/textures/${e}.png`)} alt="" />)
                                })}
                            </div>

                        </>
                    }
                    {view === 3 &&
                        <>
                            <span className='fs-3 fw-bold'>Most Choosen Colors</span>
                            <span className='fs-5'>These are the glasses that are choosen by your customers</span>
                            <div className="d-flex flex-wrap justify-content-center my-3">
                                {mcColor.map(e => {
                                    return (
                                        <img style={{ borderRadius: '360px', background: 'transparent' }} className=' px-2  my-2' width={"100px"} height={"100px"} src={require(`../../../assets/textures/${e}.png`)} alt="" />)
                                })}
                            </div>

                        </>
                    }
                    <div className="d-flex justify-content-end">
                        <button className='btn mx-3 p-2'
                            disabled={view < 1}
                            onClick={() => {
                                setView(prev => prev - 1)
                            }}
                            style={{
                                background: colors.blueAccent[300],
                                color: colors.blueAccent[900],
                                borderRadius: '5px',
                                fontWeight: 'bolder'
                            }}>{`<`}</button>
                        <button className='btn mx-3 p-2'
                            disabled={view > 2}
                            onClick={() => {
                                setView(prev => prev + 1)
                            }}
                            style={{
                                background: colors.blueAccent[300],
                                color: colors.blueAccent[900],
                                borderRadius: '5px',
                                fontWeight: 'bolder'
                            }}>{`>`}</button>
                    </div>
                </section>
            }
        </>
    )
}

export default Highlights
