import React, { useState } from "react";
import "../../../assets/css/mainStory/question.css";
import { ReactComponent as Triangle } from "../../../assets/svgs/triangle.svg";
import { ReactComponent as Logo } from "../../../assets/svgs/logo.svg";
import { useRef } from "react";
import { useEffect } from "react";
import { drawPerpendicularLines } from "../../../Utilities/drawingCanvas";
import Pereferences from "./Pereferences";
import { useContext } from "react";
import progressContext from "../../../Context/progressFlag/progressContext";
import PersonalityQuestion from "./PersonalityQuestion";
import HairQuestion from "./HairQuestion";
import faceContext from "../../../Context/faceDetection/faceContext";
import userContext from "../../../Context/users/userContext";
const Questionaire = () => {
  const canvasRef = useRef(null);
  const canvasParentRef = useRef(null);

  const { colorsPersonalityAndHairs } = useContext(faceContext);
  const { user } = useContext(userContext);

  const [questionPersonalityShow, setQuestionPersonalityShow] = useState(false);
  const [questionHairShow, setQuestionHairShow] = useState(false);

  const [glasses, setGlasses] = useState("glasses");
  const [material, setMaterial] = useState("metallic");

  const [personality, setPersonality] = useState(null);
  const [hairColor, setHairColor] = useState(null);
  const [hairStyle, setHairStyle] = useState(null);

  const { progressFlag, updateProgressFlag, resetProgressFlag } =
    useContext(progressContext);

  const updateGlasses = (value) => {
    setGlasses(value);
  };
  const updateMaterial = (value) => {
    setMaterial(value);
  };
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.canvas.height = canvasParentRef.current.offsetHeight;
    context.canvas.width = canvasParentRef.current.offsetWidth;
    drawPerpendicularLines(canvasRef);
  });

  const goBack = () => {
    resetProgressFlag();
  };

  return (
    <>
      <section className="sectionQ">
        <div className="leftQ">
          <div className="position-relative">
            <Triangle className="traingleQ" />
          </div>

          <nav className="navleftQ pt-4 ps-4">
            <span onClick={goBack} className="gobackQ">
              &larr;
            </span>
            <Logo onClick={goBack} className="mx-2 logoQ" />
            {/* <button className="btninstructionQ mx-2">How it Works</button> */}
          </nav>
          <div className="parentellipseQ ">
            <div className="ellipseQ">
              <div
                ref={canvasParentRef}
                style={{
                  // position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              >
                <canvas ref={canvasRef} />
              </div>
            </div>
          </div>
        </div>
        <div className="rightQ p-5">
          <span className="mainHeadingQ mb-2">
            Personalised Eyewear Recommendation
          </span>
          <span className="descQ mb-5">
            Looking for a nice pair of glasses that will suit you well? Let us
            help you!
          </span>
          <div className="mb-5">
            <Pereferences
              selectedValue={glasses}
              onSelect={updateGlasses}
              allValues={["sunglasses", "glasses"]}
            />
          </div>
          <div style={{ marginBottom: "20%" }}>
            <Pereferences
              selectedValue={material}
              onSelect={updateMaterial}
              allValues={["metallic", "plastic", "rimless", "semi-rimless"]}
            />
          </div>
          <button
            onClick={() => setQuestionPersonalityShow(true)}
            className="btnQ mt-3"
          >
            Get Ready
          </button>
        </div>
      </section>

      <PersonalityQuestion
        backdrop="static"
        show={questionPersonalityShow}
        personality={personality}
        onNext={(p) => {
          setQuestionPersonalityShow(false);
          setPersonality(p);
          setQuestionHairShow(true);
        }}
        onGoBack={() => setQuestionPersonalityShow(false)}
      />

      <HairQuestion
        backdrop="static"
        show={questionHairShow}
        hairStyle={hairStyle}
        hairColor={hairColor}
        onNext={(color, style) => {
          setHairColor(color);
          setHairStyle(style);
          setQuestionHairShow(false);

          colorsPersonalityAndHairs(personality, color, style);

          const question = user.settings?.questionaireEnd;
          const newProgressFlag = {
            ...progressFlag,
            questionaire: false,
            detection: !question,
            processing: question,
          };
          updateProgressFlag(newProgressFlag);
        }}
        onGoBack={() => {
          setQuestionHairShow(false);
          setQuestionPersonalityShow(true);
        }}
      />
    </>
  );
};

export default Questionaire;
