import React, { useRef, useState, useContext, useEffect } from "react";
import "../../../assets/css/mainStory/justification.css";
import { ReactComponent as Logo } from "../../../assets/svgs/logoBlue.svg";
import progressContext from "../../../Context/progressFlag/progressContext";
import userContext from "../../../Context/users/userContext";
import faceContext from "../../../Context/faceDetection/faceContext";
import Nav_circles_svg from "../../../assets/svgs/Nav_circles_svg";
import Right_arrow_svg from "../../../assets/svgs/Right_arrow_svg";
import Left_arrow_svg from "../../../assets/svgs/Left_arrow_svg";
import { rgbToHex } from "../../../Utilities/commonFunction";

const Justification = () => {
  const { progressFlag, updateProgressFlag, resetProgressFlag } =
    useContext(progressContext);

  const { faces, clearDetections } = useContext(faceContext);
  const { user } = useContext(userContext);

  const canvasRef = useRef(null);
  const canvasRefFaceShape = useRef(null);
  const canvasRefColor = useRef(null);
  const canvasRefAgeGender = useRef(null);

  const ageGender = user.settings?.showGender || user.settings?.showAge;
  console.log({ageGender})
  const question =
    user.settings?.askQuestionaire && user.settings?.questionaireEnd;

  const [tabs, setTabs] = useState(0);
  const [pixelColor, setPixelColor] = useState([]);

  const updateTabs = (tab) => {
    setTabs(tab);
    if (tab === 2 && !ageGender) {
      skip();
    }
    if (tab === 3) {
      skip();
    }
  };

  useEffect(() => {
    if (tabs === 0 && canvasRef && canvasRefFaceShape) {
      drawLandmarksFaceShape();
    }
    if (tabs === 1 && canvasRef && canvasRefColor) {
      drawLandmarksColor();
    }
    if (tabs === 2 && canvasRef && canvasRefAgeGender) {
      drawLandmarksAgeGender();
    }
  }, [tabs]);

  const drawLandmarksFaceShape = () => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Ensure cross-origin access
    img.src = faces.resizedImg.toDataURL();
    // img.src = faces.capImageElement.src;

    img.addEventListener(
      "load",
      function () {
        canvasRef.current.width = img.width;
        canvasRef.current.height = img.height;
        canvasRefFaceShape.current.width = img.width;
        canvasRefFaceShape.current.height = img.height;
        const totalWidth = img.width;
        const totalHeight = img.height;
        const ctxTarget = canvasRef.current.getContext("2d");
        const ctx = canvasRefFaceShape.current.getContext("2d");

        ctxTarget.drawImage(img, 0, 0);
        const at285 = faces.landmarksPoint[285];
        const at295 = faces.landmarksPoint[295];
        const at282 = faces.landmarksPoint[282];
        const at283 = faces.landmarksPoint[283];
        const at276 = faces.landmarksPoint[276];
        const at152 = faces.landmarksPoint[152];
        const at400 = faces.landmarksPoint[400];
        const at288 = faces.landmarksPoint[288];
        const at379 = faces.landmarksPoint[379];
        const at454 = faces.landmarksPoint[454];
        const at234 = faces.landmarksPoint[234];
        const at10 = faces.landmarksPoint[10];

        ctx.fillStyle = "transparent";
        ctx.strokeStyle = "white";
        ctx.lineWidth = 2;

        ctx.fillStyle = "blue";

        ctx.beginPath();
        ctx.arc(at152.x * totalWidth, at152.y * totalHeight, 5, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fill();

        // ctx.clip();

        ctx.beginPath();
        ctx.arc(at400.x * totalWidth, at400.y * totalHeight, 5, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fill();

        ctx.beginPath();
        ctx.arc(at379.x * totalWidth, at379.y * totalHeight, 5, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fill();

        ctx.beginPath();
        ctx.arc(at288.x * totalWidth, at288.y * totalHeight, 5, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fill();

        ctx.beginPath();
        ctx.arc(at454.x * totalWidth, at454.y * totalHeight, 5, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fill();

        ctx.beginPath();
        ctx.arc(at234.x * totalWidth, at234.y * totalHeight, 5, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fill();

        ctx.beginPath();
        ctx.arc(at10.x * totalWidth, at10.y * totalHeight, 5, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fill();

        ctx.fillStyle = "blue";

        ctx.globalCompositeOperation = "destination-over";
        ctx.beginPath();
        ctx.moveTo(at152.x * totalWidth, at152.y * totalHeight);
        ctx.lineTo(at400.x * totalWidth, at400.y * totalHeight);
        ctx.lineTo(at379.x * totalWidth, at379.y * totalHeight);
        ctx.lineTo(at288.x * totalWidth, at288.y * totalHeight);
        // ctx.arc(at400.x * totalWidth, at400.y * totalWidth, 5, 0, Math.PI * 2);
        // ctx.fill(); // Fill the circle with the fill color
        ctx.stroke(); // Draw the outline with the stroke color and specified line width

        ctx.beginPath();
        ctx.moveTo(at285.x * totalWidth, at285.y * totalHeight);
        ctx.lineTo(at295.x * totalWidth, at295.y * totalHeight);
        ctx.lineTo(at282.x * totalWidth, at282.y * totalHeight);
        ctx.lineTo(at283.x * totalWidth, at283.y * totalHeight);
        ctx.lineTo(at276.x * totalWidth, at276.y * totalHeight);
        // ctx.arc(at400.x * totalWidth, at400.y * totalWidth, 5, 0, Math.PI * 2);
        // ctx.fill(); // Fill the circle with the fill color
        ctx.stroke(); // Draw the outline with the stroke color and specified line width
        // ctx.closePath();
      },
      false
    );
  };

  const drawLandmarksAgeGender = () => {
    const img = canvasRef.current;
    canvasRefAgeGender.current.width = img.width;
    canvasRefAgeGender.current.height = img.height;
    const totalWidth = img.width;
    const totalHeight = img.height;
    const ctx = canvasRefAgeGender.current.getContext("2d");
    const at = faces.landmarksPoint;

    ctx.fillStyle = "transparent";
    ctx.strokeStyle = "white";
    ctx.lineWidth = 2;

    ctx.globalCompositeOperation = "destination-over";
    ctx.beginPath();
    ctx.moveTo(at[93].x * totalWidth, at[93].y * totalHeight);
    ctx.lineTo(at[137].x * totalWidth, at[137].y * totalHeight);
    ctx.lineTo(at[123].x * totalWidth, at[123].y * totalHeight);
    ctx.lineTo(at[50].x * totalWidth, at[50].y * totalHeight);
    ctx.lineTo(at[36].x * totalWidth, at[36].y * totalHeight);
    ctx.lineTo(at[49].x * totalWidth, at[49].y * totalHeight);
    ctx.lineTo(at[131].x * totalWidth, at[131].y * totalHeight);
    ctx.lineTo(at[134].x * totalWidth, at[134].y * totalHeight);
    ctx.lineTo(at[51].x * totalWidth, at[51].y * totalHeight);
    ctx.lineTo(at[5].x * totalWidth, at[5].y * totalHeight);
    ctx.lineTo(at[281].x * totalWidth, at[281].y * totalHeight);
    ctx.lineTo(at[363].x * totalWidth, at[363].y * totalHeight);
    ctx.lineTo(at[360].x * totalWidth, at[360].y * totalHeight);
    ctx.lineTo(at[279].x * totalWidth, at[279].y * totalHeight);
    ctx.lineTo(at[266].x * totalWidth, at[266].y * totalHeight);
    ctx.lineTo(at[280].x * totalWidth, at[280].y * totalHeight);
    ctx.lineTo(at[352].x * totalWidth, at[352].y * totalHeight);
    ctx.lineTo(at[366].x * totalWidth, at[366].y * totalHeight);
    ctx.lineTo(at[323].x * totalWidth, at[323].y * totalHeight);

    ctx.stroke();
  };

  const drawLandmarksColor = () => {
    const img = canvasRef.current;

    canvasRefColor.current.width = img.width;
    canvasRefColor.current.height = img.height;
    const totalWidth = img.width;
    const totalHeight = img.height;
    const ctxImg = canvasRef.current.getContext("2d");
    const ctx = canvasRefColor.current.getContext("2d");

    const at10 = faces.landmarksPoint[9];
    const at50 = faces.landmarksPoint[50];
    const at4 = faces.landmarksPoint[4];
    const at16 = faces.landmarksPoint[16];

    ctx.fillStyle = "transparent";
    ctx.strokeStyle = "lightGreen";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.arc(at10.x * totalWidth, at10.y * totalHeight, 5, 0, Math.PI * 2);
    ctx.stroke();
    ctx.fill();

    ctx.beginPath();
    ctx.arc(at50.x * totalWidth, at50.y * totalHeight, 5, 0, Math.PI * 2);
    ctx.stroke();
    ctx.fill();

    ctx.beginPath();
    ctx.arc(at4.x * totalWidth, at4.y * totalHeight, 5, 0, Math.PI * 2);
    ctx.stroke();
    ctx.fill();

    ctx.beginPath();
    ctx.arc(at16.x * totalWidth, at16.y * totalHeight, 5, 0, Math.PI * 2);
    ctx.stroke();
    ctx.fill();

    const pixels = [9, 50, 4, 16];
    var hexColors = [];
    pixels.forEach((e) => {
      let X = totalWidth * faces.landmarksPoint[e].x;
      let Y = totalHeight * faces.landmarksPoint[e].y;
      const pixelData = ctxImg.getImageData(X, Y, 1, 1).data;
      const color = {
        red: pixelData[0],
        green: pixelData[1],
        blue: pixelData[2],
      };
      const color_hex = rgbToHex(color.red, color.blue, color.green);
      hexColors.push(color_hex);
    });

    setPixelColor(hexColors);

    ctx.beginPath();
    ctx.lineWidth = "2";
    ctx.strokeStyle = "white";
    ctx.fillStyle=hexColors[0]
    ctx.fillRect(10, totalHeight/2 -70, 30, 30);
    ctx.stroke();

    ctx.beginPath();
    ctx.lineWidth = "2";
    ctx.strokeStyle = "white";
    ctx.fillStyle=hexColors[1]
    ctx.fillRect(10, totalHeight/2 -30, 30, 30);
    ctx.stroke();

    ctx.beginPath();
    ctx.lineWidth = "2";
    ctx.strokeStyle = "white";
    ctx.fillStyle=hexColors[2]
    ctx.fillRect(10, totalHeight/2 +10, 30, 30);
    ctx.stroke();

    ctx.beginPath();
    ctx.lineWidth = "4";
    ctx.strokeStyle = "white";
    ctx.fillStyle=hexColors[3]
    ctx.fillRect(10, totalHeight/2 +50, 30, 30);
    ctx.stroke();
  };

  const goBack = () => {
    resetProgressFlag();
  };
  const skip = () => {
    const newFlag = {
      ...progressFlag,
      justification: false,
      questionaire: question && !progressFlag.rescan,
      processing: !question || progressFlag.rescan,
    };
    updateProgressFlag(newFlag);
  };

  const rescanHandler = () => {
    clearDetections();

    const newProgressFlag = {
      ...progressFlag,
      justification: false,
      detection: true,
      rescan: true,
    };
    updateProgressFlag(newProgressFlag);
  };

  return (
    <>
      <section className="sectionJ">
        <div className="leftJ">
          <Logo onClick={goBack} className="m-2 logoJ" />
          <div className="leftmaindivJ">
            <div className="my-5 position-relative">
              {tabs === 0 && (
                <canvas
                  style={{
                    position: "absolute",
                    zIndex: 1,
                  }}
                  ref={canvasRefFaceShape}
                  className="img-fluid"
                  alt="..."
                />
              )}
              {tabs === 1 && (
                <canvas
                  style={{
                    position: "absolute",
                    zIndex: 1,
                  }}
                  ref={canvasRefColor}
                  className="img-fluid"
                  alt="..."
                />
              )}
              {tabs === 2 && (
                <canvas
                  style={{
                    position: "absolute",
                    zIndex: 1,
                  }}
                  ref={canvasRefAgeGender}
                  className="img-fluid"
                  alt="..."
                />
              )}
              <canvas ref={canvasRef} className="img-fluid" alt="..." />
            </div>
          </div>
        </div>
        <div className="rightJ">
          <div className="topoptionJ">
            <span onClick={skip}>Skip Analysis</span>
          </div>
          <div className="rightmaindivJ">
            <div className="rightcontentdivJ">
              <span className="rightcontentheadingJ">
                {tabs === 0 && "Face Shapes"}
                {tabs === 1 && "Colour"}
                {tabs === 2 && (
                  <>
                    {user.settings?.showGender ? "Gender &nbsp;" : ""}
                    {user.settings?.showGender && user.settings?.showAge
                      ? "& &nbsp;"
                      : ""}
                    
                    {user.settings?.showAge ? "Age" : ""}
                  </>
                )}
              </span>
              <span className="rightcontentdescJ">
                {tabs === 0 &&
                  "As you can see, we look at the unique dimensions and shapes in your face. This allows us to give an accurate eyewear recommendation that line up with your jawline and eyebrows."}
                {tabs === 1 &&
                  "We consider the colours and undertone of your hair, eyes, lips, and skin. This allows us to recommend colours that will look good on you."}
                {tabs === 2 && (
                  <>
                    {user.settings?.showGender &&
                      `Your gender is detected as ${faces.avgGender}.`}
                    {user.settings?.showAge &&
                      `Your age is detected as ${Math.round(
                        faces.avgAge
                      )} years old. Which we assume you are 
                              ${
                                Math.round(faces.avgAge) > 60
                                  ? " elder"
                                  : Math.round(faces.avgAge) > 40
                                  ? " senior"
                                  : Math.round(faces.avgAge) > 15
                                  ? " adult"
                                  : " young"
                              }.`}
                  </>
                )}
              </span>
            </div>
            <div className="navcircleJ mt-2">
              <Nav_circles_svg
                color1={
                  tabs === 0
                    ? "var(--primary-color)"
                    : "var(--quaternary-color)"
                }
                color2={
                  tabs === 1
                    ? "var(--primary-color)"
                    : "var(--quaternary-color)"
                }
                color3={
                  tabs === 2
                    ? "var(--primary-color)"
                    : "var(--quaternary-color)"
                }
                width={"60px"}
              />
            </div>
            <div className="tabbtndivJ">
              {tabs !== 0 && (
                <button
                  className="btnbackJ"
                  onClick={() => {
                    updateTabs(tabs - 1);
                  }}
                >
                  <Left_arrow_svg width={'20px'} color={"var(--tertiary-color)"} />
                </button>
              )}
              <button
                className="btnnextJ"
                onClick={() => {
                  updateTabs(tabs + 1);
                }}
              >
                <Right_arrow_svg width={'20px'} color={"var(--quaternary-color)"} />
              </button>
            </div>
          </div>

          <div className="parentbtnrescanJ">
            <button onClick={rescanHandler} className="btnrescanR mt-5">
              Scan Again
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Justification;
