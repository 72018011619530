
import {
    distanceVector,
    rgbToHex,
    findMajorityElement,

} from './commonFunction'
import { faceTone } from './faceColors';





export const shapeProcessor =  async (faceLandmarker, imgRef,updateFlag,shapeValue,colorValue,measurementValue) => {
  
  console.clear()
    // console.log('imgRef.offsetWidth')
    // console.log(imgRef.offsetWidth)
    // console.log('imgRef.offsetHeight')
    // console.log(imgRef.offsetHeight)
    const width = imgRef.offsetWidth
    const height = imgRef.offsetHeight

    const faceLandmarkerResult = faceLandmarker.detect(imgRef);

    if(! faceLandmarkerResult.faceLandmarks[0]) return false;


    // Create an off-screen canvas
    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = width;
    offscreenCanvas.height = height;
    const offscreenContext = offscreenCanvas.getContext('2d');

    // Draw the input image on the off-screen canvas
    offscreenContext.drawImage(imgRef, 0, 0, width, height);

    const pixelData = offscreenContext.getImageData(width * faceLandmarkerResult.faceLandmarks[0][10].x, height * faceLandmarkerResult.faceLandmarks[0][10].y, 1, 1).data;


    const color = {
        red: pixelData[0],
        green: pixelData[1],
        blue: pixelData[2],
    };

    let forhead = false;

    let forhead_X = width * faceLandmarkerResult.faceLandmarks[0][10].x
    let forhead_Y = height * faceLandmarkerResult.faceLandmarks[0][10].y

    var attempts = 0;
    while (!forhead && attempts === 25) {
        forhead_Y--
        const pixelData = offscreenContext.getImageData(forhead_X, forhead_Y, 1, 1).data;
        const loop_color = {
            red: pixelData[0],
            green: pixelData[1],
            blue: pixelData[2],
        };
        let red_difference = Math.abs(loop_color.red - color.red)
        let blue_difference = Math.abs(loop_color.blue - color.blue)
        let green_difference = Math.abs(loop_color.green - color.green)


        const limit = 18
        if (red_difference > limit || blue_difference > limit || green_difference > limit) {

            forhead = true;
        }
        attempts++
    }

    const newTop = {
        x: forhead_X / width,
        y: forhead_Y / height
    }
    faceLandmarkerResult.faceLandmarks[0][10] = { ...newTop }


    //for text  length

    //for side 1
    const x_diagonal_fg_right = Math.abs(faceLandmarkerResult.faceLandmarks[0][136].x - faceLandmarkerResult.faceLandmarks[0][251].x)

    const y_diagonal_fg_right = Math.abs(faceLandmarkerResult.faceLandmarks[0][136].y - faceLandmarkerResult.faceLandmarks[0][251].y)

    //for square
    // const angle_diagonal_fg_right = Math.atan(y_diagonal_fg_right / x_diagonal_fg_right)
    //for rectangle
    const angle_diagonal_fg_right = Math.atan((y_diagonal_fg_right*height) /( x_diagonal_fg_right*width))


    const degree_angle_diagonal_lightyellow_cyan_right = angle_diagonal_fg_right * 180 / Math.PI

    // console.log({width,height,y_diagonal_fg_right,x_diagonal_fg_right,angle_diagonal_fg_right,degree_angle_diagonal_lightyellow_cyan_right})


    //for side 1 left

    const x_diagonal_fg_left = Math.abs(faceLandmarkerResult.faceLandmarks[0][21].x - faceLandmarkerResult.faceLandmarks[0][365].x)

    const y_diagonal_fg_left = Math.abs(faceLandmarkerResult.faceLandmarks[0][21].y - faceLandmarkerResult.faceLandmarks[0][365].y)

    //for square
    // const angle_diagonal_fg_left = Math.atan(y_diagonal_fg_left / x_diagonal_fg_left)

    //for rectangle
    const angle_diagonal_fg_left = Math.atan((y_diagonal_fg_left*height) /( x_diagonal_fg_left*width))


    const degree_angle_diagonal_lightyellow_cyan_left = angle_diagonal_fg_left * 180 / Math.PI

    const degree_diagonal_side_lightyellow_cyan = (degree_angle_diagonal_lightyellow_cyan_right+degree_angle_diagonal_lightyellow_cyan_left)/2


  



    //for answer length
  //   const answer_length = 
  //  degree_diagonal_side_lightyellow_cyan < 93 &&
  //  degree_diagonal_side_lightyellow_cyan > 87 ? 
  //     'medium' :
  //     degree_diagonal_side_lightyellow_cyan > 93?
  //     'long':'small'
     
  console.log({degree_diagonal_side_lightyellow_cyan,degree_angle_diagonal_lightyellow_cyan_left,degree_angle_diagonal_lightyellow_cyan_right})
    const answer_length = 
   degree_diagonal_side_lightyellow_cyan < 47 &&
   degree_diagonal_side_lightyellow_cyan > 43 ? 
      'medium' :
      degree_diagonal_side_lightyellow_cyan > 47?
      'long':'small'
     
   console.log({answer_length})
     //for text chin
 
     //for chin side 1 green and fuschia
     const x_chin_fg_right = Math.abs(faceLandmarkerResult.faceLandmarks[0][152].x - faceLandmarkerResult.faceLandmarks[0][377].x)
     const y_chin_fg_right = Math.abs(faceLandmarkerResult.faceLandmarks[0][152].y - faceLandmarkerResult.faceLandmarks[0][377].y)
     const angle_chin_fg_right = Math.atan((y_chin_fg_right*height) /( x_chin_fg_right*width))
    //  const angle_chin_fg_right = Math.atan(y_chin_fg_right / x_chin_fg_right)
     const degree_angle_chin_fuchsia_green_right = angle_chin_fg_right * 180 / Math.PI
 
 
     //for chin side 1
     const x_chin_fg_left = Math.abs(faceLandmarkerResult.faceLandmarks[0][152].x - faceLandmarkerResult.faceLandmarks[0][148].x)
     const y_chin_fg_left = Math.abs(faceLandmarkerResult.faceLandmarks[0][152].y - faceLandmarkerResult.faceLandmarks[0][148].y)
     const angle_chin_fg_left = Math.atan((y_chin_fg_left*height) / (x_chin_fg_left*width))
    //  const angle_chin_fg_left = Math.atan(y_chin_fg_left / x_chin_fg_left)
     const degree_angle_chin_fuchsia_green_left = angle_chin_fg_left * 180 / Math.PI
 
     
 
 
     //for chin side
    //  const x_chin_side = Math.abs(faceLandmarkerResult.faceLandmarks[0][377].x - faceLandmarkerResult.faceLandmarks[0][400].x)
    //  const y_chin_side = Math.abs(faceLandmarkerResult.faceLandmarks[0][377].y - faceLandmarkerResult.faceLandmarks[0][400].y)
    //  const angle_chin_side = Math.atan(y_chin_side / x_chin_side)
    //  const degree_angle_chin_side = angle_chin_side * 180 / Math.PI
 
     //for chin side2 right fuchsia and yellow
     const x_chin_side2_right = Math.abs(faceLandmarkerResult.faceLandmarks[0][377].x - faceLandmarkerResult.faceLandmarks[0][400].x)
     const y_chin_side2_right = Math.abs(faceLandmarkerResult.faceLandmarks[0][377].y - faceLandmarkerResult.faceLandmarks[0][400].y)
     const angle_chin_side2_right = Math.atan((y_chin_side2_right*height) / (x_chin_side2_right*width))
    //  const angle_chin_side2_right = Math.atan(y_chin_side2_right / x_chin_side2_right)
     const degree_angle_chin_side2_right = angle_chin_side2_right * 180 / Math.PI
 
     //for chin side2 left
     const x_chin_side2_left = Math.abs(faceLandmarkerResult.faceLandmarks[0][176].x - faceLandmarkerResult.faceLandmarks[0][148].x)
     const y_chin_side2_left = Math.abs(faceLandmarkerResult.faceLandmarks[0][176].y - faceLandmarkerResult.faceLandmarks[0][148].y)
     const angle_chin_side2_left = Math.atan((y_chin_side2_left*height) / (x_chin_side2_left*width))
    //  const angle_chin_side2_left = Math.atan(y_chin_side2_left / x_chin_side2_left)
     const degree_angle_chin_side2_left = angle_chin_side2_left * 180 / Math.PI
 
     //for chin side left yellow and cyan
     const x_chin_side_left_yc = Math.abs(faceLandmarkerResult.faceLandmarks[0][149].x - faceLandmarkerResult.faceLandmarks[0][176].x)
     const y_chin_side_left_yc = Math.abs(faceLandmarkerResult.faceLandmarks[0][149].y - faceLandmarkerResult.faceLandmarks[0][176].y)
     const angle_chin_side_left_yc = Math.atan((y_chin_side_left_yc*height )/( x_chin_side_left_yc * width))
    //  const angle_chin_side_left_yc = Math.atan(y_chin_side_left_yc / x_chin_side_left_yc)
     const degree_angle_chin_side_left_yc = angle_chin_side_left_yc * 180 / Math.PI
 
     //for chin side right
     const x_chin_side_right_yc = Math.abs(faceLandmarkerResult.faceLandmarks[0][378].x - faceLandmarkerResult.faceLandmarks[0][400].x)
     const y_chin_side_right_yc = Math.abs(faceLandmarkerResult.faceLandmarks[0][378].y - faceLandmarkerResult.faceLandmarks[0][400].y)
     const angle_chin_side_right_yc = Math.atan((y_chin_side_right_yc*height) / (x_chin_side_right_yc * width))
    //  const angle_chin_side_right_yc = Math.atan(y_chin_side_right_yc / x_chin_side_right_yc)
     const degree_angle_chin_side_right_yc = angle_chin_side_right_yc * 180 / Math.PI
 
 
     //for chin side left khaki and cyan
     const x_chin_side_left_kc = Math.abs(faceLandmarkerResult.faceLandmarks[0][149].x - faceLandmarkerResult.faceLandmarks[0][136].x)
     const y_chin_side_left_kc = Math.abs(faceLandmarkerResult.faceLandmarks[0][149].y - faceLandmarkerResult.faceLandmarks[0][136].y)
     const angle_chin_side_left_kc = Math.atan((y_chin_side_left_kc*height) / (x_chin_side_left_kc*width))
    //  const angle_chin_side_left_kc = Math.atan(y_chin_side_left_kc / x_chin_side_left_kc)
     const degree_angle_chin_side_left_kc = angle_chin_side_left_kc * 180 / Math.PI
 
     //for chin side right
     const x_chin_side_right_kc = Math.abs(faceLandmarkerResult.faceLandmarks[0][378].x - faceLandmarkerResult.faceLandmarks[0][365].x)
     const y_chin_side_right_kc = Math.abs(faceLandmarkerResult.faceLandmarks[0][378].y - faceLandmarkerResult.faceLandmarks[0][365].y)
     const angle_chin_side_right_kc = Math.atan((y_chin_side_right_kc*height) / (x_chin_side_right_kc*width))
    //  const angle_chin_side_right_kc = Math.atan(y_chin_side_right_kc / x_chin_side_right_kc)
     const degree_angle_chin_side_right_kc = angle_chin_side_right_kc * 180 / Math.PI
 
 
     //for chin decision
 
     const degree_angle_chin_side_yellow_fuchsia = (degree_angle_chin_side2_right+degree_angle_chin_side2_left)/2
 
     const degree_angle_chin_side_green_fuchsia = (degree_angle_chin_fuchsia_green_right+degree_angle_chin_fuchsia_green_left)/2
 
     const degree_angle_chin_side_yellow_cyan = (degree_angle_chin_side_right_yc+degree_angle_chin_side_left_yc)/2
 
     const degree_angle_chin_side_khaki_cyan = (degree_angle_chin_side_right_kc+degree_angle_chin_side_left_kc)/2
 
     const answer_chin = 
     Math.abs(degree_angle_chin_side_green_fuchsia-degree_angle_chin_side_yellow_fuchsia)  < 17 && 
     Math.abs(degree_angle_chin_side_yellow_cyan-degree_angle_chin_side_yellow_fuchsia)  > 11.5 ?
       'first rounded' :
       Math.abs(degree_angle_chin_side_yellow_cyan-degree_angle_chin_side_khaki_cyan)  < 8 && 
       Math.abs(degree_angle_chin_side_green_fuchsia-degree_angle_chin_side_yellow_fuchsia)  > 17 
       ?'first sharp':
       Math.abs(degree_angle_chin_side_green_fuchsia-degree_angle_chin_side_yellow_fuchsia)  < 19 
      
        ?'second rounded':  Math.abs(degree_angle_chin_side_green_fuchsia-degree_angle_chin_side_yellow_fuchsia)  > 19? 
       
       'second sharp':'third round'
 
 
       console.log({answer_chin})

 
 
     //for text jawline
      //for jaw side left khaki and red
      const x_jaw_side_left_kr = Math.abs(faceLandmarkerResult.faceLandmarks[0][172].x - faceLandmarkerResult.faceLandmarks[0][136].x)
      const y_jaw_side_left_kr = Math.abs(faceLandmarkerResult.faceLandmarks[0][172].y - faceLandmarkerResult.faceLandmarks[0][136].y)
      const angle_jaw_side_left_kr = Math.atan((y_jaw_side_left_kr*height) /( x_jaw_side_left_kr*width))
    //   const angle_jaw_side_left_kr = Math.atan(y_jaw_side_left_kr / x_jaw_side_left_kr)
      const degree_angle_jaw_side_left_kr = angle_jaw_side_left_kr * 180 / Math.PI
  
      //for jaw side right
      const x_jaw_side_right_kr = Math.abs(faceLandmarkerResult.faceLandmarks[0][397].x - faceLandmarkerResult.faceLandmarks[0][365].x)
      const y_jaw_side_right_kr = Math.abs(faceLandmarkerResult.faceLandmarks[0][397].y - faceLandmarkerResult.faceLandmarks[0][365].y)
      const angle_jaw_side_right_kr = Math.atan((y_jaw_side_right_kr*height) /( x_jaw_side_right_kr*width))
    //   const angle_jaw_side_right_kr = Math.atan(y_jaw_side_right_kr / x_jaw_side_right_kr)
      const degree_angle_jaw_side_right_kr = angle_jaw_side_right_kr * 180 / Math.PI
      
 
 
      //for jaw side left darkGreen and red
      const x_jaw_side_left_gr = Math.abs(faceLandmarkerResult.faceLandmarks[0][172].x - faceLandmarkerResult.faceLandmarks[0][58].x)
      const y_jaw_side_left_gr = Math.abs(faceLandmarkerResult.faceLandmarks[0][172].y - faceLandmarkerResult.faceLandmarks[0][58].y)
      const angle_jaw_side_left_gr = Math.atan((y_jaw_side_left_gr*height) / (x_jaw_side_left_gr*width))
    //   const angle_jaw_side_left_gr = Math.atan(y_jaw_side_left_gr / x_jaw_side_left_gr)
      const degree_angle_jaw_side_left_gr = angle_jaw_side_left_gr * 180 / Math.PI
  
      //for jaw side right
      const x_jaw_side_right_gr = Math.abs(faceLandmarkerResult.faceLandmarks[0][397].x - faceLandmarkerResult.faceLandmarks[0][288].x)
      const y_jaw_side_right_gr = Math.abs(faceLandmarkerResult.faceLandmarks[0][397].y - faceLandmarkerResult.faceLandmarks[0][288].y)
      const angle_jaw_side_right_gr = Math.atan((y_jaw_side_right_gr*height) /( x_jaw_side_right_gr*width))
    //   const angle_jaw_side_right_gr = Math.atan(y_jaw_side_right_gr / x_jaw_side_right_gr)
      const degree_angle_jaw_side_right_gr = angle_jaw_side_right_gr * 180 / Math.PI
 
 
 
 
 
      //for jaw side left darkgreen and fuchsia
      const x_jaw_side_left_gf = Math.abs(faceLandmarkerResult.faceLandmarks[0][58].x - faceLandmarkerResult.faceLandmarks[0][132].x)
      const y_jaw_side_left_gf = Math.abs(faceLandmarkerResult.faceLandmarks[0][58].y - faceLandmarkerResult.faceLandmarks[0][132].y)
      const angle_jaw_side_left_gf = Math.atan((y_jaw_side_left_gf*height) / (x_jaw_side_left_gf*width))
    //   const angle_jaw_side_left_gf = Math.atan(y_jaw_side_left_gf / x_jaw_side_left_gf)
      const degree_angle_jaw_side_left_gf = angle_jaw_side_left_gf * 180 / Math.PI
  
      //for jaw side right
      const x_jaw_side_right_gf = Math.abs(faceLandmarkerResult.faceLandmarks[0][288].x - faceLandmarkerResult.faceLandmarks[0][361].x)
      const y_jaw_side_right_gf = Math.abs(faceLandmarkerResult.faceLandmarks[0][288].y - faceLandmarkerResult.faceLandmarks[0][361].y)
      const angle_jaw_side_right_gf = Math.atan((y_jaw_side_right_gf*height) / (x_jaw_side_right_gf*width))
    //   const angle_jaw_side_right_gf = Math.atan(y_jaw_side_right_gf / x_jaw_side_right_gf)
      const degree_angle_jaw_side_right_gf = angle_jaw_side_right_gf * 180 / Math.PI
 
 
      //for jaw side left lightPink and fuchsia
      const x_jaw_side_left_pf = Math.abs(faceLandmarkerResult.faceLandmarks[0][93].x - faceLandmarkerResult.faceLandmarks[0][132].x)
      const y_jaw_side_left_pf = Math.abs(faceLandmarkerResult.faceLandmarks[0][93].y - faceLandmarkerResult.faceLandmarks[0][132].y)
      const angle_jaw_side_left_pf = Math.atan((y_jaw_side_left_pf*height) /( x_jaw_side_left_pf*width))
    //   const angle_jaw_side_left_pf = Math.atan(y_jaw_side_left_pf / x_jaw_side_left_pf)
      const degree_angle_jaw_side_left_pf = angle_jaw_side_left_pf * 180 / Math.PI
  
      //for jaw side right
      const x_jaw_side_right_pf = Math.abs(faceLandmarkerResult.faceLandmarks[0][323].x - faceLandmarkerResult.faceLandmarks[0][361].x)
      const y_jaw_side_right_pf = Math.abs(faceLandmarkerResult.faceLandmarks[0][323].y - faceLandmarkerResult.faceLandmarks[0][361].y)
      const angle_jaw_side_right_pf = Math.atan((y_jaw_side_right_pf*height) / (x_jaw_side_right_pf*width))
    //   const angle_jaw_side_right_pf = Math.atan(y_jaw_side_right_pf / x_jaw_side_right_pf)
      const degree_angle_jaw_side_right_pf = angle_jaw_side_right_pf * 180 / Math.PI
 
      //for jaw side left yellow and dark green
      const x_jaw_side_left_yg = Math.abs(faceLandmarkerResult.faceLandmarks[0][176].x - faceLandmarkerResult.faceLandmarks[0][58].x)
      const y_jaw_side_left_yg = Math.abs(faceLandmarkerResult.faceLandmarks[0][176].y - faceLandmarkerResult.faceLandmarks[0][58].y)
      const angle_jaw_side_left_yg = Math.atan((y_jaw_side_left_yg*height) / (x_jaw_side_left_yg*width))
    //   const angle_jaw_side_left_yg = Math.atan(y_jaw_side_left_yg / x_jaw_side_left_yg)
      const degree_angle_jaw_side_left_yg = angle_jaw_side_left_yg * 180 / Math.PI
  
      //for jaw side right
      const x_jaw_side_right_yg = Math.abs(faceLandmarkerResult.faceLandmarks[0][400].x - faceLandmarkerResult.faceLandmarks[0][288].x)
      const y_jaw_side_right_yg = Math.abs(faceLandmarkerResult.faceLandmarks[0][400].y - faceLandmarkerResult.faceLandmarks[0][288].y)
      const angle_jaw_side_right_yg = Math.atan((y_jaw_side_right_yg*height) /( x_jaw_side_right_yg*width))
    //   const angle_jaw_side_right_yg = Math.atan(y_jaw_side_right_yg / x_jaw_side_right_yg)
      const degree_angle_jaw_side_right_yg = angle_jaw_side_right_yg * 180 / Math.PI
 
 
 
      //decision for jaw
     const degree_angle_jaw_side_khaki_red = (degree_angle_jaw_side_right_kr+degree_angle_jaw_side_left_kr)/2
 
     const degree_angle_jaw_side_darkGreen_red = (degree_angle_jaw_side_right_gr+degree_angle_jaw_side_left_gr)/2
 
     const degree_angle_jaw_side_darkGreen_fuchsia = (degree_angle_jaw_side_right_gf+degree_angle_jaw_side_left_gf)/2
 
     const degree_angle_jaw_side_lightPink_fuchsia = (degree_angle_jaw_side_right_pf+degree_angle_jaw_side_left_pf)/2
 
     const degree_angle_jaw_side_darkGreen_yellow = (degree_angle_jaw_side_right_yg+degree_angle_jaw_side_left_yg)/2
     
     console.log({
      firstangled:degree_angle_jaw_side_darkGreen_yellow,
      firstrounded1:Math.abs(Math.abs(degree_angle_jaw_side_khaki_red-degree_angle_jaw_side_darkGreen_red) -Math.abs(degree_angle_jaw_side_darkGreen_red-degree_angle_jaw_side_darkGreen_fuchsia)),
      firstrounded2: Math.abs(Math.abs(degree_angle_jaw_side_lightPink_fuchsia-degree_angle_jaw_side_darkGreen_fuchsia) -Math.abs(degree_angle_jaw_side_darkGreen_red-degree_angle_jaw_side_darkGreen_fuchsia)),
      
    })

     const answer_jaw =degree_angle_jaw_side_darkGreen_yellow<20
     ?
     'first angled':
     Math.abs(Math.abs(degree_angle_jaw_side_khaki_red-degree_angle_jaw_side_darkGreen_red) -Math.abs(degree_angle_jaw_side_darkGreen_red-degree_angle_jaw_side_darkGreen_fuchsia)) < 5 &&
 
     Math.abs(Math.abs(degree_angle_jaw_side_lightPink_fuchsia-degree_angle_jaw_side_darkGreen_fuchsia) -Math.abs(degree_angle_jaw_side_darkGreen_red-degree_angle_jaw_side_darkGreen_fuchsia)) < 5
 
     ? 'first rounded' : 'second angled'
 
  
     console.log({answer_jaw})
 

    //displaying result for face shape
    var answer;
    switch (answer_length) {
        case 'long':
            answer = 'oblong'
            break;
        case 'medium':
            if (answer_jaw.endsWith('angled') ) {
                if (answer_chin.endsWith('rounded') ) {
                    answer = 'triangle'
                }
                else if (answer_chin.endsWith('sharp')) {
                    answer = 'diamond'
                }
            }
            else if (answer_jaw.endsWith('rounded')) {
                if (answer_chin.endsWith('rounded')) {
                    answer = 'oval'
                }
                else if (answer_chin.endsWith('sharp')) {
                    answer = 'heart'
                }
            }
            break;
        case 'small':

            if (answer_jaw.endsWith('angled')) {
                answer = 'square'
            }
            else if (answer_jaw.endsWith('rounded') ) {
                answer = 'round'
            }
            break;

        default:
            break;
    }




    //for color complexion
    //129,230,266,425,345,182,411,280, 352, 366 right cheek
    //100,142,119,118,105,128,229,123, 147, 187 left cheek
    //1,4,5,3,419,197,9,6,122,351 nose

    const pixels = [
        // 129, 230, 266, 425, 345, 182, 411, 280, 352, 366,
        // 100, 142, 119, 118, 105, 128, 229, 123, 147, 187,
        // 1, 4, 5, 3, 419, 197, 9, 6, 122, 351
        9, 50, 4, 16
    ]
    let detected_colors_category = []
    let detected_colors_hex = []

    pixels.forEach(e => {
        let X = width * faceLandmarkerResult.faceLandmarks[0][e].x
        let Y = height * faceLandmarkerResult.faceLandmarks[0][e].y
        const pixelData = offscreenContext.getImageData(X, Y, 1, 1).data;
        const color = {
            red: pixelData[0],
            green: pixelData[1],
            blue: pixelData[2],
        };
        const color_hex = rgbToHex(color.red, color.blue, color.green)
        const color_category = faceTone(color_hex)

        detected_colors_category.push(color_category.catagory)
        detected_colors_hex.push(color_category.hex)
    });

    const predicted_color=findMajorityElement(detected_colors_category)
    const predicted_color_hex=findMajorityElement(detected_colors_hex)



    measurementValue(faceLandmarkerResult.faceLandmarks[0])
    shapeValue(answer)
    colorValue(predicted_color)
    updateFlag(true)
}