

import { getColor } from './RecommendColor'
import { getFrame } from './RecommendFrame'
import { finalizeArray } from './finalizeArray'



export const decisionMaker = async (faceShape, faceColor, hairColor, hairStyle, personality, age, gender) => {
    
    const frames= await getFrame(faceShape,hairColor, hairStyle, personality, age, gender)

    const colors = await getColor( faceColor, hairColor, hairStyle, personality, age, gender)

    const arrays = await finalizeArray(frames,colors)

    // console.log(arrays.arrayFrame)
    // console.log(arrays.arrayColor)
    return arrays

}


//heart,oblong,diamond,triangle,square,round,oval
//'black','darkbrown','brown','tanned','pale','lightpale'
//'blonde', 'black','red','gray','auburn','black'
//'short','long','curly','straight','updo','bangs','pixiecut'
// 'Pessimistic', 'Indoor', 'Traditional', 'Logical', 'Professional', 'Easygoing','Conscientious', 'Ambivert', 'Analytical', 'Nurturing', 'Empathetic', 'Optimistic', 'Independent', 'Spontaneous', 'Confident', 'Adventurous', 'Creative', 'Extroverted'
//male female

// module.exports={
//     decisionMaker
// }



