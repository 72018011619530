import React from "react";

const Nav_circles_svg = ({color1,color2,color3,...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 10"
    {...props}
  >
    <circle cx="5" cy="5" r="5" fill={color1} />
    <circle cx="21.9219" cy="5" r="5" fill={color2}/>
    <circle cx="38.8457" cy="5" r="5" fill={color3} />
  </svg>
);

export default Nav_circles_svg;
