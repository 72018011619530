

import { HairColorStyle } from './HairColorStyle';
import { personalityBase } from './personalityBase';
import { GenderAgeRecommend } from './AgeGenderBase';
import { RecommendationOnFaceColor } from './FaceColor';

import { findCommonElements, findCommonElementsInFour, findCommonElementsInThree  } from './utils';

export async function getColor(faceColor, hairColor, hairStyle, personality, age, gender) {
    const colorRecommend = {}
    //for hair, personality and ageGender getting color and frame
    const hairWise = HairColorStyle(hairColor, hairStyle)
    const personalityWise = personalityBase(personality)
    const ageGenderWise = GenderAgeRecommend(gender, age)


    const skinWise = { color: RecommendationOnFaceColor(faceColor), reason: 'skin_tone', pirority: 4 }
    const hairColorComplimentWise = { color: hairWise.compliment, reason: 'hair_color_compliment', pirority: 2 }
    const hairColorContrastWise = { color: hairWise.contrast, reason: 'hair_color_contrast', pirority: 2 }
    const personalityColorWise = { color: personalityWise.color, reason: 'personality', pirority: 3 }
    const agColorWise = { color: ageGenderWise.colors, reason: 'age_gender', pirority: 1 }



    const hairColors = [hairColorComplimentWise, hairColorContrastWise]

    hairColors.forEach(hairColorWise => {
        const mostcommon = findCommonElementsInFour(skinWise.color, hairColorWise.color, personalityColorWise.color, agColorWise.color)

        if (mostcommon.length > 0) {
            const priority = skinWise.pirority + hairColorWise.pirority + personalityColorWise.pirority + agColorWise.pirority
            const reason = []
            reason.push(skinWise.reason)
            reason.push(hairColorWise.reason)
            reason.push(agColorWise.reason)
            reason.push(personalityColorWise.reason)
            
            colorRecommend[`priority${priority}`] = [{
                color: mostcommon,
                reason,
                priority
            }]
        }

        const totalColors = [skinWise, hairColorWise, personalityColorWise, agColorWise]
        for (let i = 0; i < totalColors.length - 1; i++) {
            for (let j = i + 1; j < totalColors.length; j++) {

                const mostcommon = findCommonElements(totalColors[i].color, totalColors[j].color)
                if (mostcommon.length > 0) {
                    const priority = totalColors[i].pirority + totalColors[j].pirority
                    const reason = []
                    reason.push(totalColors[i].reason)
                    reason.push(totalColors[j].reason)
                    if (colorRecommend.hasOwnProperty(`priority${priority}`) && colorRecommend[`priority${priority}`].length > 0) {
                        colorRecommend[`priority${priority}`].push({
                            color: mostcommon,
                            reason,
                            priority
                        })
                    }
                    else {
                        colorRecommend[`priority${priority}`] = [{
                            color: mostcommon,
                            reason,
                            priority
                        }]
                    }

                }
            }
        }


        for (let i = 0; i < totalColors.length - 2; i++) {
            for (let j = i + 1; j < totalColors.length - 1; j++) {
                for (let k = j + 1; k < totalColors.length; k++) {

                    const mostcommon = findCommonElementsInThree(totalColors[i].color, totalColors[j].color, totalColors[k].color)
                    if (mostcommon.length > 0) {

                        const priority = totalColors[i].pirority + totalColors[j].pirority + totalColors[k].pirority

                        const reason = []
                        reason.push(totalColors[i].reason)
                        reason.push(totalColors[j].reason)
                        reason.push(totalColors[k].reason)


                        if (colorRecommend.hasOwnProperty(`priority${priority}`) && colorRecommend[`priority${priority}`].length > 0) {
                            colorRecommend[`priority${priority}`].push({
                                color: mostcommon,
                                reason,
                                priority
                            })
                        }
                        else {
                            colorRecommend[`priority${priority}`] = [{
                                color: mostcommon,
                                reason,
                                priority
                            }]
                        }


                    }

                }
            }

        }

    });

    return colorRecommend

}
