export const drawSquareCorners = (canvasRef) => {

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const canvasWidth = canvas.offsetWidth;
    const canvasHeight = canvas.offsetHeight;


    // Clear the canvas
    context.clearRect(0, 0, canvasWidth, canvasHeight);

    // Calculate square dimensions
    const squareSize = Math.min(canvasWidth, canvasHeight) * 0.5;
    const squareX = (canvasWidth - squareSize) / 2;
    const squareY = (canvasHeight - squareSize) / 2;

    // Calculate corner size y-axis
    const cornerSizeWidthY = squareSize * 0.05;
    const cornerSizeHeightY = squareSize * 0.13;

    // Calculate corner size x-axis
    const cornerSizeWidthX = squareSize * 0.16;
    const cornerSizeHeightX = squareSize * 0.04;




    // Draw the corners
    context.fillStyle = 'blue';
    context.fillRect(squareX, squareY, cornerSizeWidthY, cornerSizeHeightY);
    context.fillRect(squareX, squareY, cornerSizeWidthX, cornerSizeHeightX);
    context.fillStyle = 'red';
    context.fillRect(squareX + squareSize - cornerSizeWidthY, squareY, cornerSizeWidthY, cornerSizeHeightY);
    context.fillRect(squareX - cornerSizeWidthX + squareSize, squareY, cornerSizeWidthX, cornerSizeHeightX);
    context.fillStyle = 'green';
    // context.fillRect(squareX, squareY + squareSize - cornerSize, cornerSize, cornerSize);
    context.fillRect(squareX, 2.5 * squareY + squareY, cornerSizeWidthY, cornerSizeHeightY);
    context.fillRect(squareX, 2.5 * squareY + squareY + cornerSizeHeightY - cornerSizeHeightX, cornerSizeWidthX, cornerSizeHeightX);

    context.fillStyle = 'yellow';
    // context.fillRect(squareX + squareSize - cornerSize, squareY + squareSize - cornerSize, cornerSize, cornerSize);
    context.fillRect(squareX + squareSize - cornerSizeWidthY, 2.5 * squareY + squareY, cornerSizeWidthY, cornerSizeHeightY);
    context.fillRect(squareX - cornerSizeWidthX + squareSize, 2.5 * squareY + squareY + cornerSizeHeightY - cornerSizeHeightX, cornerSizeWidthX, cornerSizeHeightX);

}

export const drawOvalDotted = (canvasRef, color) => {

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const canvasWidth = canvas.offsetWidth;
    const canvasHeight = canvas.offsetHeight;

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    ctx.strokeStyle = color;
    ctx.lineWidth = 5
    ctx.setLineDash([15, 5]);

    ctx.beginPath();
    ctx.ellipse(canvasWidth / 2, canvasHeight / 2, canvasWidth / 5, canvasWidth / 3, Math.PI, 0, 2 * Math.PI);
    ctx.stroke();

    // Draw horizontal line
    // ctx.strokeStyle = "white";
    // ctx.setLineDash([25, 10]);
    // ctx.beginPath();
    // ctx.moveTo(canvasWidth / 2 - canvasWidth / 5, canvasHeight / 2);
    // ctx.lineTo(canvasWidth / 2 + canvasWidth / 5, canvasHeight / 2);
    // ctx.stroke();

    // // Draw vertical line
    // ctx.beginPath();
    // ctx.moveTo(canvasWidth / 2, canvasHeight / 2 - canvasWidth / 3);
    // ctx.lineTo(canvasWidth / 2, canvasHeight / 2 + canvasWidth / 3);
    // ctx.stroke();
}
export const drawNumber=(canvasRef,number)=>{
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const canvasWidth = canvas.offsetWidth;
    const canvasHeight = canvas.offsetHeight;


    // Set font and color
    context.font = '80px Arial';
    context.fillStyle = 'White';
    context.clearRect(0, 0, canvasWidth, canvasHeight);
    const centerX = canvasWidth / 2;
    const centerY =canvasHeight / 2;
    context.fillText(number.toString(), centerX, centerY);
}

export const drawPerpendicularLines = (canvasRef) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const canvasWidth = canvas.offsetWidth;
    const canvasHeight = canvas.offsetHeight;

    ctx.strokeStyle = "white";
    ctx.lineWidth = 5
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;

    ctx.setLineDash([25, 10]);
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    ctx.moveTo(centerX, 0);
    ctx.lineTo(centerX, canvasHeight);
    // ctx.stroke();

    // ctx.lineWidth = 1

    // ctx.beginPath();
    ctx.moveTo(0, centerY);
    ctx.lineTo(canvasWidth, centerY);
    ctx.stroke();




}