import React from "react";

const Left_arrow_svg = ({color,...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 15"
    {...props}
  >
    <path
      d="M0.792892 8.20711C0.40237 7.81658 0.40237 7.18342 0.792892 6.79289L7.15685 0.428932C7.54738 0.0384078 8.18054 0.0384078 8.57107 0.428932C8.96159 0.819457 8.96159 1.45262 8.57107 1.84315L2.91422 7.5L8.57107 13.1569C8.96159 13.5474 8.96159 14.1805 8.57107 14.5711C8.18054 14.9616 7.54738 14.9616 7.15685 14.5711L0.792892 8.20711ZM47.5 8.5H1.5V6.5H47.5V8.5Z"
      fill={color}
    />
  </svg>
);

export default Left_arrow_svg;
