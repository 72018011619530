import React, { useRef, useState } from "react";
import faceContext from "./faceContext";
import { findMajorityElement, averageCalculation } from '../../Utilities/commonFunction';

const FaceState = (props) => {


    const ageGenderResults = async (ageGender) => {

        const ageResults = ageGender.age;
        const genderResults = ageGender.gender;

        faces.ageArray.push(ageResults)
        faces.genderArray.push(genderResults)

    }

    const shapeResults = async (shapes) => {

        faces.shapeArray.push(shapes)

    }

    const colorsOfFace = async (faceColor) => {
        faces['faceColor'] = faceColor
    }

    const colorsPersonalityAndHairs = async (personality, hairColor, hairStyle) => {

        faces['hairColor'] = hairColor
        faces['hairStyle'] = hairStyle
        faces['personality'] = personality
    }
    const colorOfEye = async (eyeColor) => {
        faces['eyeColor'] = eyeColor

    }

    const setFacingMode = async (mode) => {
        faces['facingMode'] = mode
    }

    const pdResults = async (pd) => {
        faces['PD'] = pd
    }

    const averageCalculator = async () => {


        const averageShapePrediction = findMajorityElement(faces.shapeArray)
        const averageGenderPrediction = findMajorityElement(faces.genderArray[0])
        const averageAgePrediction = averageCalculation(faces.ageArray[0])


        faces['avgAge'] = averageAgePrediction
        faces['avgGender'] = averageGenderPrediction
        faces['avgShape'] = averageShapePrediction


    }

    const saveImageBase64 = async (frame) => {
        faces['capImageBase64'] = frame;
    }

    const saveImageElement = async (element) => {
        faces['capImageElement'] = element;
    }
    const saveResizedImage = async (element) => {
        faces['resizedImg'] = element;
    }

    const allLandmakrs = async (element) => {
        faces['landmarksPoint'] = element;

    }



    const clearFaceState = () => {
        const newFace = {
            facingMode: 'environment',
            PD: null,
            ageArray: [],
            genderArray: [],
            shapeArray: [],
            avgAge: null,
            avgGender: null,
            avgShape: null,
            faceColor: "black",
            eyeColor: "black",
            hairStyle: "curly",
            hairColor: "black",
            personality: "adventure",
            capImageBase64: null,
            capImageElement: null,
            resizedImg: null,
            landmarksPoint: null
        }
        setFaces(newFace)
    }


    const clearDetections = () => {
        const newFace = {
            ...faces,
            PD: null,
            ageArray: [],
            genderArray: [],
            shapeArray: [],
            avgAge: null,
            avgGender: null,
            avgShape: null,
            faceColor: null,
            capImageBase64: null,
            capImageElement: null,
            resizedImg: null,
            landmarksPoint: null
        }
        setFaces(newFace)
    }



    const [faces, setFaces] = useState({
        facingMode: 'environment',
        PD: null,
        ageArray: [],
        genderArray: [],
        shapeArray: [],
        avgAge: null,
        avgGender: null,
        avgShape: null,
        faceColor: null,
        eyeColor: "black",
        hairStyle: "curly",
        hairColor: "black",
        personality: "Adventure",
        capImageBase64: null,
        capImageElement: null,
        resizedImg: null,
        landmarksPoint: null
    })
    
    return (
        <faceContext.Provider value={{
            faces,
            ageGenderResults,
            shapeResults,
            averageCalculator,
            saveImageBase64,
            clearFaceState,
            pdResults,
            saveImageElement,
            saveResizedImage,
            colorsPersonalityAndHairs,
            colorsOfFace,
            setFacingMode,
            allLandmakrs,
            clearDetections,
            colorOfEye
        }}>
            {props.children}
        </faceContext.Provider>

    )
}



export default FaceState