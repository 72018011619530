const colorTypes = [
    //0 to 3
    'black', 'brightred', 'brown', 'electricblue',
    //4 to 7 
    'grey', 'oranges', 'pink', 'tortoise', 
    //8 to 9
    'vividgreen', 'yellow'
]
const faceColors=[
    'black','darkbrown','brown','tanned','pale','lightpale'
]
const colorRecommendation=[
    [9,4,1,3,7],[2,8,9,6],[2,9,1],[2,8,5,9],[4,0,3,6,7],[4,9,3]
]
export const RecommendationOnFaceColor=(color)=>{
    const number=faceColors.findIndex(x =>x===color)
    const colorNumber=colorRecommendation[number]
    var colors=[];
    
    colorNumber.forEach(n => {
        colors.push(colorTypes[n])
    });
    

    return colors
}


