const glassesTypes = [ 
    //0 to 3
    'aviator','browline','cateye','geometric',
    //4 to 7
    'oval','oversized','rectangle',   'round', 
    //8
    'square']
const faceShapes=['oblong','triangle', 'diamond', 'oval','heart','square','round']
const frameRecommendation=[
    [0,1,5],[2,4],[3,4,2],[3,5,6,8],
    [0,2],[7,4,2],[8,6]
]
 
export const recommenderOnShape = (shape) => {
   
    const number=faceShapes.findIndex(x =>x===shape)
    const frameNumber=frameRecommendation[number]
    var frames=[];
    frameNumber.forEach(n => {
        frames.push(glassesTypes[n])
    });

    return frames
    

}




