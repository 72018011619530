import React, { useState } from "react";
import framesContext from "./framesContext";
import { arrangeRecommendtaionWithLimit } from "../../Utilities/DecisionMaker/utils";

const FrameState = (props) => {

    const prepareUI = async (recommendations, limit) => {
        const frameArray = recommendations.arrayFrame;
        const colorArray = recommendations.arrayColor;

        const frame = arrangeRecommendtaionWithLimit(frameArray,limit);
        const color = arrangeRecommendtaionWithLimit(colorArray,limit);

        setFrames({
            frame: frame,
            color: color
        })

        return true;
    }

    const clearFrameState = () => {
        setFrames({
            frame: null,
            color: null
        })
    }

    const [frames, setFrames] = useState({
        frame: null,
        color: null
    })
    return (
        <framesContext.Provider value={{ frames, setFrames, prepareUI, clearFrameState }}>
            {props.children}
        </framesContext.Provider>

    )
}



export default FrameState