import React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import userContext from '../../../Context/users/userContext'
import { ReactComponent as PinkSpinner } from '../../../assets/svgs/loading_spinner_small_pink.svg';
import { useNavigate } from 'react-router-dom';
import FeedbackContext from '../../../Context/FeedbackContext/FeedbackContext';

const HistoryIssue = ({ order, colors }) => {

    const { user } = useContext(userContext)





    const uploadRef = useRef(null)


    const feedbackContext = useContext(FeedbackContext)
    const { saveFeedback } = feedbackContext

    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState({ email: user.email, exp: '', title: '' })
    const [uploading, setUploading] = useState(false)

    const [error, setError] = useState({
        show: false,
        msg: ""
    })
    const [success, setSuccess] = useState({
        show: false,
        msg: ""
    })



    const onChange = (e) => {
        setFeedback({ ...feedback, [e.target.name]: e.target.value })

        if (e.target.name === 'exp') {
            e.target.style.height = 'auto'; // Reset the height first
            e.target.style.height = e.target.scrollHeight + 'px'; // Set the new height
        }
    }



    const submitHandler = async (e) => {
        e.preventDefault()


        uploadRef.current.classList.add('d-none')
        setUploading(true)
        const issue = `This feed back is generated from order in frameswise \n The user that is reporting the issue have ID ${user._id} \n And the order in which problem is reported is ${order._id} \n The title is ${feedback.title} and the issue is \n${feedback.exp}`

        const result = await saveFeedback('frameswise', rating, feedback.email, issue)


        if (!result) {
            setError({
                show: true,
                msg: "Unable to post feedback. Please try again"
            })
            uploadRef.current.classList.remove('d-none')
        }
        else {
            setSuccess({
                show: true,
                msg: "Your suggestion has been submitted"
            })

        }


        setUploading(false)
    }
    return (
        <>
            <div className="d-flex flex-column p-2" style={{
                color:colors.primary[100]
            }}>
                <span className=" fs-4 fw-bold">
                    Submit your Issue
                </span>

                <span className=" w-100">
                    User ID: {user._id}
                </span>


                <span className="w-100">
                    Record ID: {order._id}
                </span>

                <div className=''>
                    <form onSubmit={submitHandler}>

                        <div className="row">

                            <div className="mb-3 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="email" className="form-label  fs-4 fw-bold">Your Email address</label>
                                <input required disabled onChange={onChange} type="email" className="form-control" id="email" name='email' value={user.email} aria-describedby="emailHelp" />
                            </div>

                            <div className="mb-3 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="email" className="form-label  fs-4 fw-bold">Title</label>
                                <input required onChange={onChange} type="text" className="form-control" id="title" name='title' value={feedback.title} />
                            </div>






                        </div>

                        <div className='mb-2 '>
                            <span className=" fs-4 fw-bold">Breifly explain the issue</span>
                        </div>
                        <div className="form-floating mb-3">

                            <textarea className="form-control" placeholder="Leave a comment here" name='exp' id="exp"
                                style={{
                                    height: 'fit-content',
                                    minHeight: '200px',
                                    overflowY: 'auto',
                                    maxHeight: '600px'

                                }}
                                required
                                value={feedback.exp} onChange={onChange}></textarea>
                            <label htmlFor="exp ">Explain Issue:</label>
                        </div>

                        {success.show &&
                            <div className="d-flex my-2">
                                <span className="text-success fw-semibold fs-5">
                                    {success.msg}
                                </span>
                            </div>
                        }
                        {error.show &&
                            <div className="d-flex my-2">
                                <span className="text-danger fw-semibold fs-5">
                                    {error.msg}
                                </span>
                            </div>
                        }





                        <button type="submit" ref={uploadRef} className="btn btn-default mx-2">Submit the issue</button>





                        {uploading &&
                            <div className='mb-5'>
                                <PinkSpinner style={{ marginTop: '10px', width: "30px", height: "30px" }} />
                            </div>
                        }
                    </form>
                </div>
            </div>
        </>
    )
}

export default HistoryIssue
