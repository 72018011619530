import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import userContext from '../../Context/users/userContext';
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";
import { ReactComponent as LoadingSpinner } from "../../assets/svgs/loading_spinner.svg";
const EmailVerified = () => {
    const navigate= useNavigate();
    const { id, token } = useParams();
    const user_context = useContext(userContext)
    const { verifyEmail } = user_context
    const [isVerified, setIsVerified] = useState(false)
    const [failed, setFailed] = useState(false)
    useEffect(() => {
        process()
    })



    const process = async () => {

        const result = await verifyEmail(id, token)
        if (result.success) {
            setIsVerified(true)
        }
        else {
            setFailed(true)
        }
    }
    return (
        <>
        

            <section className="sectionWelcome">
                <div className="parentmainW">
                    <div className="mainW px-2">
                        <Logo />
                        {!isVerified  && !failed && <>

                            <span className="headingW mt-3">
                                Verifing Your Email
                            </span>
                            <span className="fs-5 text-white">Please Wait</span>

                            <LoadingSpinner
                                style={{ marginTop: "10px", width: "40px", height: "40px" }}
                            />
                        </>}
                        {isVerified && !failed &&
                            <>
                                <span className="headingW mt-3">
                                    Your Email Has Been Verified
                                </span>
                                <span className="fs-5 text-white">Please return back to the app</span>
                                <button className="btnW mt-3" onClick={() => {
                                    navigate("/")
                                }}>
                                    Return to App
                                </button>
                            </>}
                        {
                            failed &&
                            <>
                                <span className="headingW mt-3">
                                    Your token is in valid
                                </span>
                                <span className="fs-5 text-white">Please generate a new token by loggin in on app</span>
                                <button className="btnW mt-3" onClick={() => {
                                    navigate("/verifyfirst")
                                }}>
                                    Return to App
                                </button>
                            </>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmailVerified