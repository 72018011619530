// Import the color-diff library
const colorDiff = require('color-diff');
const deltaE = require('delta-e');
const convert = require('color-convert');


// Your defined set of hex colors
const definedColors = [
    '#4b0f01', '#6A330C', '#57331D', '#563E37', '#483223', '#592001',
     '#8D7260', '#8E572A','#7D4821','#9C5F3D','#8E522D','#81431F',
    '#CF9F76', '#B08873','#9D7762','#AD8459','#A66D35','#917851',
    '#F8B583','#EFA96A','#EE9A5E','#E4B586','#DDAA7F','#E18C4C',
    '#FACDC6','#FFC8AF','#F8BFA5','#F0BDB6','#EEC29C','#F5B491',
    '#FDDAC6','#F0D0B4','#F1DDCB','#EFC7B0','#EACBC6','#EED9CE',
];
// const definedColors = ['#8d5524', '#c68642', '#e0ac69', '#f1c27d', '#ffdbac'];
const defincedColorsCatagories = [
    'black','black','black','black','black','black',
    'darkbrown','darkbrown','darkbrown','darkbrown','darkbrown','darkbrown',
    'brown','brown','brown','brown','brown','brown',
    'tanned','tanned','tanned','tanned','tanned','tanned',
    'pale','pale','pale','pale','pale','pale',
    'lightpale','lightpale','lightpale','lightpale','lightpale','lightpale'
]


export const faceTone = (userHex) => {
    var closestColor = null;
    let minDeltaE = Infinity;
    // const userRgbColor = hexToRgb(userHex)
    const userLabColor = convert.hex.lab(userHex);

    const userLAB = {
        L: userLabColor[0],
        A: userLabColor[1],
        B: userLabColor[2]
    }
    for (const definedHexColor of definedColors) {

        // const definedRgbColor = hexToRgb(definedHexColor);
        const definedLabColor = convert.hex.lab(definedHexColor);



        const defindedLAB = {
            L: definedLabColor[0],
            A: definedLabColor[1],
            B: definedLabColor[2]
        }
       
        const deltaEValue = deltaE.getDeltaE00(userLAB, defindedLAB);


        if (deltaEValue < minDeltaE) {
            minDeltaE = deltaEValue;
            closestColor = definedHexColor;

        }
    }

    const index = findIndex(definedColors, closestColor)


    const color={
        catagory:defincedColorsCatagories[index],
        hex:definedColors

    }
    
    return color

}



// Convert RGB to Lab
function rgbToLab(rgb) {
    const lab = colorDiff.rgb_to_lab(rgb);
    return lab;
}

// Function to find index of element in array
function findIndex(array, element) {
    for (let i = 0; i < array.length; i++) {
        if (array[i] === element) {
            return i; // Found the element, return its index
        }
    }
    return -1; // Element not found
}