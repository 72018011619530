import React from 'react'
import Navbar from '../Navbar';
import SignupDetails from './SignupDetails';

const Signup = (props) => {
  
  return (
    <>
      {/* <Navbar /> */}
      <SignupDetails />
    </>
  )
}

export default Signup