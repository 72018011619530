import React, { useContext, useRef, useState } from 'react'
import Navbar from '../Navbar'
import userContext from '../../Context/users/userContext'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";
import { ReactComponent as LoadingSpinner } from "../../assets/svgs/loading_spinner.svg";


const VerifyFirst = () => {
    const navigate = useNavigate()
    const sendLinkBtn = useRef(null)
    const user_context = useContext(userContext)
    const { user, setUser, resendVerificationLink, verificationCheck } = user_context

    const [updating, setUpdating] = useState(false)
    const [sentMail, setSentMail] = useState(false)
    const [error, setError] = useState({
        show: false,
        msg: ""
    })

    const clickHandler = async () => {
        sendLinkBtn.current.classList.add("d-none")
        setUpdating(true)
        setError({ ...error, show: false })
        try {
            const result = await resendVerificationLink(user.email)
            if (result) {

                setSentMail(true)

                const checkInterval = setInterval(async () => {
                    const result = await verificationCheck()
                    if (result) {
                        navigate('/home')
                        clearInterval(checkInterval)
                    }
                    else if (result.error) {
                        clearInterval(checkInterval)
                    }
                }, 1000);
            }
            else {
                setError({ ...error, msg: "Unable to sent mail, please try again", show: false })
                sendLinkBtn.current.classList.remove("d-none")
                setUpdating(false)


            }
        } catch (err) {

            setError({ ...error, msg: "Unable to sent mail, please try again", show: false })
            sendLinkBtn.current.classList.remove("d-none")
            setUpdating(false)

        }
    }


    return (
        <>
            {/* <div className='px-5'>
                <div className="container my-5 ">
                    <center>
                        <span className="d-flex fs-2 text-white fw-bold justify-content-center">
                            Email Verification Required
                        </span>
                    </center>
                </div>
                <div className="mt-5">
                    <div className=' mb-3'>
                        <span className="text-white fs-5 ">You must need to verify your email "<span className='fw-bold'>{user.email}</span>" before you can continue to use the app!</span>

                    </div>
                    <div>
                        <span className="text-white fs-5">Click on Send Link button to get a verification email.</span>

                    </div>
                </div>
                <div className="mt-5">
                    <center>
                        <button onClick={clickHandler} ref={sendLinkBtn} className="btn-pinkBlueGradient p-3 fw-bold fs-4">Send Link</button>
                    </center>
                    <div>
                        {sentMail && <span className="fs-6 text-lightGreen fw-bold ">Verification email Has Been Sent to Your entered email address.</span>}

                    </div>
                    <div >
                        <center>
                            {updating && <img className='img-fluid' src={require('../../assets/gifs/loading_blue_circle.gif')} style={{
                                width: "120px",
                                height: '120px',
                                marginRight: '10px'
                            }} alt="" />}
                        </center>
                    </div>
                    <div>
                        <center>
                            {sentMail && <span className="fs-6 text-lightGreen fw-bold">Waiting for you to verify the link!</span>}
                        </center>
                    </div>
                    <div className="d-flex my-2">
                        {error.show &&
                            <span className="text-lightRed fw-bold fs-5">
                                {error.msg}
                            </span>
                        }

                    </div>
                </div>
            </div> */}

            <section className="sectionWelcome">
                <div className="parentmainW">
                    <div className="mainW px-2">
                        <Logo />
                        <span className="headingW mt-3">
                            Verify Your Email 
                            <br />
                          
                            <span className="fs-5 text-white">Click on Send Link button to get a verification email</span>
                        </span>
                        <button ref={sendLinkBtn} className="btnW mt-3" onClick={clickHandler}>
                            Send Link
                        </button>
                        {sentMail && <span className="fs-6 text-lightGreen fw-bold ">Verification email Has Been Sent to Your entered email address.</span>}
                        {updating && <LoadingSpinner
                            style={{ marginTop: "10px", width: "40px", height: "40px" }}
                        />}
                        {sentMail && <span className="fs-6 text-lightGreen fw-bold">Waiting for you to verify the link!</span>}
                        {error.show &&
                            <span className="text-lightRed fw-bold fs-5">
                                {error.msg}
                            </span>
                        }
                    </div>
                </div>
            </section>

        </>
    )
}

export default VerifyFirst