import React, { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import userContext from '../../Context/users/userContext';

import '../../assets/css/glassmophorm.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

import { ReactComponent as LoadingSpinnerSmall } from '../../assets/svgs/loading_spinner_small_pink.svg';


const ResetPassword = () => {
    const resetBtn = useRef(null)
    const { id, token } = useParams();
    const [success, setSuccess] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState({
        isError: false,
        msg: ""
    })

    const context = useContext(userContext)
    const { resetPassword, resetValidURL } = context

    const [passwordVisibility, setPasswordVisibility] = useState(false)
    const [cpasswordVisibility, setcPasswordVisibility] = useState(false)


    const [user, setuser] = useState({
        signupPassword: "",
        signupConfirmPassword: ""
    })


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user.signupPassword === user.signupConfirmPassword) {
            setError(false)
            setUpdating(true)
            resetBtn.current.classList.add("d-none")

            const valid = await resetValidURL(id, token)

            if (valid.success) {
                const result = await resetPassword(id, token, user.signupPassword)

                if (result.success) {
                    localStorage.setItem('token', result.authToken)
                    // props.showAlert("Signed up successfully!", "success")
                    setSuccess(true)
                    // navigate('/')
                    setUpdating(false)


                }
                else {
                    setError({
                        ...error, isError: true,
                        msg: "Unable to Change"
                    })
                    setUpdating(false)
                }

            }
            else {
                setError({
                    ...error, isError: true,
                    msg: "Link Expired"
                })
                setUpdating(false)
            }





        }

        else {
            setError({
                ...error, isError: true,
                msg: "Passwords do not match"
            })
        }
    }



    const onChange = (e) => {
        setuser({ ...user, [e.target.name]: e.target.value })
    }
    return (
        <>
            <div className="form-container bg-6 w-100">
                <form id="form" onSubmit={handleSubmit} className='py-2'>
                  
                    <h4 className='fw-bold text-white'>Reset Your Password</h4>
                    <h3>Please enter your a new strong password.</h3>
                    <h2 className='text-info fs-5'>Your password must contain one capital letter, one small letter, one number and one special character.</h2>

                    <div>
                        <FontAwesomeIcon className='mx-1' icon={faLock} style={{ color: "#fc03e8" }} />
                        <input className='ms-2' placeholder='Password' type={`${passwordVisibility ? 'text' : 'password'}`} id="signupPassword" name='signupPassword' onChange={onChange} value={user.signupPassword} required style={{
                            width: ' 80%'
                        }} />
                        <FontAwesomeIcon className='ps-2' icon={passwordVisibility ? faEye : faEyeSlash} style={{
                            color: "#fc03e8",
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                setPasswordVisibility(!passwordVisibility)
                            }}
                        />

                    </div>
                    <div>
                        <FontAwesomeIcon className='mx-1' icon={faLock} style={{ color: "#fc03e8" }} />

                        <input className='ms-2' type={`${cpasswordVisibility ? 'text' : 'password'}`} placeholder='Confirm Password' id="signupConfirmPassword" name='signupConfirmPassword' onChange={onChange} value={user.signupConfirmPassword} required style={{
                            width: ' 80%'
                        }} />

                        <FontAwesomeIcon className='ps-2' icon={cpasswordVisibility ? faEye : faEyeSlash} style={{
                            color: "#fc03e8",
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                setcPasswordVisibility(!cpasswordVisibility)
                            }}
                        />

                    </div>

                    {error && <span className='fs-5 text-lightRed'>{error.msg}</span>}

                    <button ref={resetBtn} type="submit" className="btn btn-primary" >Reset</button>
                    {updating &&  <LoadingSpinnerSmall style={{ marginTop: '10px', width: "30px", height: "30px" }} />}
                    {success && <span className='fs-5 text-lightGreen'>Password has been changed.</span>}


                </form>
            </div>
        </>
    )
}

export default ResetPassword