export function findCommonElements(arr1, arr2) {
    // Create an empty array to store common elements
    const commonElements = [];

    // Loop through the first array
    for (let i = 0; i < arr1.length; i++) {
        const currentElement = arr1[i];

        // Check if the current element is present in the second array
        if (arr2.includes(currentElement)) {
            // Add the common element to the commonElements array
            commonElements.push(currentElement);
        }
    }

    // Return the array of common elements
    return commonElements;
}

export function findCommonElementsInThree(arr1, arr2, arr3) {
    // Create an empty array to store common elements
    const commonElements = [];

    // Loop through the elements of the first array
    for (let i = 0; i < arr1.length; i++) {
        const currentElement = arr1[i];

        // Check if the current element is present in both the second and third arrays
        if (arr2.includes(currentElement) && arr3.includes(currentElement)) {
            // Add the common element to the commonElements array
            commonElements.push(currentElement);
        }
    }

    // Return the array of common elements
    return commonElements;
}
export function findCommonElementsInFour(arr1, arr2, arr3, arr4) {
    // Create an empty array to store common elements
    const commonElements = [];

    // Loop through the elements of the first array
    for (let i = 0; i < arr1.length; i++) {
        const currentElement = arr1[i];

        // Check if the current element is present in both the second and third arrays
        if (arr2.includes(currentElement) && arr3.includes(currentElement) && arr4.includes(currentElement)) {
            // Add the common element to the commonElements array
            commonElements.push(currentElement);
        }
    }

    // Return the array of common elements
    return commonElements;
}

export const getIndexInSubArray = (array, targetElement) => {
    // console.log({array,targetElement})
    let index = -1
    // Use the some method to iterate over each subarray
    array.some((subarray, subIndex) => {
        // Check if the targetValue is present in the subarray
        if (subarray.includes(targetElement)) {
            index = subIndex;
            return true; // Stops the iteration since the value is found
        }
        return false;
    });

    return index

}

export const arrangeRecommendtaionWithLimit = (arr, limit) => {
    var newv = []
    for (let i = 0; i < arr.length; i++) {
        // console.log(i)
        if (newv.length === 0) {
            newv.push(arr[i])
        }
        else {
            for (let j = 0; j < newv.length; j++) {
                if (newv[j][2] < arr[i][2]) {
                    newv.splice(j, 0, arr[i]);
                    break;
                }
                else {
                    if (j === newv.length - 1) {
                        newv.push(arr[i])
                        break;
                    }
                }
            }
        }
    }
    newv.splice(limit)
    return newv
}

