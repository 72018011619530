import React from 'react'
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { hairColorTraits,hairStyleTraits } from "../../../Utilities/const";
import { capitalizeFirstWord } from '../../../Utilities/commonFunction';
import { getIndexInSubArray } from "../../../Utilities/DecisionMaker/utils";


const HairQuestion = (props) => {
    console.clear()
  const [color, setColor] = useState(props.hairColor||"black");
  const [style, setStyle] = useState(props.hairStyle||"straight");
  const [colorIndex, setColorIndex] = useState(5)
  const [styleIndex, setStyleIndex] = useState(3)

  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter" className="w-100 fw-bold text-black">
        <center>Hair Color & Style</center>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="my-4">
            <span className="fw-bold fs-5"
            style={{
                color:"var(--primary-color)"
            }}
            >Hair Color</span>
            <div className="w-100 d-flex flex-wrap">
                {
                    hairColorTraits.map((e,i)=>{
                        return(
                            <span className='fs-5 px-3 py-1 m-2' 
                            onClick={()=>{
                                setColor(e[0])
                                setColorIndex(i)
                            }}
                            style={{
                                cursor:"pointer",
                                background:color===e[0]?"var(--tertiary-color)":"var(--quaternary-color)",
                                color:color===e[0]?"var(--quaternary-color)":"var(--tertiary-color)",
                                borderRadius:"20px"
                            }}>{capitalizeFirstWord(e[0])}</span>
                        )
                    })
                }
            </div>
            <div>
                <span>{hairColorTraits[colorIndex][1]}</span>
            </div>
        </div>



        <div className="my-4">
            <span className="fw-bold fs-5"
            style={{
                color:"var(--primary-color)"
            }}
            >Hair Style</span>
            <div className="w-100 d-flex flex-wrap">
                {
                    hairStyleTraits.map((e,i)=>{
                        return(
                            <span className='fs-5 px-3 py-1 m-2' 
                            onClick={()=>{
                                setStyle(e[0])
                                setStyleIndex(i)
                            }}
                            style={{
                                cursor:"pointer",
                                background:style===e[0]?"var(--tertiary-color)":"var(--quaternary-color)",
                                color:style===e[0]?"var(--quaternary-color)":"var(--tertiary-color)",
                                borderRadius:"20px"
                            }}>{capitalizeFirstWord(e[0])}</span>
                        )
                    })
                }
            </div>
            <div>
                <span>{hairStyleTraits[styleIndex][1]}</span>
            </div>
        </div>
      
    </Modal.Body>
    <Modal.Footer className="w-100 d-flex justify-content-center">
      <Button className="btnQ" onClick={props.onGoBack}>Back</Button>
      <Button disabled={!color||!style} className="btnQ" onClick={() => props.onNext(color,style)}>
        Next
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default HairQuestion
