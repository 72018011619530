import React from 'react'
import { useContext } from 'react';
import ordersContext from '../../../Context/orders/ordersContext';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { capitalizeFirstWord, convertToExactDate } from '../../../Utilities/commonFunction'
import { ReactComponent as LoadingSpinnerSmall } from '../../../assets/svgs/loading_spinner_small_pink.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { Accordion, Card } from 'react-bootstrap';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import HistoryIssue from './HistoryIssue';

const HistoryDetailedItem = (props) => {
    const { id, updateProgressFlag, colors } = props;
    const { orders } = useContext(ordersContext)
    const order = orders.find(order => order._id === id)

    const colorsListNames = ['Blue', 'silver/gray', 'green', 'pink', 'gold',
        //0to 4
        'red', 'brown', 'yellow', 'tortoise', 'peaches', 'cream', 'Blod', //5 to 11
        'black', 'orange', 'white', 'purple', 'burgundy']//12 to 16
    const colorsListHex = ['#005AFF', '#A8ABAE', '#154734', '#F88CAE', '#FFD971',
        //0to 4
        '#E31937', '#AA875C', '#FFEB00', '#754734', '#F4A6A3', '#C2CDA5', '#FF7F7F', //5 to 11
        '#20211A', '#EA871E', '#E5F1ED', '#330072', '#651D32']//12 to 16



    const btnUpdateRef = useRef(null)
    const btnDeleteRef = useRef(null)
    const imageRef = useRef(null)

    const onChange = (e) => {

        if (!updating) {

            btnUpdateRef.current.classList.remove('d-none')
        }
        setEditedOrder({ ...editedOrder, [e.target.name]: e.target.value })
    }

    useEffect(() => { window.scrollTo(0, 0); }, [])


    const index = colorsListHex.indexOf(order.colorFrame);

    var frameColorName = 'Asthetic';
    if (index !== -1) {
        frameColorName = colorsListNames[index]
    } else if (order.colorFrame) {
        frameColorName = order.colorFrame
    }
    else {
        frameColorName = 'Asthetic'

    }

    const context = useContext(ordersContext);
    const { editOrder, deleteOrder } = context

    const [updating, setUpdating] = useState(false)

    const [editedOrder, setEditedOrder] = useState({
        id: order._id,
        name: order.name,
        age: order.accurateAge,
        preDeadline: order.prescriptionDeadline,
        status: order.orderStatus
    })



    return (
        <>

            <section className="d-flex flex-column flex-wrap container-fluid">

                <div className='mb-3 d-flex justify-content-center'>
                    <img ref={imageRef} src={order.image ? order.image : require('../../../assets/imgs/account_female.png')} className="img-fluid rounded-start" alt="..." />
                </div>

                <span className=" fs-4 fw-bold">
                    Personal Info
                </span>

                 <table style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 15px'
                }}>
                    <tr className='mb-3'>
                        <th>
                            <span className=' fw-bold fs-4 '>Name:</span>
                        </th>
                        <td>
                            <input className='fs-6 inputPlaceholderWhite  '
                                style={{
                                    maxWidth: '230px',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    background: 'transparent',
                                    backdropFilter: 'blur(10px)',
                                    placeholder: 'white'
                                }}
                                type="text" name='name' placeholder='Enter Name' onChange={onChange} value={editedOrder.name} />
                        </td>
                    </tr>
                    <tr className="mb-3">
                        <th>
                            <span className=' fw-bold fs-4 '>Actual Age:</span>
                        </th>
                        <td>
                            <input className='fs-6 inputPlaceholderWhite  ' type="number" name='age' onChange={onChange} value={editedOrder.age}
                                style={{
                                    maxWidth: '230px',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    background: 'transparent',
                                    backdropFilter: 'blur(10px)',
                                    placeholder: 'white'
                                }}
                                placeholder='Enter Actual Age in years'
                                inputMode='numeric' />
                        </td>


                    </tr>
                    <tr>

                        <th>
                            <span className=' fw-bold fs-4 '>Pres. Date:</span>

                        </th>
                        <td>
                            <input className='fs-6  inputPlaceholderWhite  ' type="date" name='preDeadline'
                                style={{
                                    maxWidth: '230px',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    background: 'transparent',
                                    backdropFilter: 'blur(10px)',
                                    placeholder: 'white'
                                }}
                                onChange={onChange}
                                value={editedOrder.preDeadline} />
                        </td>
                    </tr>
                </table>


                <span className=" fs-4 fw-bold">
                    Detections
                </span>

                <table style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 15px'
                }}>
                    <tr className='mb-3'>
                        <th>
                            <span className=' fw-bold fs-4 mx-3'>Face Shape:</span>
                        </th>
                        <td>
                            <span className='  fs-4 mx-3'>{capitalizeFirstWord(order.faceShape)}</span>
                        </td>
                    </tr>

                    {order.faceColor &&
                        <tr className="mb-3">
                            <th>
                                <span className=' fw-bold fs-4 mx-3'>Face Color:</span>
                            </th>
                            <td>
                                <span className=' fs-4 mx-3'>{capitalizeFirstWord(order.faceColor)}</span>
                            </td>


                        </tr>}
                    {order.pupilDistance &&
                        <tr className="mb-3">
                            <th>
                                <span className=' fw-bold fs-4 mx-3'>Pupil Distance:</span>
                            </th>
                            <td>
                                <span className=' fs-4 mx-3'>{order.pupilDistance}</span>
                            </td>


                        </tr>}

                    <tr className='mb-3'>
                        <th>
                            <span className=' fw-bold fs-4 mx-3'>Gender:</span>
                        </th>
                        <td>
                            <span className='  fs-4 mx-3'>{capitalizeFirstWord(order.gender)}</span>
                        </td>
                    </tr>
                    <tr className='mb-3'>
                        <th>
                            <span className=' fw-bold fs-4 mx-3'>Age:</span>
                        </th>
                        <td>
                            <span className='  fs-4 mx-3'>{capitalizeFirstWord(order.age)}</span>
                        </td>
                    </tr>

                </table>

                {order.personality
                    &&
                    <>
                        <div className='mt-5'>
                            <span className=" fs-4 fw-bold">
                                Selections
                            </span>
                        </div>

                        <table style={{
                            borderCollapse: 'separate',
                            borderSpacing: '0 15px'
                        }}>
                            <tr className='mb-3'>
                                <th>
                                    <span className=' fw-bold fs-4 mx-3'>Personality:</span>
                                </th>
                                <td>
                                    <span className='  fs-4 mx-3'>{capitalizeFirstWord(order.personality)}</span>
                                </td>
                            </tr>


                            <tr className="mb-3">
                                <th>
                                    <span className=' fw-bold fs-4 mx-3'>Hair Color:</span>
                                </th>
                                <td>
                                    <span className=' fs-4 mx-3'>{capitalizeFirstWord(order.hairColor)}</span>
                                </td>

                            </tr>

                            <tr className='mb-3'>
                                <th>
                                    <span className=' fw-bold fs-4 mx-3'>Hair Style:</span>
                                </th>
                                <td>
                                    <span className='  fs-4 mx-3'>{capitalizeFirstWord(order.hairStyle)}</span>
                                </td>
                            </tr>


                        </table>
                    </>
                }

                <span className=" fs-4 fw-bold">
                    Order
                </span>

             <table style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 15px'
                }}>
                    <tr className='mb-3'>
                        <th>
                            <span className=' fw-bold fs-4 mx-3'>Selected Frame: :</span>
                        </th>
                        <td>
                            <span className='  fs-4 mx-3'>{capitalizeFirstWord(order.selectedFrame)}</span>
                        </td>
                    </tr>


                    {order.colorFrame && <tr className="mb-3">
                        <th>
                            <span className=' fw-bold fs-4 mx-3'>Frame Color:</span>
                        </th>
                        <td>
                            <span className=' fs-4 mx-3'>
                                <span className='px-1 mx-1' style={{
                                    background: order.colorFrame,
                                    width: '20px',
                                    height: '25px'
                                }}></span> {capitalizeFirstWord(frameColorName)}
                            </span>
                        </td>


                    </tr>}
                    <tr className="mb-3">
                        <th>
                            <span className=' fw-bold fs-4 mx-3'>Order Status:</span>
                        </th>
                        <td>
                            <span className=' fs-4 mx-3'>
                                <select
                                    className=''
                                    name='status' value={editedOrder.status} onChange={onChange}
                                    style={{
                                        maxWidth: '230px',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        background: 'transparent',
                                        backdropFilter: 'blur(10px)',
                                        placeholder: 'white'
                                    }}>
                                    <option className='text-dark' value="In Progress">In Progress</option>
                                    <option className='text-dark' value="Completed">Completed</option>
                                    <option className='text-dark' value="Cancel">Cancel</option>
                                </select>
                            </span>
                        </td>

                    </tr>



                </table>

                <span className=" fs-4 fw-bold">
                    Selected Frame Image
                </span>

                <div className='container bg-light'>

        
                </div>
                <span className=" fs-5">Date & Time of creation:&nbsp;</span>
                <span className=" fs-5">{
                    convertToExactDate(parseInt(order.date))
                }</span>

                  <div className="d-flex flex-wrap justify-content-end my-5">
                    {updating &&
                        <LoadingSpinnerSmall
                            style={{
                                marginTop: '10px',
                                width: "30px",
                                height: "30px",
                                marginRight: '10px'
                            }}
                        />
                    }
                    <button className="btn btn-default me-2 d-none" ref={btnUpdateRef} onClick={async () => {
                        setUpdating(true)
                        btnUpdateRef.current.classList.add('d-none')
                        await editOrder(editedOrder.id,
                            editedOrder.name,
                            editedOrder.age,
                            editedOrder.preDeadline,
                            editedOrder.status)
                        setUpdating(false)

                    }}>Update</button>
                    <button ref={btnDeleteRef} className="btn btn-danger" onClick={async () => {
                        // console.log(btnDeleteRef)
                        setUpdating(true);
                        btnDeleteRef.current.classList.add('d-none')
                        await deleteOrder(order._id);
                        setUpdating(false)
                        updateProgressFlag({
                            list: true,
                            detail: false
                        })
                    }}>
                        <FontAwesomeIcon icon={faTrashCan} />
                        &nbsp;Delete
                    </button>
                </div>





                <Accordion className='container-fluid my-5'>
                    <Card
                        style={{
                            backdropFilter: 'blur(10px)'
                        }}
                        className='bg-transparent'>
                        <Card.Header className=''>
                            <CustomToggle colors={colors} eventKey="0">
                                <span className='fw-bold fs-5'>
                                    <FontAwesomeIcon icon={faWarning} />
                                    &nbsp;Report an Issue?
                                </span>
                            </CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <HistoryIssue colors={colors} order={order} />
                                <button
                                    style={{
                                        background: colors.primary[400],
                                        color: colors.primary[100],
                                        border: `1px solid ${colors.primary[100]}`,
                                        width: 'fit-content',
                                        borderRadius: '5px',
                                        padding:'20px'
                                    }}
                                    onClick={() => {
                                        updateProgressFlag({
                                            list: true,
                                            detail: false
                                        })
                                    }}
                                    className="mt-5"
                                >{`<<Go Back`}</button>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </section>
        </>
    )
}

function CustomToggle({ children, eventKey, colors }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
        <div
            className='w-100 h-100'
            style={{
                backgroundColor: 'transparent',
                cursor: 'pointer',
                color: colors.redAccent[200]
            }}
            onClick={decoratedOnClick}
        >
            <center>
                {children}
            </center>
        </div>
    );
}

export default HistoryDetailedItem
