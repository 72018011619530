import React, { useContext, useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar'
import userContext from '../../Context/users/userContext'
import { useNavigate } from 'react-router-dom'
import '../../assets/css/glassmophorm.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as LoadingSpinnerSmall } from '../../assets/svgs/loading_spinner_small_pink.svg';

import { ReactComponent as Logo } from "../../assets/svgs/logoBlue.svg";


const ForgotPassword = () => {
    const navigate = useNavigate()
    const [updating, setUpdating] = useState(false)
    const [success, setSuccess] = useState(false)
    const [leftHeight, setLeftHeight] = useState("100%");

    const resetBtn = useRef(null)
    const left = useRef(null);

    const context = useContext(userContext)
    const { sendResetLink } = context

    const [user, setuser] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUpdating(true)
        resetBtn.current.classList.add("d-none")
        const result = await sendResetLink(user)
        if (result.success) {
            setUpdating(false)
            setSuccess(true)
        }
        else if (!result.success) {
            setUpdating(false)
            setSuccess(true)
        }
    }

    const onChange = (e) => {

        setuser(e.target.value)
    }

    useEffect(() => {
        setLeftHeight(left.current.offsetHeight);
    }, []);
    return (
        <>
            {/* <Navbar /> */}
            {/* <div className="form-container bg-6 w-100" style={{
                transition: '2s all linear'
            }}>
                <form onSubmit={handleSubmit}>
                <h4 className='fw-bold text-white'>Forgot Password?</h4>
                <h3>Enter the email address and we will send you the reset link</h3>
                   
                    
                    <div className="mb-3">
                        <FontAwesomeIcon className='mx-1' icon={faEnvelope} style={{ color: "#fc03e8" }} />
                       
                        <input type="email" className='ms-2' placeholder='Enter Email' value={user} id="email" name='email' onChange={onChange} />

                    </div>

                    <button ref={resetBtn} type="submit" className="btn">Send Reset Link</button>
                    {updating && <LoadingSpinnerSmall style={{ marginTop: '10px', width: "30px", height: "30px" }} />}
                    {success &&
                        <>
                            <span className='fs-5 text-lightPink' >Password Reset link sent.</span>
                            <br />
                            <button
                            onClick={()=>{
                                navigate('/login')
                            }} 
                            className="btn my-4">Go Back</button>
                        </>
                    }
                </form>
            </div> */}

            <section className="sectionLS">
                <div ref={left} className="left">
                    <div className="topLeftTriangle"></div>
                    <Logo
                        //  width={'200px'} height={'200px'}
                        className="logoLS"
                    />

                    <form
                        className="form-container-login"
                        onSubmit={handleSubmit}
                        id="form"
                    >
                        <span className="mainHeading ">Forgot Password?</span>
                        <span className="subHeading mb-4">
                            Enter password to recieve reset link!
                        </span>
                        <div
                            className="mb-5"
                            style={{
                                flexDirection: "row",
                                width: "fit-content",
                            }}
                        >

                        </div>

                        <input
                            className="email-input mb-4"
                            placeholder="Enter your Email"
                            type="email"
                            id="email"
                            name="email"
                            aria-describedby="emailHelp"
                            onChange={onChange}
                            value={user.email}
                            required
                        />

                        {success &&
                            <span className='fs-5 text-primary fw-bold' >Password Reset link sent.</span>
                        }

                        <div className="d-flex justify-content-center mt-4">
                            <button type="button" className="active mx-2"
                                onClick={() => {
                                    navigate("/login")
                                }}>
                                Log In
                            </button>
                            {updating && (
                                <LoadingSpinnerSmall
                                    style={{ marginTop: "10px", width: "30px", height: "30px" }}
                                />
                            )}
                            <button type="submit" className="login-btn mx-2" ref={resetBtn}>
                                Send Reset Link
                            </button>
                        </div>




                    </form>
                </div>
                <div className="rightLS" style={{ height: leftHeight }}></div>
            </section>

        </>
    )
}

export default ForgotPassword