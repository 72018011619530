import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Faqs from './Faqs'
import Guide from './Guide'
import '../../assets/css/button.css'

const FaqsGuide = () => {
  const [display, setDisplay] = useState({ guide: true, faqs: false })

  

  const guideHandler=async()=>{
    // setDisplay({guide:true,faqs:false})
    // console.log(display)
    const newUpdateDisplay={...display,guide:true,faqs:false}
    await updateDisplay(newUpdateDisplay)

  }
  const faqsHandler=async()=>{
    // setDisplay({faqs:true,guide:false})
    const newUpdateDisplay={...display,faqs:true,guide:false}
    await updateDisplay(newUpdateDisplay) 
  }

  const updateDisplay=async(update)=>{
    await setDisplay(update)
  }

  useEffect(() => {
  }, [display]);

  return (
    <>
      <Navbar />

      <div className="container">
        <div className="row px-5">
          <center>
            <button className={`col-sm-3 btn ${display.guide?'btn-blue-pink':'btn-pink-blue'}   mt-2 mb-3 mx-3`} disabled={display.guide} onClick={guideHandler}>Guide</button>
            <button className={`col-sm-3 btn ${display.faqs?'btn-blue-pink':'btn-pink-blue'} mt-2  mb-3`} disabled={display.faqs} onClick={faqsHandler}>Faqs</button>
          </center>

        </div>

      </div>

      {display.guide && <Guide />}
      {display.faqs && <Faqs />}
    </>
  )
}

export default FaqsGuide