import React from 'react'
import { useContext } from 'react';
import ThemeContext from '../../../Context/ThemeContext/ThemeContext';
import { useState } from 'react';


import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { FeedOutlined, HistoryEduOutlined, PersonOffOutlined, ScannerOutlined, SettingsOutlined } from '@mui/icons-material';
import AdminPanelContext from '../../../Context/AdminPanelContext/AdminPanelContext';
import userContext from '../../../Context/users/userContext';

const Sidebar = () => {

  const { theme, tokens } = useContext(ThemeContext)
  const { user } = useContext(userContext)


  const palette = tokens(theme);

  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth > 700 ? false : true);
  const [selected, setSelected] = useState("dashboard");

  return (
    <>
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${palette.primary[800]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: palette.grey[100],
              }}
            >
              {!isCollapsed && (
                <div className="ms-1"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}

                >
                  <span className='fw-bold fs-2' style={{
                    color: palette.grey[100],
                  }}  >
                    ADMIN
                  </span>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon style={{ color: palette.primary[100] }} />
                  </IconButton>
                </div>
              )}
            </MenuItem>

            {!isCollapsed && (
              <Box mb="25px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    alt="profile-user"
                    width="100px"
                    height="100px"
                    src={require(`../../../assets/imgs/account_female.png`)}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                  />
                </Box>
                <Box textAlign="center">
                  <span
                    className='fs-3 fw-bold mt-1'
                    style={{
                      color: palette.redAccent[300]
                    }}>
                    {user.name}
                  </span>

                </Box>
                <Box textAlign="center">

                  <span className='fs-5 mt-1'
                    style={{
                      color: palette.greenAccent[300]
                    }}>

                    {user.shopName}
                  </span>
                </Box>
              </Box>
            )}

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                screen="dashboard"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

            
              <Item
                title="Go to App"
                screen="app"
                icon={<ScannerOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

              <span className='fs-6 mt-1'
                style={{ color: palette.grey[300], margin: "15px 0 5px 15px" }}>
                Manage
              </span>
              <Item
                title="History Records"
                screen="history"
                icon={<HistoryEduOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <span className='fs-6 mt-1'
                style={{ color: palette.grey[300], margin: "15px 0 5px 20px" }}>
                Tools
              </span>
              <Item
                title="Settings"
                screen="settings"
                icon={<SettingsOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Profile"
                screen="updateProfile"
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />




              <span className='fs-6 mt-1'
                style={{ color: palette.grey[300], margin: "15px 0 5px 15px" }}>
                Support
              </span>
              <Item
                title="Feedback"
                screen="feedback"
                icon={<FeedOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="FAQs"
                screen="faqs"
                icon={<HelpOutlineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <span className='fs-6 mt-1'
                style={{ color: palette.grey[300], margin: "15px 0 5px 20px" }}>
                Charts
              </span>
              <Item
                title="Bar Chart"
                screen="bar"
                icon={<BarChartOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Pie Chart"
                screen="pie"
                icon={<PieChartOutlineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Line Chart"
                screen="line"
                icon={<TimelineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}

            </Box>

          </Menu>
        </ProSidebar>
      </Box>
    </>
  )
}


const Item = ({ title, screen, icon, selected, setSelected }) => {
  const { theme, tokens } = useContext(ThemeContext)
  const { adminScreen, updateAdminScreen } = useContext(AdminPanelContext)
  const navigate=useNavigate();
  const palette = tokens(theme);
  return (

    <MenuItem
      //  active={selected===title}
      style={{
        color: adminScreen?.[`${screen}`] ? palette.grey[800] : palette.grey[100],
        background: adminScreen?.[`${screen}`] ? palette.redAccent[400] : palette.primary[800],
        fontWeight: adminScreen?.[`${screen}`] ? 'bold' : 'normal',
        padding: adminScreen?.[`${screen}`] ? '5px 0px' : '0px 0px'
      }}
      onClick={() => {
        if(screen!=="app"){
          updateAdminScreen(screen)
          setSelected(screen);
        }
        else{
          navigate("/home")
        }
        
      }}
      icon={icon}
    >
      <span>{title}</span>
    </MenuItem>
  );
};




export default Sidebar
